import React, { useEffect, useState } from 'react';
import GoogleLogin, { useGoogleLogin } from 'react-google-login';
import { ShareTwoTone as ShareIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { observer } from 'mobx-react';
import { useStores } from 'store';

// @ts-ignore
let gapi = window.gapi;

export const YoutubeUploadButton = observer(() => {
  const { videoStore } = useStores();
  const [loggedIn, setLoggedIn] = useState(false);

  const { signIn, loaded } = useGoogleLogin({
    redirectUri: 'http://localhost:3000/youtube',
    responseType: 'code',
    uxMode: 'redirect',
    scope:
      'profile https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/plus.login',
    clientId:
      '117217818595-f4g5mcfugq2jpg1ujgf2rtdh8lreh19n.apps.googleusercontent.com',
    onSuccess: resp => {
      console.log(resp);
    },
    onFailure: console.log,
    onRequest: console.log,
  });

  console.log(loaded);

  const upload = async () => {
    const { access_token } = gapi.auth2
      .getAuthInstance()
      .currentUser.get()
      .getAuthResponse();

    //const blob = await videoStore.encodeVideo();
    const resp = await fetch(
      `https://www.googleapis.com/upload/youtube/v3/videos?uploadType=resumable&part=snippet,status`,
      {
        method: 'POST',
        headers: {
          Host: 'www.googleapis.com',
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'application/json; charset=UTF-8',
          'X-Upload-Content-Length': '433000',
          'X-Upload-Content-Type': 'video/*',
        },
        body: JSON.stringify({
          snippet: {
            title: 'iniuhi22hi',
            description: 'asd22f ojniniuni iuniunui ininiun',
          },
          status: {
            privacyStatus: 'public',
          },
        }),
      },
    );

    const location = resp.headers.get('location');

    console.log(location);
  };

  const onClickSignIn = () => {
    if (loggedIn) {
      upload();
    } else {
      signIn();
    }
  };

  useEffect(() => {
    if (!loaded) return;

    gapi = window.gapi;

    const id = gapi.auth2.getAuthInstance().currentUser.get().getId();
    setLoggedIn(!!id);
  }, [loaded]);

  // 유투브 업로드 잠시 안함
  return null;

  return (
    <IconButton
      size="large"
      //disabled={!videoStore.video}
      color="inherit"
      title="Share"
      onClick={onClickSignIn}
    >
      <GoogleLogin
        redirectUri="http://localhost:3000/youtube"
        uxMode="redirect"
        cookiePolicy="single_host_origin"
        scope="profile https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/plus.login"
        clientId="117217818595-f4g5mcfugq2jpg1ujgf2rtdh8lreh19n.apps.googleusercontent.com"
        onSuccess={resp => {
          console.log(resp);
        }}
      />
    </IconButton>
  );
});
