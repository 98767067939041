import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Header } from 'component/header';
import { Playground } from 'component/playground';
import { EffectMenu } from 'component/popup/EffectMenu';
import { Sidebar } from 'component/sidebar';
import 'react-timelines2/lib/css/style.css';
import { observer, useObserver } from 'mobx-react';
import { useStores } from 'store';

import { ChatPrompt } from 'component/chat';
import { BgmOption } from 'component/playground/BgmOption';
import Loading from '../component/loading/Loading';
import Toast from '../component/popup/Toast';
import { Overlay } from './app/Overlay';
import { constLayout } from 'constant/layout';
import { HistoryDesktop } from 'component/history/HistoryDesktop';

export const AppMobile = observer(() => {
  const text: string =
    'Uploading Video... \n (Check it at the Cloud after upload.)';

  const {
    uiStore,
    uiHistory,
    uiToast,
    uiLoading,
    uiMobileEffectMenu,
    uiPopup,
  } = useStores();

  return (
    <Container>
      <Header />
      <Toast
        {...{
          block: uiToast.loading,
          title: 'UPLOAD COMPLETED',
          url: uiToast.url,
        }}
      />

      <AppContainer>
        <RightContainer>
          <Playground />
          {/* <EffectMenu block={uiMobileEffectMenu.loading} /> */}
        </RightContainer>
        <Sidebar />
      </AppContainer>
      <HistoryDesktop show={uiHistory.loading} />
      <Overlay />

      {uiStore.showChatPrompt && <ChatPrompt />}
    </Container>
  );
});

const Container = styled.div`
  height: 100%
`;

const AppContainer = styled.div`
  position: relative;
  height: calc(100vh - ${constLayout.MOBILE_HEADER_HEIGHT});

  display: flex;
  grid-template-rows: 1fr ${constLayout.MOBILE_HEADER_HEIGHT};
  overflow: hidden;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
