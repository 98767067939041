import React from 'react';
import { InputBase } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import RobotIcon from '@/asset/robot.png';
import SearchIcon from 'asset/search.svg';
import { constLayout } from 'constant/layout';

/** 서치 너비는 사이드바의 확장된 영역 만큼의 너비에서 약간 작은 값 */
const searchWidth = parseInt(constLayout.PC_SIDEBAR_EXPAND_WIDTH) - parseInt(constLayout.PC_SIDEBAR_WIDTH) - 20

interface SearchProps {
  hasAIButton?: boolean;
  value: string;

  /** 검색 돋보기 내에 placeHolder로 적용할 값 (사용자가 검색창에 아무것도 입력하지 않으면 표시되는 값) 
   * 
   * 이 값에서 최대로 표시할 수 있는 글자 수는 제한되어 있을 수 있습니다. (정확한 값은 모름)
   * */ 
  placeHolder?: string;

  /** 오른쪽에 표시할 이미지의 경로, 없을경우 표시되지 않음. */
  rightImageSrc?: string;

  /** 오른쪽을 클릭했을 때 사용되는 함수 (없을경우 무시됨) */
  rightClickEvent?: Function;

  /** SSearch 태그의 강제 width 설정 (css값으로 넣어야 함) */
  setCssWidth?: string;

  onChange: (x: string) => void;
}
export const Search = ({
  hasAIButton = false,
  value,
  onChange,
  placeHolder,
  rightImageSrc,
  rightClickEvent,
  setCssWidth: setWidth,
  ...props
}: SearchProps) => {
  return (
    <SSearch style={{ height: '36px', width: setWidth == null ? searchWidth + 'px' : setWidth }} {...props}>
      {/* SSearch의 길이를 조정하기 위해 강제로 328px 너비를 할당함 */}
      <SearchIconWrapper>
        <img src={SearchIcon} />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder={placeHolder}
        inputProps={{ 'aria-label': 'search' }}
        value={value}
        onChange={(e: any) => onChange(e.target.value)}
        />
      {rightImageSrc != null && 
        <Right>
          <img src={rightImageSrc} style={{position:'absolute', right:'5%'}} 
          onClick={() => {
            if (rightClickEvent != null) {
              rightClickEvent()
            }
          }}></img>
        </Right>
      }

      {hasAIButton && (
        <Right>
          <RobotButton src={RobotIcon} />
        </Right>
      )}
    </SSearch>
  );
};

const SSearch = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '30px',
  backgroundColor: '#2E2E2E',
  alignItems: 'center',
  gap: '8px',
  color: '#777777',
  '&:hover': {
    backgroundColor: '#2E2E2E',
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#777',
  fontFamily: 'Pretendard',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '-0.24px',

  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),

    // width의 경우 searchWidth가 기준값보다 더 설정되는 경우가 있어서, 일부 값을 마이너스 했습니다.
    // cloud 메뉴에서 버튼 클릭의 영역을 가리는 문제 때문에 기준보다 약간 낮게 설정되어있습니다.
    width: searchWidth + 'px', // 참고: 이 값은 고정px로 설정하지 않으면, 임의 크기로 지정되는 것 같습니다.
    // [theme.breakpoints.up('md')]: {
    //   width: '100%',
    // },
  },
}));

const Right = styled('div')(() => ({
  position: 'absolute',
  right: '6px',
  top: '4px',
  cursor: 'pointer',
}));
const RobotButton = styled('img')(() => ({
  width: '26px',
  height: '26px',
}));
