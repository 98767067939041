import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Radar } from "react-chartjs-2";
import { observer } from "mobx-react";
import AudioSpectrum from "react-audio-spectrum";

import { useStores } from "store";
import { isMobile } from "util/mobile";

interface GraphProps {}
export const Graph = observer(({}: GraphProps) => {
  const canvas = useRef<HTMLCanvasElement>(null);
  const { timelineStore, videoStore } = useStores();
  // 이펙트 적용 전과 적용 후를 비교할 수 있게 2개의 아날라이저 노드를 가져옵니다.
  const analyserOriginal = videoStore.mixer?.analyserOriginal
  const analyserEffect = videoStore.mixer?.analyserEffect
  const mobile = isMobile()
  /** 어두운 파랑 */ const DARKBLUE = '#808798'
  /** 진한 파랑 */ const DEEPBLUE = '#153B96'

  useEffect(() => {
    if (!analyserOriginal || !analyserEffect) {
      return;
    }
    const ctx = canvas.current?.getContext("2d");
    
    /** 
     * 영상 밑쪽 화면에 파형이 나오도록 그리는 함수
     */
    const renderFrameWave = () => {
      if (!ctx || !canvas.current) {
        return;
      }

      ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);

      // 2개를 한 화면에 표시해야 하므로, fftSize는 캔버스 사이즈의 50%입니다.
      // 모바일이면 256(실제 표시 128), PC는 1024(실제 표시 512)
      // 다만 캔버스에 해당 사이즈가 지정되어 있으므로, 캔버스 나누기 2로 정의했습니다.
      let fftSize = canvas.current.width / 2;
      let pulseOriginal = new Uint8Array(fftSize);
      let pulseEffect = new Uint8Array(fftSize);
      analyserOriginal.getByteTimeDomainData(pulseOriginal);
      analyserEffect.getByteTimeDomainData(pulseEffect);

      /** 8비트 int의 최대 숫자 */
      const UINT8MAX = 255;
      
      // original audio graph // 왼쪽 절반 영역에 표시됩니다.
      ctx.fillStyle = DARKBLUE; // 색상을 노란색으로 변경
      let w = canvas.current.width / (pulseOriginal.length) // w = width (각 사각형의 너비)
      for (let i = 0; i < pulseOriginal.length; i++) {
        // 첫번째 요소부터 마지막 요소까지 사각형으로 칠합니다.
        let x = (i / pulseOriginal.length) * fftSize
        let h = (pulseOriginal[i] / UINT8MAX) * canvas.current.height
        ctx.fillRect(x, canvas.current.height, w, -h)
      }
      
      // effect enable audio graph (effect 또는 오디오가 적용되어있어야만 출력이 가능)
      // mixer 내부에 오디오 또는 이펙트가 적용된 상태일 때 출력됩니다.
      let isMixerEnable = videoStore.mixer?.getIsEnableEffectOrAudio()
      if (isMixerEnable) {
        ctx.fillStyle = DEEPBLUE // 색상을 어두운 파란색으로 변경
        for (let i = 0; i < pulseEffect.length; i++) {
          // 오른쪽 절반 영역에 칠해지기 때문에 x값에 (canvas.current.width / 2) 만큼의 값을 추가해야 합니다.
          let x = (i / pulseOriginal.length) * fftSize + (canvas.current.width / 2)
          let h = (pulseEffect[i] / UINT8MAX) * canvas.current.height
          ctx.fillRect(x, canvas.current.height, w, -h)
        }
      }
      requestAnimationFrame(renderFrameWave)
    }
    renderFrameWave()

    // 사용자의 요청에 의하여 이 기능은 취소되었습니다.
    // 이제 막대그래프는 더이상 표시되지 않습니다.
    // const renderFrame = () => {
    //   if (!ctx || !canvas.current) {
    //     return;
    //   }

    //   let freqData = new Uint8Array(analyser.frequencyBinCount);
    //   requestAnimationFrame(renderFrame);
    //   analyser.getByteFrequencyData(freqData);
    //   ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
    //   ctx.fillStyle = "#9933ff";
    //   let bars = 100;
    //   for (var i = 0; i < bars; i++) {
    //     let bar_x = i * (1024 / 100);
    //     let bar_width = 1024 / 100;
    //     let bar_height = -Math.pow(freqData[i] / 100, 5);
    //     ctx.fillRect(bar_x, canvas.current.height, bar_width, bar_height);
    //   }
    // };
    // renderFrame();

  }, [analyserOriginal]);

  return (
    <Container>
      {/* 모바일의 경우 캔버스의 해상도 및 정밀도가 감소합니다.
      캔버스 사이즈를 기준으로 fftSize를 결정하기 때문에, 이 값을 2의 배수가 아닌 다른 값으로 정의하지 마세요. */}
      {mobile 
      ? <canvas ref={canvas} width="256px" height="256px" />
      : <canvas ref={canvas} width="1024px" height="1024px" /> }
    </Container>
  );
});

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 14%;
  left: 0%;
  top: 66%;

  z-index: -1;

  canvas {
    width: 100%;
    height: 100%;
  }
`;
