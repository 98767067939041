import React, { useEffect, useRef, useState } from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { DateTime } from 'luxon';
import { useObserver } from 'mobx-react';
import { IUploadedVideo } from 'model';
import { useStores } from 'store';
import styled, { css } from 'styled-components';

import { getAuthTokenFromCookie, getCookie } from 'util/cookie';
import { downloadFileUrl } from 'util/download';
import { request } from 'util/http-client';
import { Search } from 'component/input';
import Confirm from 'component/popup/Confirm';
import { timelineStore } from 'store/timeline';
import { videoStore } from 'store/video';

import checkedIcon from 'asset/history/checked.svg';
import uncheckedIcon from 'asset/history/unchecked.svg';
import linkIcon from 'asset/history/link.svg';
import downloadIcon from 'asset/history/download.svg';
import deleteDisableIcon from 'asset/history/deleteDisable.svg';
import deleteEnableIcon from 'asset/history/deleteEnable.svg';
import { constLayout } from 'constant/layout';
import { getLanguageData } from 'constant/language';
import { isMobile } from 'util/mobile';

const lang = getLanguageData()
const IS_MOBILE = isMobile()

interface HistoryDesktopProps {
  show: boolean;
}

export const HistoryDesktop = ({ show }: HistoryDesktopProps) => {
  const [removeOk, setRemoveOk] = useState(false);
  const [q, setQ] = useState('');
  const [list, setList] = useState<IUploadedVideo[]>([]);

  // 삭제 작업을 위한 강제 갱신 state
  // 주의: checkBox는 ref로 동작하며, 버튼을 눌러도 화면이 갱신되지 않습니다.
  const [forceUpdate, setForceUpdate] = useState(0);

  /** 체크박스에 체크된 비디오들의 id 리스트 */
  const checkedVideoIdList = useRef<string[]>([]);

  const allCheckBoxRef = useRef<HTMLInputElement>(null);
  const checkBoxArrayRef = useRef<HTMLInputElement[]>([]);

  const { uiPopup, uiHistory } = useStores();

  /** 내 프로젝트 개수 표시 컴포넌트 */
  function MyProject () {
    return (
      <MyProjectDiv>
        <MyProjectText>{lang.CLOUD_MY_PROJECT}</MyProjectText>
        &nbsp;
        <MyProjectCount>({list.length})</MyProjectCount>
      </MyProjectDiv>
    )
  }

  /** 삭제 버튼의 섹션 공간 (이 안에 삭제 버튼이 구현됨) */
  function DeleteButtonSectionArea () {
    // 참고: 컴포넌트를 갱신하면, 리스트가 맨 위로 올라가는 문제 때문에 state 변경 요소를 전부 제거하여 갱신을 막았습니다.
    // 따라서, 현재 개수에 따라 state 갱신을 할 수 없습니다. 이 때문에 활성화된 delete 버튼만 표시됩니다.
    return (
      <DeleteButtonSection>
        <DeleteButtonSelectButtonEnable type='button' onClick={removeMusicList} className='enable'>
          <img src={deleteEnableIcon}></img>
          {lang.CLOUD_DELETE}
        </DeleteButtonSelectButtonEnable>
      </DeleteButtonSection>
    )
  }

  /** 비어있는 공간 (가로줄) */
  function BlankLineArea () {
    return (
      <div style={{height: '10px'}}></div>
    )
  }

  /** PC 버전의 테이블 헤더 공간 */
  function TableHeaderArea () {
    return (
      <TableHeader>
        <TableColumn setPercentColumnWidth={tableWidthPercentArray[0]}>
          <TableDataCheckbox 
            type='checkbox' 
            id='tableHeaderCheckbox'
            ref={allCheckBoxRef}
            onClick={allCheckDeleteCheckBoxOnClickHandle}
          />
        </TableColumn>

        {/* 위에서 0번 테이블을 표시하였으므로, 인덱스는 1번부터 처리 */}
        {tableNameArray.map((value, index, array) => {
          if (index === 0) return null;

          return (
            <TableColumn
              key={'history->tableHeader->' + index}
              setPercentColumnWidth={tableWidthPercentArray[index]}
            >
              <span>{tableNameArray[index]}</span>
            </TableColumn>
          );
        })}
      </TableHeader>
    )
  }

  /** PC 버전의 테이블 데이터 공간 */
  function TableDataArea () {
    return (
      <TableData>
        {list.filter(x => x.title.includes(q) || x.description.includes(q),)
          .map((item: any, index: number) => {
            return (
              <TableRow
                onClick={e => {
                  onClickApply(item);
                  e.stopPropagation();
                }}
                key={'history->tableData->' + item.videoId}
              >
                <TableColumn
                  setPercentColumnWidth={tableWidthPercentArray[0]}
                >
                  <TableDataCheckbox 
                    type='checkbox' 
                    id='tableDataCheckbox'
                    ref={elem =>
                      (checkBoxArrayRef.current[index] = elem!)
                    }
                    defaultChecked={checkedVideoIdList.current.includes(item.videoId)}
                    onClick={e => {
                      e.stopPropagation();
                      rowListRemoveCheckOnClick(item.videoId);
                    }}
                  />
                </TableColumn>

                <TableColumn
                  setPercentColumnWidth={tableWidthPercentArray[1]}
                >
                  {/* 썸네일 이미지는 img태그가 svg 출력 용도로도 사용되므로, 썸네일 전용 클래스를 추가로 적용해야 합니다. */}
                  <img
                    className='thumbnail'
                    crossOrigin="anonymous"
                    src={item.thumbnailUrl}
                  />
                </TableColumn>
                <TableColumn
                  setPercentColumnWidth={tableWidthPercentArray[2]}
                >
                  <TableDataSpan>{item.title}</TableDataSpan>
                </TableColumn>
                <TableColumn
                  setPercentColumnWidth={tableWidthPercentArray[3]}
                >
                  <TableDataSpan>&nbsp;</TableDataSpan>
                </TableColumn> 
                <TableColumn
                  setPercentColumnWidth={tableWidthPercentArray[4]}
                >
                  <TableDataSpan title={DateTime.fromISO(item.created_at).toISOTime()}>
                    {DateTime.fromISO(item.created_at).toISOTime()}
                  </TableDataSpan>
                </TableColumn>
                <TableColumn
                  setPercentColumnWidth={tableWidthPercentArray[5]}
                  // onClick={() => onClickBGM(item)}
                >
                  <TableDataSpan title={DateTime.fromISO(item.created_at).toLocaleString()}>
                    {DateTime.fromISO(item.created_at).toLocaleString()}
                  </TableDataSpan>
                </TableColumn>
                <TableColumn
                  setPercentColumnWidth={tableWidthPercentArray[6]}
                >
                  <img src={linkIcon} 
                    onClick={() => onClickLinkCopy(item.videoUrl)}
                    style={{ cursor: 'pointer' }}
                  />
                </TableColumn>
                <TableColumn
                  setPercentColumnWidth={tableWidthPercentArray[7]}
                >
                  <img src={downloadIcon} 
                    onClick={() => onClickDownload(item.videoUrl)}
                    style={{ cursor: 'pointer' }}
                  />
                </TableColumn>
              </TableRow>
            );
          })}
      </TableData>
    )
  }

  /** 테이블에 표시할 이름의 리스트 */
  const tableNameArray = [
    '',
    lang.CLOUD_THUMBNAIL,
    lang.CLOUD_PROJECT_NAME,
    lang.CLOUD_VIDEO_SIZE,
    lang.CLOUD_LAST_MODIFIED_TIME,
    lang.CLOUD_LAST_MODIFIED_DATE,
    lang.CLOUD_COPY_LINK,
    lang.CLOUD_DOWNLOAD,
  ];

  /** 테이블에 표시할 영역의 너비에 대한 percent 값 (총합이 100을 넘으면 안됨) */
  const tableWidthPercentArray = [8, 8, 20, 16, 16, 12, 10, 10];

  /**  */
  function MoblieGridDataArea () {
    return (
      <GridData>
        <div style={{display:'grid', gridTemplateColumns:'46% 46%', width:'100%', height:'100%', overflow:'scroll', gap:'6%'}}>
          {list && list.length > 0 && list
            .filter(x => x.title.includes(q) || x.description.includes(q))
            .map((item: any, index: number) => { 
              return (
                <div key={'history->grid->' + index} style={{width:'100%', position: 'relative'}}
                  onClick={e => {
                    onClickApply(item);
                    e.stopPropagation();
                  }}
                >
                  <img style={{width:'100%', height:'50%'}}
                    crossOrigin="anonymous"
                    src={item.thumbnailUrl}
                  />
                  <TableDataCheckbox 
                    style={{ position: 'absolute', top:'1%', left:'1%'}}
                    type='checkbox' 
                    id='tableDataCheckbox'
                    ref={elem =>
                      (checkBoxArrayRef.current[index] = elem!)
                    }
                    defaultChecked={checkedVideoIdList.current.includes(item.videoId)}
                    onClick={e => {
                      e.stopPropagation();
                      rowListRemoveCheckOnClick(item.videoId);
                    }}
                  />
                  <br></br>
                  <GridDataTitle>{item.title}</GridDataTitle>
                  <GridDataTime>{item.created_at.split('T')[0]}</GridDataTime>
                </div>
              )
            })}
        </div>
      </GridData>
    )
  }

  /** header checkbox 클릭 시, header checkbox 의 checked 값에 따라
   * 하위 데이터 리스트 체크박스를 모두 선택하거나 모두 선택하지 않게 변경합니다. */
  const allCheckDeleteCheckBoxOnClickHandle = () => {
    if (checkBoxArrayRef.current.length <= 0) return // 체크된 박스의 개수가 0이면 아무 일도 없음
    if (allCheckBoxRef.current == null) return

    // 하위 체크박스의 값을 전부 allCheckBoxRef.current.checked값으로 설정함
    const check = allCheckBoxRef.current.checked ? true : false
    checkBoxArrayRef.current.forEach(element => {
      if (!element) return;
      element.checked = check
    });
  };

  // 데이터 리스트 중 체크 박스 클릭 시, 전체 선택 여부에 따라 header checkbox 해제 또는 선택
  const listCheckBoxOnClickHandle = (key?: number) => {
    let isAllChecked = true;

    if (checkBoxArrayRef.current.length > 0) {
      checkBoxArrayRef.current.forEach(element => {
        // 전체 선택이 안되는 조건일 시, 전체 선택 체크 박스 False 처리
        if (element && !element.checked) isAllChecked = false;

        // 체크박스를 직접 클릭할 경우
        if (key) {
          if (element && Number(element.value) == key) {
            element.checked = element.checked ? false : true;
          }
        }
      });
    }

    if (allCheckBoxRef.current != null) {
      allCheckBoxRef.current!.checked = !isAllChecked ? false : true;
    }
  };

  /** confirm 컴포넌트 내, 확인|취소 상태 확인을 위해 하위로 내린 함수
   * 결과가 화면에 반영되기 위하여 forceUpdate를 해주세요.
    */
  const confirmButtonOnClick = (isOk: boolean) => {
    isOk ? setRemoveOk(true) : onClose();
    setForceUpdate(f => f + 1) // confirm 적용을 위해 화면 강제 갱신
  };

  /** checked 된 ref의 개수만큼 string 배열을 가져옵니다. checked 되어있다면 string값으로 'on'이 추가됩니다. */
  const getCheckedRefValue = () => {
    let checkedRefValue: string[] = [];
    if (checkBoxArrayRef.current.length > 0) {
      checkBoxArrayRef.current.forEach(element => {
        if (element && element.checked) checkedRefValue.push(element.value);
      })
    }

    return checkedRefValue
  }

  /** 삭제 버튼 클릭 시 동작 및 삭제된 데이터를 제외한 리스트 출력을 위한 set useState */
  const removeMusicList = () => {
    // 선택된 리스트의 key List 배열
    let checkedRefValue = getCheckedRefValue();

    if (checkedRefValue.length < 1) {
      alert('Please select the list you want to delete.');
      return;
    }

    !removeOk ? uiPopup.showLoading() : uiPopup.hideLoading();
    setForceUpdate(f => f + 1) // popup 화면을 보여주기 위한 강제 갱신
  };

  /** 해당 프로젝트의 mastering, music을 적용합니다. */
  const onClickApply = (item: any) => {
    timelineStore.load(JSON.parse(item.data));

    uiHistory.hideLoading();
  };

  /** 로우 리스트에서 체크박스를 클릭한 경우, 해당하는 videoId를 가져오고, 그 videoId를 ref에 등록한다.
   * 등록된 videoId는, 특정한 video를 삭제할 때 사용된다.
   */
  const rowListRemoveCheckOnClick = (videoId: string) => {
    // videoId가 이미 선택되어 있는 경우
    if (checkedVideoIdList.current.includes(videoId)) {
      // 필터로 제거 (videoId가 같은건 사라짐)
      checkedVideoIdList.current = checkedVideoIdList.current.filter(x => x !== videoId)
    } else {
      // 새로 배열에 추가
      checkedVideoIdList.current.push(videoId)
    }

    listCheckBoxOnClickHandle();
  };

  const removeAction = async () => {
    if (removeOk) {
      const memberId = getCookie('SU_TOKEN');

      if (!memberId) {
        alert('User Auth Invalid! Try Again Please.');
        window.location.href = 'https://surroundio.org';
      }

      try {
        for (const id of checkedVideoIdList.current) {
          await request('POST', '/history/delete', {
            vid: id,
          });
        }

        setRemoveOk(false);
        alert('Successfully processed.');
      } catch (error) {
        alert('A temporary error has occurred. History Delete Error');
        window.location.reload();
      }
    }
  };

  /** 클릭한 경우, 링크를 복사합니다. */
  const onClickLinkCopy = (url: string) => {
    // 참고: https환경에서만 동작 가능
    // 참고2: 해당 링크를 클릭한경우, 동영상 파일을 바로 다운로드 진행.
    // 동영상을 보여주기 위해서, 페이지를 제공할 필요가 있습니다.
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert(lang.CLOUD_COPY_LINK_MESSAGE);
      });
  }

  const onClickDownload = async (url: string) => {
    await downloadFileUrl(url, videoStore.video);
  };

  const onClickBGM = (item: IUploadedVideo) => {
    uiHistory.hideLoading();
  };

  const onClose = () => {
    setRemoveOk(false);
    uiPopup.hideLoading();
  };

  useEffect(() => {
    const authToken = getAuthTokenFromCookie();

    if (!authToken) {
      alert('User Auth Invalid! Try Again Please.');
      //window.location.href = 'https://surroundio.org';
    }

    const data: any = request('GET', '/history');

    data.then((res: any) => setList(res.data));

    if (removeOk) {
      removeAction();
      uiPopup.hideLoading();
    }
  }, [show, removeOk]);

  function PCArea () {
    return (
      <Container
        show={show}
      >
        {/* ConfirmArea는 분리 불가능, 이 부분은 상위 엘리먼트의 기능에 의존되어있는 것 같음. */}
        <div onClick={e => e.stopPropagation()}>
          <Confirm
            {...{
              block: uiPopup.loading,
              header: lang.CLOUD_DELETE_TITLE,
              content:
                lang.CLOUD_DELETE_CONTENT,
              confirmButtonOnClick,
            }}
          />
        </div>
        <RegisterMusicBoardContainer>
          <Table>
            <MyProject/>
            <DeleteButtonSectionArea/>
            <BlankLineArea/>
            <TableHeaderArea/>
            <TableDataArea/>
          </Table>
        </RegisterMusicBoardContainer>
      </Container>
    )
  }

  function MoblieArea () {
    return (
      <ContainerMoblie show={show}>
        <Confirm
          {...{
            block: uiPopup.loading,
            header: lang.CLOUD_DELETE_TITLE,
            content: lang.CLOUD_DELETE_CONTENT,
            confirmButtonOnClick,
          }}
        />

        <RegisterMusicBoardContainer>
          <Table>
            <MyProject/>
            <DeleteButtonSectionArea/>
            {list && <MoblieGridDataArea/>}
          </Table>
        </RegisterMusicBoardContainer>
      </ContainerMoblie>
    )
  }

  // Search는, useObserver 컴포넌트가 아닌 다른 컴포넌트에 넣었을 때
  // 검색어를 입력할 때마다 focus를 잃는 문제가 발견되었습니다.
  // 그래서, focus를 유지하기 위해, 검색창만 따로 분리했습니다.
  // 모바일 영역과 PC 영역은 서로 다를 수 있습니다.
  return useObserver(() =>
    {return show ?
      <Wrapper>
        <Search value={q} onChange={setQ} placeHolder={lang.CLOUD_SEARCH_PLACE_HOLDER} />
        {IS_MOBILE ? <MoblieArea/> : <PCArea/>}
      </Wrapper>
     : null}
  );
};

/** table에서 사용하는 padding-top 값 */
const PADDING_TOP_HEIGHT = 20

/** Search 태그에서 사용하는 height 값 */
const SEARCH_HEIGHT = 36

/** 모바일에서 전체 높이 중 빼야 하는 값,
 * 모바일 클라우드의 높이는, 사이드바와 헤더를 가리면 안됩니다. 그래서 사이드바와 헤더 높이만큼을 빼야합니다.
 * 그리고, PADDING_TOP도 빼야하고, SEARCH_HEIGHT도 빼야합니다. 왜 Search가 공간을 차지하지 않는것처럼 계산되는지 모르겠지만
 * 이런저런 값들을 전부 빼야, 밑의 사이드바를 보여주면서 배치할 수 있습니다.
 * 이 값은 상수 값이므로, css값을 적용할 때 px라는 문자열을 추가해야 합니다. */
const MOBILE_MINUS_HEIGHT = parseInt(constLayout.MOBILE_HEADER_HEIGHT) + parseInt(constLayout.MOBILE_SIDEBAR_HEIGHT) + PADDING_TOP_HEIGHT + SEARCH_HEIGHT

const Wrapper = styled.div`
  position: fixed;
  left: 0px;

  top: ${IS_MOBILE ? constLayout.MOBILE_HEADER_HEIGHT : constLayout.PC_HEADER_HEIGHT};
  left: ${IS_MOBILE ? '0px' : constLayout.PC_SIDEBAR_WIDTH};

  /* 표시되는 공간은 모바일 버전이 아닌 경우 전체 공간에서 사이드바 너비를 빼야 함 */
  width: calc(100vw - ${IS_MOBILE ? '0px' : constLayout.PC_SIDEBAR_WIDTH});

  /* 표시되는 공간은 전체 공간에서 헤더 높이만큼 빼야 함 */
  height: calc(100vh - ${IS_MOBILE ? MOBILE_MINUS_HEIGHT + 'px' : constLayout.PC_HEADER_HEIGHT});

  background-color: #191919;
`;
const Container = styled.div<{ show: boolean }>`
  position: absolute;

  width: 100%;
  height: 100%;
  max-width: 100%;

  // 모바일이 아닐 때, 최소 길이를 적용하지 않도록 처리
  ${!IS_MOBILE && 'min-width: 720px;'}

  z-index: 22;

  ${({ show }) =>
    show == false
      ? css`
          display: none;
        `
      : css`
          display: flex;
        `}

  justify-content: center;
`;

const ContainerMoblie = styled.div<any>`
  width: 100%;
  height: 100%;
  background-color: #191919;

  z-index: 311;

  ${({ show }) =>
    show == false
      ? css`
          display: none;
        `
      : css`
          display: flex;
        `}

  justify-content: center;
`;

const RegisterMusicBoardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const BacgroundOpacity = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: gray;
  opacity: 0.6;
`;

/** 내 프로젝트 표시용 태그, 강제로 왼쪽에 배치시키기 위해 absolute 포지션을 부여 */
const MyProjectDiv = styled.span`
  position: absolute;
  left: 28px;
`

const MyProjectText = styled.span`
  color: var(--colors-common-white, #FFF);

  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
  letter-spacing: -0.48px;
`

const MyProjectCount = styled.span`
  color: var(--Default-Gray-400, var(--colors-common-white, #AFAFAF));
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.48px;
`

const Table = styled.div`
  background-color: #191919;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;

  // PC버전은 맨 위 공간을 띄우기 위해 패딩을 추가하지만,
  // 모바일은 페딩을 제거함 (사이즈 계산 문제 때문에)
  padding-top: ${PADDING_TOP_HEIGHT}px;
`;

const TableHeader = styled.div`
  width: 95%;
  height: 40px;
  padding-top: 1px;
  background-color: #2E2E2E;

  display: flex;
  flex-direction: row;

  margin-bottom: 10px;
  border-radius: 5px;
`;

const TableRow = styled.div`
  width: 95%;
  height: 60px;
  min-height: 60px;

  display: flex;
  flex-direction: row;

  margin-bottom: 3px;

  border-radius: 5px;

  &:hover {
    width: 95%;
    height: 69px;
    box-shadow: 2px 2px 5px 1px #888282;
    z-index: 99990;
  }
`;

const TableColumn = styled.div<any>`
  width: ${props => {
    return props.setPercentColumnWidth;
  }}%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  color: #D1D1D1;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 137.5%
  letter-spacing: -0.32px;

  /* svg 이미지도 같이 img태그를 사용하기 때문에, 썸네일 적용 스타일은 따로 클래스를 추가했습니다. */
  img.thumbnail {
      width: 63px;
      height: 63px;
  }

  span {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const TableData = styled.div`
  width: 100%;
  height: ${IS_MOBILE ? 'calc(100% - 40px)' : '100%'};
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  // PC 버전은 맨 밑의 리스트를 출력하기 위해 일정 영역을 지정하였음. 
  // 모바일은 이 값의 영향을 받아 일부 영역이 회색으로 출력되기 때문에, 0px로 설정함
  margin-bottom: ${IS_MOBILE ? '0px' : '100px'};

  span {
    color: black;
  }

  .download {
    color: blue;
  }
`;

/** 참고: 그리드 레이아웃과 테이블 레이아웃은 동일합니다. 다만 이름 구분을 위해 이 변수를 따로 만들었습니다. */
const GridData = TableData

const TableDataSpan = styled.div`
  color: #D1D1D1;

  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
`

const TableDataCheckbox = styled.input`
  /* 기본 체크박스 형태는 보이지 않음 */
  appearance: none;
  width: 21px;
  height: 21px;

  /* 일반 상태의 체크박스 이미지 */
  background: url(${uncheckedIcon}) no-repeat;

  /* 체크 상태의 체크박스 이미지 */
  &:checked {
    background: url(${checkedIcon}) no-repeat;
  }
`

const DeleteButtonSection = styled.div`
  width: 95%;

  display: flex;
  justify-content: right;
`;

const DeleteButtonSelectButtonDisable = styled.button`
  display: inline-flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #2E2E2E;
  color: #777777;

  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;

  pointer-events: none;

  &:hover {
    cursor: pointer;
    background-color: #666666;
  }
`

const DeleteButtonSelectButtonEnable = styled.button`
  display: inline-flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #2E2E2E;
  color: #777777;

  color: var(--colors-common-white, #FFF);

  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;

  &:hover {
    cursor: pointer;
    background-color: #666666;
  }
`

const GridDataTitle = styled.div`
  color: #D0D0D0;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;

  word-break: break-all;
`

const GridDataTime = styled.div`
  color: #999;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;

  word-break: break-all;
`