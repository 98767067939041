import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import LinkIcon from 'asset/header/link.svg';
import LogoImage from 'asset/logo.png';

import { HeaderIcon, ShareButton } from './buttons';

interface PlayerHeaderProps {}
export const PlayerHeader = observer(({}: PlayerHeaderProps) => {
  const onCopyURL = () => {
    navigator.clipboard.writeText(window.location.href);
    alert('URL Copied to clipboard');
  };

  return (
    <Container>
      <SAppBar>
        <Toolbar>
          <AppTitle
            variant="h6"
            noWrap
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            onClick={() => window.open('https://surroundio.org/', '_blank')}
          >
            <img
              src={LogoImage}
              style={{ width: '180px', transform: 'translateY(5px)' }}
            />
          </AppTitle>
          <Box sx={{ flexGrow: 1 }} />

          <IconContainer>
            <HeaderIcon src={LinkIcon} onClick={onCopyURL} />
            <ShareButton />
          </IconContainer>
        </Toolbar>
      </SAppBar>
    </Container>
  );
});

const Container = styled(Box).attrs({
  sx: { flexGrow: 1 },
})``;

const SAppBar = styled(AppBar).attrs({
  position: 'static',
})`
  background-color: #59cbfc;
`;
const AppTitle = styled(Typography).attrs({
  component: 'div',
})`
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 18px;
`;
