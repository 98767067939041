import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';

import { useStores } from 'store';
import { useSearchParamStore } from 'store/router';
import { EqMenu } from '../EqMenu';
import { EffectMenu } from '../EffectMenu';
import { ShutterStockMenu } from '../ShutterStockMenu';
import { MainAudioVolume } from '../MainAudioVolume';
import { constLayout } from 'constant/layout';
import { getLanguageData } from 'constant/language';
import { HistoryDesktop } from 'component/history/HistoryDesktop';
const lang = getLanguageData()

interface SidebarMobileProps {}
export const SidebarMobile = observer(({}: SidebarMobileProps) => {
  const { videoStore, uiMobileEffectMenu, uiHistory, uiStore } = useStores();
  const [menu, setMenu] = useState<string | null>();
  const expand = uiStore.showSidebar;

  const MAIN_AUDIO_VOLUME = lang.SIDEBAR_VIDEO;

  const toggleMenu = (name: string) => {
    if (name === 'history') {
      if (uiHistory.loading) {
        uiHistory.hideLoading();
      } else {
        uiHistory.showLoading();
      }
    } else {
      if (menu === name) {
        setMenu(null);
        uiStore.showSidebar = false;
      } else {
        setMenu(name);
        uiStore.showSidebar = true;
      }
    }
  };

  // history menu click
  const historyOpenOnClickHandle = () => {
    toggleMenu('history');
  };

  const videoEffectMenuOpenOnClickHandle = () => {
    if (uiMobileEffectMenu.loading == false) {
      uiMobileEffectMenu.showLoading();
    } else {
      uiMobileEffectMenu.hideLoading();
    }
  };

  const onClickChatGPT = () => {
    if (videoStore.isReady === false) {
      return;
    }

    uiStore.showChatPrompt = true;
  };

  useEffect(() => {
    if (videoStore.isReady) {
      setTimeout(() => {
        // setMenu("effect");
        uiMobileEffectMenu.showLoading();
      }, 1000);
    }
  }, [videoStore.isReady]);

  return (
    <Container expand={expand}>
      {expand && menu !== MAIN_AUDIO_VOLUME && (
        <ExpandMenuContainer>
          {menu === 'effect' && <EffectMenu />}
          {menu === 'node' && <EqMenu />}
          {menu === 'shutterstock' && <ShutterStockMenu />}
          {menu === 'history' && <HistoryDesktop show={true}/> }

          <FoldButton onClick={() => setMenu(null)} />
        </ExpandMenuContainer>
      )}

      {/* ExpandMenuContainer 안에 이 메뉴를 넣으면 일정 공간을 반드시 차지해서, 
        비디오가 클릭되지 않는 문제가 있습니다. 
        그래서, 이 메뉴만 따로 공간을 분리했습니다. */}
      {menu === MAIN_AUDIO_VOLUME && <MainAudioVolume />}

      <MenuContainer>
        <IconContainer>
          <Icon
            src={require('asset/sidebar/mastering.svg').default}
            onClick={() => toggleMenu('effect')}
          />
          <IconContainerText>{lang.SIDEBAR_MASTERING}</IconContainerText>
        </IconContainer>

        <IconContainer>
          <Icon
            src={require('asset/sidebar/music.svg').default}
            onClick={() => toggleMenu('shutterstock')}
          />
          <IconContainerText>{lang.SIDEBAR_MUSIC}</IconContainerText>
        </IconContainer>

        <IconContainer>
          <Icon
            src={require('asset/sidebar/originalAudioVolume.svg').default}
            onClick={() => toggleMenu(MAIN_AUDIO_VOLUME)}
          />
          <IconContainerText>{lang.SIDEBAR_VIDEO}</IconContainerText>
        </IconContainer>

        {/* <Icon
          src={require('asset/sidebar/eq.svg').default}
          onClick={() => toggleMenu('effect')}
        /> */}

        <IconContainer>
          <Icon
            src={require('asset/sidebar/history.svg').default}
            onClick={() => historyOpenOnClickHandle()}
            style={{ height: '36px' }}
          />
          <IconContainerText>{lang.SIDEBAR_CLOUD}</IconContainerText>
        </IconContainer>

        {/* <IconContainer>
          <Icon
            src={require('asset/sidebar/robot.svg').default}
            onClick={() => onClickChatGPT()}
            style={{ height: '30px' }}
          />
        </IconContainer> */}

        {/* {videoStore.isReady ? (
          <IconContainer>
            <Icon
              src={require('asset/sidebar/align-text-justify.svg').default}
              onClick={() => videoEffectMenuOpenOnClickHandle()}
              style={{ height: '36px' }}
            />
          </IconContainer>
        ) : (
          ''
        )} */}
      </MenuContainer>
    </Container>
  );
});

const Container = styled.div<any>`
  position: fixed;
  left: 0px;
  bottom: 0px;

  width: 100%;

  background: black;

  transition: all 0.13s ease;

  ${({ expand }) =>
    expand
      ? css`
          height: ${constLayout.MOBILE_SIDEBAR_HEIGHT};
        `
      : css`
          height: ${constLayout.MOBILE_SIDEBAR_HEIGHT};
        `}
`;
const MenuContainer = styled.div`
  display: flex;
  height: 100%;

  gap: 12px;

  align-items: center;
  justify-content: center;
  z-index: 9;
`;

const ExpandMenuContainer = styled.div`
  position: fixed;
  left: 0px;
  bottom: ${constLayout.MOBILE_SIDEBAR_HEIGHT};

  width: 100vw;
  height: 70vh;

  overflow: hidden;

  z-index: 7;
`;

const FoldButton = styled.div`
  position: absolute;

  right: -16px;
  top: calc(50vh - 30px - 32px);

  width: 16px;
  height: ${constLayout.MOBILE_SIDEBAR_HEIGHT};

  background: black;

  border-radius: 0px 10px 10px 0px;

  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  width: 96px;
  height: ${constLayout.MOBILE_SIDEBAR_HEIGHT};

  flex-direction: column;
  align-items: center;
  justify-content: center;
  
`;
const Icon = styled.img`
  size: 24px;
  height: 24px;
  display: block;

  cursor: pointer;

  user-select: none;
  transition: all 0.25s ease;

  :hover {
    transform: scale(1.18);
  }
`;

const IconContainerText = styled.span`
  color: #D1D1D1;
  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.2px;
`
