import styled from 'styled-components';

export const HeaderIcon = styled.img`
  display: inline-flex;
  height: 40px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  cursor: pointer;
  border-radius: 8px;
  background: linear-gradient(104deg, #D63CAB -4.93%, #6A6CFC 110.7%);
`;
