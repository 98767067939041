import { getLanguageData } from "constant/language"
import { useEffect, useRef, useState } from "react"
import { videoStore } from "store/video"
import styled from "styled-components"
import { RangeSlider } from "toolcool-range-slider"
import { isMobile } from "util/mobile"

const lang = getLanguageData()

export const MainAudioVolume = () => {
  const volumeSliderRef = useRef<RangeSlider>(null)
  useEffect(() => {
    const onPointerClicked = (evt: Event) => {
      let customEvent = evt.currentTarget as any
      if (isNaN(customEvent.value1)) return

      let value = (customEvent.value1 / 100) // 게인으로 변환하기 위해 100으로 나눔
      if (videoStore.mixer != null) {
        videoStore.mixer.setMainAudioGainValue(value)
      }
    }

    if (volumeSliderRef.current) {
      volumeSliderRef.current.min = 0
      volumeSliderRef.current.step = 1
      volumeSliderRef.current.max = 100

      if (videoStore.mixer != null) {
        let value = videoStore.mixer.getMainAudioGainValue()
        value = Math.floor(value * 100) // 100배로 증가 (게인 범위가 0 ~ 1 이므로)
        volumeSliderRef.current.value = value
      }

      volumeSliderRef.current.addEventListener('change', onPointerClicked)
    }

    return () => {
      volumeSliderRef.current?.removeEventListener('change', onPointerClicked)
    }
  }, [])

  const VolumeValueArea = () => {
    const [f, setF] = useState(0);
    useEffect(() => {
      let intenvalId = setInterval(() => {
        setF((f) => f + 1)
      }, 100)
      
      return () => clearInterval(intenvalId)
    }, [])

    return (
      <MainText>{volumeSliderRef.current != null && volumeSliderRef.current.value + '%/100%'}</MainText>
    )
  }

  const MainAudioVolumeArea = () => {
    /** 이 텍스트는 임시로 남겨둠, 필요없다면 삭제 가능 @deprecated */
    const HELP_TEXT = '참고: 마스터링을 적용한 경우, 원본 오디오의 볼륨이 슬라이더의 값보다 더 높게 적용될 수 있습니다.'

    return (
      <div style={{paddingTop: '5%'}}>
        <MainText>{lang.MENU_VIDEO_VOLUME}</MainText>
        <VolumeValueArea></VolumeValueArea>
        <SliderContent>
          <MainText style={{textAlign: 'left', width: '10%'}} >0</MainText>
          <div style={{width: '80%'}}>
            <tc-range-slider id='rangeslider' ref={volumeSliderRef}
              animate-onclick="false"
              slider-bg-fill='#6A6CFC'
              slider-width='300px'
              pointer-border="2px solid #6A6CFC" />
          </div>
          <MainText style={{textAlign: 'right', width: '10%'}} >100</MainText>
        </SliderContent>
      </div>
    )
  }

  // 모바일과 PC버전이 서로 다른 스타일을 사용해서, 서로 구분함
  return (
    <div>
      {isMobile()
        ? <MainAudioVolumeComponentMoblie>
            <MainAudioVolumeArea/>
          </MainAudioVolumeComponentMoblie>
        : <MainAudioVolumeComponent>
            <MainAudioVolumeArea/>
          </MainAudioVolumeComponent>}
    </div>
  )
}


// 참고: 각 공간의 구분을 위하여 배경색을 다르게 설정함.
const MainAudioVolumeComponent = styled.div`
  align-items: center;
  padding-top: 5%;
  gap: 29px;
  background-color: #2F2F2F;
`

const MainText = styled.div`
  text-align: center;
  color: #FFF;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

const SliderContent = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`

const MainAudioVolumeComponentMoblie = styled.div`
  align-items: center;
  gap: 29px;
  background-color: #2F2F2F;
  width: 100%;
  height: 20%;
  position: fixed;
  top: 70%;
`