import { action, observable } from 'mobx';

export interface IUiStore {
  loading: boolean;
  time?: number;
  isCheck?: boolean;
  text?: string;
  url?: string;
  title?: string;
  content?: string;
  msg?: string;
  showSidebar: boolean;
  showChatPrompt: boolean;

  showLoading(time?: number): void;
  showLoading(url?: string): void;
  hideLoading(): void;
  open(): void;
  cancle(): void;
  ok(): void;
  setText(str?: string, type?: number): void;
}

export const uiStore = observable({
  loading: false,
  showSidebar: false,
  showChatPrompt: false,

  showLoading() {
    this.loading = true;
  },
  hideLoading() {
    this.loading = false;
  },
} as IUiStore);

/** history 메뉴 로드 관련 객체 */
export const uiHistory = observable({
  /** history 메뉴가 실행 중일 때, 이 값은 true이고, 아니라면 false입니다. */
  loading: false,

  /** histroy 메뉴를 실행 중 상태로 변경 */
  showLoading() {
    this.loading = true;
  },

  /** history 메뉴를 실행 중지 상태로 변경 */
  hideLoading() {
    this.loading = false;
  },
} as IUiStore);

export const uiToast = observable({
  loading: false,
  url: '',

  showLoading(url: string) {
    this.url = url;
    this.loading = true;
  },
  hideLoading() {
    this.loading = false;
  },
} as IUiStore);

export const uiLoading = observable({
  loading: false,

  showLoading() {
    this.loading = true;
  },

  hideLoading() {
    this.loading = false;
  },
} as IUiStore);

export const uiPopup = observable({
  loading: false,
  isCheck: false,
  title: '',
  content: '',

  showLoading() {
    this.loading = true;
  },

  hideLoading() {
    this.loading = false;
  },

  ok() {
    this.isCheck = true;
  },

  cancle() {
    this.isCheck = false;
  },

  setText(str: string, type: number) {
    if (type == 0) {
      this.title = str;
    } else if (type == 1) {
      this.content = str;
    }
  },
} as IUiStore);

export const uiTimeLine = observable({
  loading: false,
  isCheck: false,

  showLoading(type: number) {
    // if isCheck true -> effect, false -> music
    type == 0 ? (this.isCheck = true) : (this.isCheck = false);
    this.loading = true;
  },

  hideLoading() {
    this.loading = false;
  },
} as IUiStore);

export const uiMobileEffectMenu = observable({
  loading: false,

  showLoading() {
    this.loading = true;
  },

  hideLoading() {
    this.loading = false;
  },
} as IUiStore);

export const uiMobileBgmMenu = observable({
  loading: false,

  showLoading() {
    this.loading = true;
  },

  hideLoading() {
    this.loading = false;
  },
} as IUiStore);
