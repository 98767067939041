import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'store';
import styled, { css } from 'styled-components';

import { useSearchParamStore } from 'store/router';
import { EqMenu } from '../EqMenu';
import { MainAudioVolume } from '../MainAudioVolume';
import { EffectMenu } from '../EffectMenu';
import { constLayout } from 'constant/layout';
import { ShutterStockMenu } from '../ShutterStockMenu';
import { getLanguageData } from 'constant/language';

const lang = getLanguageData()

// 공통된 문자열을 한번에 처리하기 위한 문자열 상수 (메뉴에도 텍스트용도로도 사용합니다.)
const MASTERING = lang.SIDEBAR_MASTERING;
const CLOUD = 'Cloud';
const MUSIC = lang.SIDEBAR_MUSIC;
const NODE = 'Node';
const MAIN_VIDEO = lang.SIDEBAR_VIDEO;

interface SidebarDesktopProps {}
export const SidebarDesktop = observer(({}: SidebarDesktopProps) => {
  const fileElement = useRef<HTMLInputElement>();
  const { videoStore } = useStores();
  const [params, setParams] = useSearchParamStore();
  const menu = params.menu;
  const { uiHistory, uiToast, uiLoading, uiStore } = useStores();

  const expand = !!menu;

  const toggleMenu = (name: string) => {
    if (videoStore.isReady === false) {
      if (name !== CLOUD) {
        return;
      }
    }

    if (menu === name) {
      // 메뉴를 열고 닫을 때 마다, 검색어는 초기화됩니다.
      setParams({ menu: undefined, q: '' });

      // 선택한 메뉴가 다시 history 일 경우 페이지 숨김 처리
      if (name === CLOUD && uiHistory.loading) {
        uiHistory.hideLoading();
      } else if (name === CLOUD) {
        uiHistory.showLoading();
        // setMenu(name);
      }
    } else {
      // 메뉴를 이동할 때 마다, 검색어는 초기화됩니다.
      setParams({ menu: name, q: '' });

      // 파일 업로드 이후, 히스토리 페이지를 오픈한 뒤 GNB 클릭 시 정상 적용을 위한 조건 분기 처리
      if (name === CLOUD && !uiHistory.loading) {
        uiHistory.showLoading();
        setParams({ menu: undefined });
      } else if (name === CLOUD && uiHistory.loading) {
        uiHistory.hideLoading();
        setParams({ menu: undefined });
      }

      if (name !== CLOUD) {
        // 선택한 메뉴가 history 가 아닌 경우 및 history 페이지가 오픈되어 있는 경우 숨김 처리
        if (uiHistory.loading == true) uiHistory.hideLoading();
      }
    }
  };

  // history menu click
  const historyOpenOnClickHandle = () => {
    toggleMenu(CLOUD);
  };

  const onClickChatGPT = () => {
    if (videoStore.isReady === false) {
      return;
    }

    uiStore.showChatPrompt = true;
  };

  const onClicksetCustomTrack = () => {
    fileElement.current?.click();
  };

  useEffect(() => {
    const elem = document.createElement('input');
    elem.type = 'file';
    elem.accept = 'audio/*';
    elem.addEventListener('change', (e: any) => {
      const file = e.target.files[0];
      let fileUrl = URL.createObjectURL(file);
      let audio = new Audio();
      audio.src = fileUrl;
      videoStore.mixer?.setCustomTrack(audio);

      if (videoStore.mixer != null) {
        videoStore.mixer.isMainAudioMuted = true;
      }
    });

    fileElement.current = elem;
  }, []);

  useEffect(() => {
    if (videoStore.isReady) {
      setTimeout(() => {
        setParams({ menu: MASTERING });
      }, 1000);
    }
  }, [videoStore.isReady]);

  return (
    <Container expand={expand}>
      <MenuContainer>
        <IconContainer active={menu === MASTERING}>
          <Icon
            src={require('asset/sidebar/mastering.svg').default}
            onClick={() => toggleMenu(MASTERING)}
          />
          <IconContainerText>{MASTERING}</IconContainerText>
        </IconContainer>

        <IconContainer active={menu === MUSIC}>
          <Icon
            src={require('asset/sidebar/music.svg').default}
            onClick={() => toggleMenu(MUSIC)}
          />
          <IconContainerText>{MUSIC}</IconContainerText>
        </IconContainer>

        <IconContainer active={menu === MAIN_VIDEO}>
          <Icon
            src={require('asset/sidebar/originalAudioVolume.svg').default}
            onClick={() => toggleMenu(MAIN_VIDEO)}
          />
          <IconContainerText>{MAIN_VIDEO}</IconContainerText>
        </IconContainer>

        <IconContainer active={menu === CLOUD}>
          <Icon
            style={{ width: '24px', height: '24px', padding: '12px' }}
            src={require('asset/sidebar/history.svg').default}
            onClick={() => historyOpenOnClickHandle()}
          />
          <IconContainerText>{CLOUD}</IconContainerText>
        </IconContainer>

        {/* <IconContainer active={menu === 'chatgpt'}>
          <Icon
            style={{ width: '24px', height: '24px', padding: '12px' }}
            src={require('asset/sidebar/robot.svg').default}
            onClick={() => onClickChatGPT()}
          />
          <span>AI</span>
        </IconContainer> */}

        {/*}
        <IconContainer active={menu === 'customAudio'}>
          <Icon
            style={{ width: '24px', height: '24px', padding: '12px' }}
            src={require('asset/sidebar/align-text-justify.svg').default}
            onClick={() => onClicksetCustomTrack()}
          />
          <span>
            Custom Audio(Beta)<br></br>(incomplete)
          </span>
        </IconContainer>
  */}
      </MenuContainer>

      {expand && (
        <>
          {menu === MASTERING && <EffectMenu />}
          {menu === NODE && <EqMenu />}
          {menu === MUSIC && <ShutterStockMenu />}
          {menu === MAIN_VIDEO && <MainAudioVolume />}

          {/* 해당 버튼은 사용되지 않는것 같습니다. */}
          {/* <FoldButton onClick={() => setMenu(null)} /> */}
        </>
      )}
    </Container>
  );
});

const Container = styled.div<any>`
  display: flex;
  grid-template-columns: ${constLayout.PC_SIDEBAR_WIDTH} 1fr;

  background: #1C1C1D;

  transition: all 0.13s ease;

  ${({ expand }) =>
    expand
      ? css`
          width: ${constLayout.PC_SIDEBAR_EXPAND_WIDTH};
        `
      : css`
          width: ${constLayout.PC_SIDEBAR_WIDTH};
        `}
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 12px;

  padding: 12px 0px;

  align-items: center;
`;

const FoldButton = styled.div`
  position: absolute;

  right: -16px;
  top: calc(50vh - 30px - 32px);

  width: 16px;
  height: 60px;

  background: black;

  border-radius: 0px 10px 10px 0px;

  cursor: pointer;
`;
const IconContainer = styled.div<{ active: boolean }>`
  color: white;

  font-size: 10px;
  text-align: center;

  display: flex;
  width: ${constLayout.PC_SIDEBAR_WIDTH};
  height: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
  flex-shrink: 0;

  > span {
    display: block;

    transform: translateY(-7.5px);
  }

  ${({ active }) =>
    active
      ? css``
      : css`
          opacity: 0.5;
        `}
`;
const Icon = styled.img`
  size: 24px;
  height: 25px;

  cursor: pointer;

  transition: all 0.25s ease;

  :hover {
    transform: scale(1.24);
  }
`;
const IconContainerText = styled.span`
  color: #FFF;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
`