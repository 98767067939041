import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'store';
import styled from 'styled-components';
import { isIOS } from 'util/mobile';

interface VideoProps {}
export const Video = observer(({}: VideoProps) => {
  const { videoStore } = useStores();
  const ref = useRef<any>();

  useEffect(() => {
    const tid = setInterval(() => {
      videoStore.time = Math.floor(ref.current.currentTime);
    }, 1000);
    return () => clearInterval(tid);
  }, []);

  return (
    <Container>
      <VideoPlayer
        controls
        playsInline
        preload="metadata"
        id="main-video"
        ref={ref}
        src={videoStore.videoUrl!}
        poster={isIOS ? '/black.png' : undefined}
      />
      <div id="waveform" />
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  position: absolute;
  top: 5%;
  left: 10%;
  width: 80%;
  max-width: 90vw;
  height: 50%;

  @media (max-width: 480px) {
    height: 45vw;
  }
`;
const VideoPlayer = styled.video`
  width: 100%;
  height: 100%;
`;
