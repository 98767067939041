import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { IEffect, IEqEffect, ISubEffect } from 'model';
import { useStores } from 'store';
import styled from 'styled-components';

import CloseIcon from 'asset/icon/close.svg';

import { NonSelectable } from 'util/css';
import { isMobile } from 'util/mobile';
import { isTestMode } from 'util/testmode';
import { Search } from 'component/input';
import { useEffects } from 'store/hook';
import { useSearchParamStore } from 'store/router';
import { videoStore } from 'store/video';
import IconToggleDisable from 'asset/toggleDisable.svg';
import IconToggleEnable from 'asset/toggleEnable.svg';
import { drawFrequencyGraph, drawTimeDomainGraph } from './analyserGraph';
import { constLayout } from 'constant/layout';
import { getLanguageData } from 'constant/language';

interface EffectMenuProps {}
const IS_MOBILE = isMobile();

const lang = getLanguageData();
const LEFT_ARROW = '◀';
const RIGHT_ARROW = '▶';
const ORIGINAL = 'Original';
const SURROUNDIO = 'Surroundio';

export const EffectMenu = observer(({}: EffectMenuProps) => {
  const [params, setParams] = useSearchParamStore();
  const { uiStore, videoStore, timelineStore } = useStores();
  const q = params.q ?? '';
  const [expand, setExpand] = useState(false);
  const [page, setPage] = useState(0);
  const effects = useEffects();
  const canvasOriginalAudio = useRef<HTMLCanvasElement>(null);
  const canvasSurroundioAudio = useRef<HTMLCanvasElement>(null);
  const animationId = useRef<number>(0);
  /** 최대 이펙트 표시 개수 */ const MAX_EFFECT_COUNT = IS_MOBILE ? 15 : 15;

  const [forceUpdate, setForceUpdate] = useState(0);
  const forceUpdateRef = useRef(0);

  /** 다이얼로그 보여주기 */
  const [showDialog, setShowDialog] = useState(false);

  /** 썸네일 주소 ref (사용자가 클릭한 이펙트의 썸네일을 다이얼로그에 표시할려면 이 값이 필요함) */
  const [thumbnail, setThumbnail] = useState('');

  /** 선택한 이펙트의 이름 */
  const [effectName, setEffectName] = useState('');

  /** 마스터링 적용에 대한 ref (이 값이 false이면 마스터링을 끕니다.) */
  const enableMasteringRef = useRef(true);
  const [enableMasteringState, setEnableMasteringState] = useState(true);

  /** 마스터링 on/off를 변경할때, 이전에 적용했었던 마스터링 이펙트를 기억하기 위한 ref */
  const effectRef = useRef<IEffect>();

  /** 컨테이너 영역을 얻기 위한 ref */
  const containerRef = useRef<HTMLDivElement>(null);

  /** 마우스 커서의 스크린 좌표 */
  const pcDialogMouseScreen = useRef({x: 0, y: 0})

  /** 마우스가 이동할 때 이벤트 (다만, 이 함수는 좌표 측정 용도로만 사용됨) */
  const pcDialogMouseMoveEvent = (e: MouseEvent) => {
    pcDialogMouseScreen.current.x = e.pageX
    pcDialogMouseScreen.current.y = e.pageY
  }

  useEffect(() => {
    // 현재 적용중인 마스터링을 표시하기 위한 초기화 작업
    autoEffectName()
  }, [])

  /** 현재 타임라인에 등록되어 있는 이펙트를 자동으로 표시합니다. */
  const autoEffectName = () => {
    if (timelineStore.effects.length >= 1) {
      setEffectName(timelineStore.effects[0].effect.name)
    } else {
      setEffectName('')
    }
  }

  /** 마스터링 적용을 할지 말지를 변경합니다. */
  const changeEnableMastering = (enable = true) => {
    enableMasteringRef.current = enable
    setEnableMasteringState(enable)

    if (effectRef.current == null) return

    const start = 0; // 이펙트는 영상의 0초부터 적용됨
    if (enableMasteringRef.current) {
      timelineStore.addEffect({
        effect: effectRef.current,
        group: 0,
        start: start,
        end: videoStore.getDuration() != null ? start + videoStore.getDuration() : start + 1, 
      });
    } else {
      // 이펙트를 미적용 상태로 업데이트 함
      videoStore._updateMixer(
        timelineStore.backgroundSounds, [], true,
      );
    }
  }

  /** 
   * 마스터링 적용 상태를 기본값 (항상 적용됨), 상태로 적용합니다.
   * 
   * 이 함수는, 마스터링 미리듣기를 종료한 후, 다이얼로그를 닫을 때 실행해, 다시 마스터링을 적용한 상태로 변경합니다.
   */
  const enableMasteringDefault = () => {
    // 믹서를 원래대로 되돌림
    videoStore._updateMixer(
      timelineStore.backgroundSounds, timelineStore.effects, true,
    );
    autoEffectName()
  }

  /** 이펙트 다이얼로그 영역 (사용자가 마스터링을 선택했을 때 사용) */
  const EffectDialogArea = () => {
    const pcDialogRef = useRef<HTMLDivElement>(null);

    const animation = () => {
      // 마스터링의 시각적 효과를 위해, 오리지널의 컬러는 조금 더 어두운색으로 배치했습니다.
      const originalColor = ['#fface7', '#8383ff']
      const originalDomainColor = ['#ff26c1', '#2525ff']

      if (canvasOriginalAudio.current && videoStore.mixer) {
        // 캔버스를 지운 후, 다시 그립니다.
        const context = canvasOriginalAudio.current.getContext('2d')
        context?.clearRect(0, 0, canvasOriginalAudio.current.width, canvasOriginalAudio.current.height)

        drawFrequencyGraph(
          canvasOriginalAudio.current,
          videoStore.mixer.analyserOriginal,
          {color: originalColor, clear: false}
        );
        drawTimeDomainGraph(
          canvasOriginalAudio.current,
          videoStore.mixer.analyserOriginal,
          {color: originalDomainColor, clear: false}
        );
      }
  
      if (canvasSurroundioAudio.current && videoStore.mixer && enableMasteringRef.current) {
        // 캔버스를 지운 후, 다시 그립니다.
        const context = canvasSurroundioAudio.current.getContext('2d')
        context?.clearRect(0, 0, canvasSurroundioAudio.current.width, canvasSurroundioAudio.current.height)
        drawFrequencyGraph(
          canvasSurroundioAudio.current,
          videoStore.mixer.analyserEffect,
          {clear: false}
        )
        drawTimeDomainGraph(
          canvasSurroundioAudio.current,
          videoStore.mixer.analyserEffect, 
          {clear: false}
        )
      }
  
      // 캔버스가 있을 때만 에니메이션 호출되도록 변경
      if (canvasOriginalAudio.current) {
        animationId.current = requestAnimationFrame(animation);
      }

      // pcDialog의 위치는 마우스 위치에 따라서 계속 변경됨
      if (pcDialogRef.current && containerRef.current) {
        // 보정치 계산, 화면 바깥을 이동하지 않도록 처리
        const dialogRect = pcDialogRef.current.getBoundingClientRect()
        const containerRect = containerRef.current.getBoundingClientRect()
        // 출력 위치가 16정도의 오차가 있는것은, 마우스가 캔버스 내에 들어가는것을 막기 위해서
        // 이 오차가 적으면 마우스가 다이얼로그 영역 안에 들어가서 원하는 대로 동작하지 않음
        let outputX = pcDialogMouseScreen.current.x + 16
        let outputY = pcDialogMouseScreen.current.y + 16

        // 오른쪽 초과 금지
        if (outputX + dialogRect.width > containerRect.right) {
          outputX = containerRect.right - dialogRect.width
        }
        
        // 만약, 아래쪽 길이보다 더 밑이 내려가면, 마우스 위쪽에 다이얼로그를 배치
        if (outputY + dialogRect.height > containerRect.bottom) {
          outputY = pcDialogMouseScreen.current.y - dialogRect.height - 16
        }

        pcDialogRef.current.style.position = 'fixed'
        pcDialogRef.current.style.left = outputX + 'px'
        pcDialogRef.current.style.top = outputY + 'px'
      }
    };
  
    // 에니메이션 처리
    useEffect(() => {
      animation();
      return () => cancelAnimationFrame(animationId.current);
    }, []);

    /** 다이얼로그를 종료했을 때 사용하는 함수 */
    const onClickClose = () => {
      setShowDialog(false)

      // 마스터링이 꺼져있으면, 이를 원상태로 되돌림
      if (!enableMasteringRef.current) {
        enableMasteringDefault()
      }
    }

    /** 
     * 이펙트 다이얼로그 모바일 버전, 
     * 마스터링 효과의 비교를 그래프로 보여주고, 마스터링 미리듣기를 on/off 할 수 있습니다.
     * 
     * PC랑 padding이 약간 다름
     */
    function EffectDialogMoblie () {
      return (
        <EffectDialog>
          <img src={CloseIcon} alt="close" style={{padding:'5%', float:'right'}} onClick={() => onClickClose()} />
          <img style={{position:'absolute', left:'2%', top:'15%', width:'20%'}} src={thumbnail} alt="" />
          <EffectDialogText style={{position:'absolute', left: '25%', top:'15%', width:'50%'}}>{effectName}</EffectDialogText>
          <div style={{position:'absolute', top:'15%', left:'75%'}}>
            {enableMasteringState 
              ? <img src={IconToggleEnable} onClick={() => changeEnableMastering(false)} />
              : <img src={IconToggleDisable} onClick={() => changeEnableMastering(true)} />}
          </div>
          <canvas ref={canvasOriginalAudio} width={512} height={200} 
            style={{width:'92%', height:'30%', top:'32%', position:'absolute', border: '1px solid white'}} />
          <canvas ref={canvasSurroundioAudio} width={512} height={200} 
            style={{width:'92%', height:'30%', top:'64%', position:'absolute', border: '1px solid #D63CAB'}} />
          <EffectDialogText style={{top:'32%', position:'absolute'}}>{ORIGINAL}</EffectDialogText>
          <EffectDialogTextSurroundio style={{top: '64%', position:'absolute'}}>{SURROUNDIO}</EffectDialogTextSurroundio>
        </EffectDialog>
      )
    }

    /** 
     * 이펙트 다이얼로그 PC 버전, 
     * 마스터링 효과의 비교를 그래프로 보여줍니다. (모바일과 기능은 동일하나, 일부 요소가 없음)
     * 
     * 이 이펙트 다이얼로그의 기본 위치는 화면에 벗어나있습니다.
     * 초기 위치 때문에 마우스가 다이얼로그 위에 있을 수 있기 때문입니다.
     * 초기 위치 문제로 인해 마우스가 다이얼로그 위에 있다면 다이얼로그가 마우스를 따라가지 못합니다.
     */
    function EffectDialogPC () {
      return (
        <EffectDialog ref={pcDialogRef} style={{top: '-1200px', left: '-1200px'}}>
          <canvas ref={canvasOriginalAudio} width={512} height={200} 
            style={{width:'99%', height:'50%', top:'0%', position:'absolute', border: '1px solid white'}} />
          <canvas ref={canvasSurroundioAudio} width={512} height={200} 
            style={{width:'99%', height:'50%', top:'50%', position:'absolute', border: '1px solid #D63CAB'}} />
          <EffectDialogText style={{top:'0%', position:'absolute'}}>Original</EffectDialogText>
          <EffectDialogTextSurroundio style={{top: '50%', position:'absolute'}}>Surroundio</EffectDialogTextSurroundio>
        </EffectDialog>
      )
    }

    // EffectDialog에 있는 padding 값 때문에, 레이아웃 위치가 임의로 지정되어있습니다.
    // 그래서, 일부 수치는 10%단위로 나누어 떨어지지 않습니다.
    // 디자인 의도대로 표현하기 위해 absoulte로 위치가 지정되어있어서, 구조가 복잡할 수 있습니다.
    return (
      <div>
        {IS_MOBILE ? <EffectDialogMoblie/> : <EffectDialogPC/>}
      </div>
    )
  }

  // hoveredEffect 처리를 위한 useEffect (단, 모바일에서는 사용하지 않음)
  useEffect(() => {
    if (!videoStore.isReady || IS_MOBILE) {
      return;
    }

    if (timelineStore.hoveredEffect) {
      videoStore._updateMixer(
        timelineStore.backgroundSounds,
        [
          {
            id: '0',
            effect: timelineStore.hoveredEffect,
            group: 0,
            start: 0,
            end: videoStore.getDuration(),
          },
        ],
        true,
      );
    } else {
      videoStore._updateMixer(
        timelineStore.backgroundSounds,
        timelineStore.effects,
        true,
      );
    }
  }, [timelineStore.hoveredEffect]);

  /**
   * 클릭한 이펙트를 다이얼로그에 표시합니다.
   * @param x 이펙트
   * @param name 이펙트의 이름
   * @param thumbnail 이펙트의 섬네일 주소
   */
  const onClickEffect = async (x: IEffect, name: string, thumbnail: string) => {
    const start = 0; // 이펙트는 영상의 0초부터 적용됨
    setThumbnail(thumbnail) // 썸네일 설정
    setEffectName(name)
    effectRef.current = x

    // 다이얼로그 보여주기
    if (!showDialog) { 
      setShowDialog(true)
    }

    changeEnableMastering(true) // 마스터링 적용

    timelineStore.addEffect({
      effect: x!,
      group: 0,
      start: start,
      end: videoStore.getDuration() != null ? start + videoStore.getDuration() : start + 1, 
    });
  };

  /** 이펙트 아이템을 표시 */
  const EffectItem = ({ data, onClick }: EffecItemProps) => {
    const { timelineStore } = useStores();
    const [hovered, setHovered] = useState(false);
    const [index, setIndex] = useState(0);
  
    const onHover = (effect: any) => {
      timelineStore.hoveredEffect = effect;
    };
  
    const onMouseEnter = () => {
      setHovered(true);
      onHover({ ...data, nodes: data.nodes?.[index].nodes });
      setShowDialog(true)
    };
    const onMouseLeave = (e: any) => {
      setHovered(false);
      timelineStore.hoveredEffect = null;
      setShowDialog(false)
    };
  
    // 참고: 맨 밑에 br이 있는 이유는, 텍스트의 공간을 만들기 위해서입니다.
    // 텍스트 영역이 너무 넓어서, 마스터링 미리듣기 적용은 이미지에 마우스가 닿았는지를 기준으로 결정합니다.
    return (
      <EffectContainer
        key={data.thumbnail}
      >
        <EffectImage
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          src={data.nodes[index].thumbnail}
          onClick={() => onClick({ ...data, nodes: data.nodes[index].nodes }, data.name, data.nodes[index].thumbnail)}
        />
        <EffectName>{data.name}</EffectName>
        <br/>
      </EffectContainer>
    );
  };

  const PageArea = () => {
    // 최대 페이지 값이 검색 결과의 개수의 영향을 받음
    // 검색 개수는 이름을 마스터링의 검색한 결과임
    const searchCount = effects.filter(x => x.name.includes(q)).length
    const MAX_PAGE = Math.floor(searchCount / MAX_EFFECT_COUNT)

    // 강제 렌더링이 진행될 때, 현재 페이지가 최대 페이지를 초과한경우, 이를 조정함
    useEffect(() => {
      if (page > MAX_PAGE) setPage((p) => MAX_PAGE)
    })

    /** 마스터링 목록의 이전 페이지로 이동 */
    const prevPage = () => {
      if (page > 0) setPage((p) => p - 1)
      if (page > MAX_PAGE) setPage((p) => MAX_PAGE)
    }

    /** 마스터링 목록의 다음 페이지로 이동 */
    const nextPage = () => {
      if (page < MAX_PAGE) setPage((p) => p + 1)
    }

    // &nbsp; 공백, 빈 공간을 만들기 위한 강제 띄어쓰기가 있음.
    return (
      <ContainerText>
        <ButtonArrow onClick={prevPage} >{LEFT_ARROW}</ButtonArrow>&nbsp;
        {lang.MENU_MASTERING_PAGE}: {page} / {MAX_PAGE} &nbsp;
        <ButtonArrow onClick={nextPage} >{RIGHT_ARROW}</ButtonArrow>&nbsp;
      </ContainerText>
    )
  }

  return (
    <Container expand={expand} ref={containerRef}>
      <Header>
        <Search value={q} onChange={newQ => setParams({ q: newQ })} placeHolder={lang.MENU_MASTERING_SEARCH_PLACEHOLDER}/>
      </Header>
      <PageArea/>
      <ContainerText>{lang.MENU_MASTERING_TITLE} {effectName && ' -> ' + effectName}</ContainerText>
      <Depth1 onMouseMove={(e) => pcDialogMouseMoveEvent(e)}>
        {effects
        .filter(x => x.name.includes(q)) // 이름 검색
        .filter((value, index, array) => {
          const rangeStart = MAX_EFFECT_COUNT * page
          const rangeEnd = MAX_EFFECT_COUNT * (page + 1)

          // 페이지 내의 시작번호부터 끝번호까지 리턴 (MAX_EFFECT_COUNT 개수만큼)
          if (index >= rangeStart && index < rangeEnd) {
            return true
          } else {
            return false
          }
        }).map((x, index) => {
            return (
              <EffectItem
                key={index}
                data={x}
                onClick={(effect, name, thumbnail) => onClickEffect(effect, name, thumbnail)}
              />
            )
        })}
      </Depth1>
      {showDialog && <EffectDialogArea/>}
    </Container>
  );
});

interface EffecItemProps {
  /** ? */ data: any;
  /**
   * 클릭시 전송되는 이펙트에 대한 정보
   * 
   * 자세한것은 EffectMenu에 있는 onClickEffect 함수 참고
   * @param effect 
   * @param name 
   * @param thumbnail 
   * @returns 
   */ 
  onClick: (effect: IEffect, name: string, thumbnail: string) => void;
}

const Container = styled.div<any>`
  position: relative;
  grid-template-rows: max-content 1fr;
  background-color: #191919;

  height: 100%;

  padding: 8px;

  overflow: hidden;
`;

const ContainerText = styled.div`
  padding: 8px;
  color: #FFF;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
`;

const ButtonArrow = styled.button`
  justify-content: center;
  align-items: center;
  padding: 5px;

  background: #444444;
  transition: 0.4s;
  color: white;
`;

const Header = styled.div`
  padding: 8px;
  padding-bottom: 0px;
`;

/** 
 * 마스터링 목록을 표현하는 공간입니다. PC와 모바일의 배치는 다릅니다.
 * 
 * padding, gap이 각 공간을 차지하므로, grid-template-columns는 
 * 모바일은 3x3을 표현하기 위해 33%보다 낮은 수치로, pc는 2x2를 표현하기 위해 50%보다 낮은 수치로 설정합니다.
 * 
 * depth1의 height는 100%로 지정하면, 맨 밑이 잘려보이므로, pc는 85%, 모바일은 75%크기로 조정
*/
const Depth1 = styled.div`
  width: 100%;
  height: ${IS_MOBILE ? '75%' : '85%'};
  display: grid;
  grid-template-columns: ${IS_MOBILE ? '31% 31% 31%' : '42% 42%'};

  gap: ${IS_MOBILE ? '2%' : '4%'};
  padding: 4px;

  overflow: auto;

  box-sizing: border-box;
`;

const EffectImage = styled.img`
  width: 100%;
  height: 60px;
  align-self: stretch;

  border-radius: 8px;

  object-fit: cover;
  transition: all 0.2s ease;
  cursor: pointer;

  ${NonSelectable}

  :hover {
    filter: contrast(1.2);
    transform: scale(0.995);
  }
`;
const EffectContainer = styled.div`
  position: relative;
  height: 100%;

  ${NonSelectable}
`;
const EffectName = styled.div`
  color: #FFF;
  height: var(--layout-radius-rounded-large, 14px);
  align-self: stretch;

  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;

  padding: 4px 8px;
`;

/** 사이드바 영역의 PC 너비 (사이드바가 고정px라 이 값을 계산해야 할 필요가 있음), 
 * 결과값에서 40을 빼는 이유는 공간 여유를 표시하기 위함(꽉 차면 답답하므로...) */
const sidebarAreaPCWidth = parseInt(constLayout.PC_SIDEBAR_EXPAND_WIDTH) - parseInt(constLayout.PC_SIDEBAR_WIDTH) - 40;

/** 
 * 마스터링을 선택했을 때 출력되는 다이얼로그 
 * 
 * 모바일의 너비는 100%이고, PC버전은 일부만 너비를 차지해야 하므로 20%입니다.
 */
const EffectDialog = styled.div`
  display: relative;
  position: fixed;
  padding: ${IS_MOBILE ? '2%' : '0'};
  top: 50%;
  width: ${IS_MOBILE ? '100%' : sidebarAreaPCWidth + 'px'};
  height: ${IS_MOBILE ? '50%' : '30%'};
  background-color: #0F0F0F;
`;

const EffectDialogText = styled.span`
  color: #FFF;
  width: 30%;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
`;

const EffectDialogTextSurroundio = styled.span`
  width: 30%;
  background: linear-gradient(104deg, #D63CAB -4.93%, #6A6CFC 110.7%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
`;