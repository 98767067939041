import React, {
  DragEvent,
  DragEventHandler,
  MouseEvent,
  TouchEvent,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { CircularProgress, Slider } from '@mui/material';
import { curry, debounce, isNil, min } from 'lodash-es';
import { observer } from 'mobx-react';
import { AudioApi } from 'shutterstock-api';
import { useStores } from 'store';
import styled from 'styled-components';
import { RangeSlider } from 'toolcool-range-slider';
import WaveSurfer from 'wavesurfer.js';

import IconClose from 'asset/icon/close.svg';
import IconLeft from 'asset/music/left.svg';
import IconMenuDisable from 'asset/music/menuDisable.svg';
import IconMenuEnable from 'asset/music/menuEnable.svg';
/** 음악 미리 듣기 내에 있는 일시정지 아이콘 (파란색이라서 blue) */ import IconPauseBlue from 'asset/music/pauseBlue.svg';
/** 플레이 아이콘 */ import IconPlay from 'asset/music/play.svg';
/** 음악 미리 듣기 내에 있는 플레이 아이콘 (파란색이라서 blue) */ import IconPlayBlue from 'asset/music/playBlue.svg';
/** 음악 추가 아이콘 */ import IconPlus from 'asset/music/plus.svg';
/** 음악 추가가 완료된 아이콘 (파란색이라서 blue) */ import IconPlusBlue from 'asset/music/plusBlue.svg';
import IconRight from 'asset/music/right.svg';

import { NonSelectable } from 'util/css';
import { isMobile } from 'util/mobile';
import { Search } from 'component/input';
import { useSearchParamStore } from 'store/router';
import { userStore } from 'store/user';
import { getLanguageData } from 'constant/language';
import { constLayout } from 'constant/layout';
import { shutterStockGernes, shutterStockMoods } from './ShutterStockList';

interface ITrack {
  title: string;
  description: string;
  url: string;
  thumbnail: string;
  duration: number;
}

const IS_MOBILE = isMobile();
const lang = getLanguageData();

interface ShutterStockMenuProps {}
export const ShutterStockMenu = observer(({}: ShutterStockMenuProps) => {
  const [params, setParams] = useSearchParamStore();
  const { uiStore, videoStore, timelineStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const q = params.q ?? '';
  const [tracks, setTracks] = useState<ITrack[]>([]);
  const [expand, setExpand] = useState(false);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const previewAudio = useRef<HTMLAudioElement>(new Audio());
  const [forceUpdate, setForceUpdate] = useState(0);

  /** preview 영역을 강제 업데이드 하도록 요청합니다. */
  const [requestForceUpdatePreview, setRequestForceUpdatePreview] = useState(0);

  /** eventListener로 인해 setForceUpdate로도 리렌더링이 안된다면, 추가로 사용하는 ref */
  const forceUpdateRef = useRef(0);

  const [showMusicPreview, setShowMusicPreview] = useState(false);

  /** 음악을 미리듣기 해야 하는 대상의 정보를 저장함 */
  const previewTarget = useRef({
    /** 음악의 주소 */ url: '',
    /** 음악의 타이틀 */ title: '',
    /** 음악의 시간 */ duration: 0,
    /** 음악의 섬네일 주소 */ thumbnail: '',
  });

  const watermark = useRef<HTMLAudioElement>(new Audio('/watermark.mp3'));

  const rangeSlider = useCallback((slider: RangeSlider) => {
    const onPointerClicked = (evt: Event) => {
      let customEvent = evt.currentTarget as any;
      // 둘중 하나의 값이 잘못된 값이라면, bpm변경하지 않음.
      if (isNaN(customEvent.value1) || isNaN(customEvent.value2)) return;

      forceUpdateRef.current += 1; // 강제 업데이트 실행 버전 2
      inputBpm(customEvent.value1, customEvent.value2);
    };

    if (slider != null) {
      slider.min = 0;
      slider.round = 0;
      slider.step = 1;
      slider.max = 300;
      slider.value1 = bpmFromRef.current;
      slider.value2 = bpmToRef.current;
      slider?.addEventListener('onPointerClicked', onPointerClicked);
    }

    return () => {
      slider?.removeEventListener('onPointerClicked', onPointerClicked);
    };
  }, []);

  /** 선택 안함 */ const NOT_SELECT = 'Not Select';
  /** bpmFrom: 해당 bpm이상 (bpmFrom ~ bpmTo) */ const bpmFromRef = useRef(0);
  /** bpmTo: 해당 bpm이하 (bpmFrom ~ bpmTo) */ const bpmToRef = useRef(300);
  const [selectMenu, setSelectMenu] = useState('');

  /** 장르 선택, 선택 안함이라면 NOT_SELECT 상수를 입력해주세요. */
  const selectGenre = useRef(NOT_SELECT);

  /** 무드 선택, 선택 안함이라면 NOT_SELECT 상수를 입력해주세요. */
  const selectMoods = useRef(NOT_SELECT);

  /** 비어있는 이미지 (드래그 미리보기 이미지 제거 용도로 사용됨) */
  const blankImage = useRef<HTMLImageElement>(null);

  const MENU_GENRE = lang.MENU_MUSIC_GENRE;
  const MENU_MOODS = lang.MENU_MUSIC_MOODS;
  const MENU_BPM = lang.MENU_MUSIC_BPM;

  /** 장르 목록 (검색 가능한 전체 범위), 배열 맨 처음에 선택 없음도 추가 */
  const genreList = [NOT_SELECT, ...shutterStockGernes];

  /** 무드 목록 (검색 가능한 전체 범위), 배열 맨 처음에 선택 없음도 추가 */
  const moodsList = [NOT_SELECT, ...shutterStockMoods];

  /**
   * 메뉴 설정 (장르, 무드를 설정할 때 사용하는 함수)
   *
   * 메뉴 값은, 상수로 정의되어있음. MENU_ 이름으로 시작하는 변수 참고
   */
  const selectMenuInput = (menu: string, targetText: string) => {
    if (menu === MENU_GENRE) {
      selectGenre.current = targetText;
    } else if (menu === MENU_MOODS) {
      selectMoods.current = targetText;
    }

    search(q);
    selectMenuCall();
  };

  /** 메뉴를 호출해서 세부 목록을 보여줌, 메뉴가 공백일경우, 기존 메뉴가 사라짐 */
  const selectMenuCall = (menu: string = '') => {
    if (selectMenu === menu) {
      setSelectMenu('');
    } else {
      setSelectMenu(menu);
    }
  };

  const inputBpm = (bpmFrom: number, bpmTo: number) => {
    bpmToRef.current = bpmTo;
    bpmFromRef.current = bpmFrom;
    bpmMinMaxChange();
    setForceUpdate(forceUpdateRef.current + 1);
  };

  function GenreListComponent() {
    // GenreComponent의 리스트 값들을 이용하여 객체를 만듬
    // 공백이 추가되어 있는 이유는 일정 영역을 강제로 띄우기 위해서임.
    return (
      <TrackButtonMenu>
        {genreList.map((value, index) => {
          return (
            <TrackButtonMenuList
              onClick={() => selectMenuInput(MENU_GENRE, value)}
              key={index}
            >
              {' '}
              {value === selectGenre.current ? '>> ' + value : value}{' '}
            </TrackButtonMenuList>
          );
        })}
      </TrackButtonMenu>
    );
  }

  function MoodsListComponent() {
    return (
      <TrackButtonMenu>
        {moodsList.map((value, index) => {
          return (
            <TrackButtonMenuList
              onClick={() => selectMenuInput(MENU_MOODS, value)}
              key={index}
            >
              {' '}
              {value === selectGenre.current ? '>> ' + value : value}{' '}
            </TrackButtonMenuList>
          );
        })}
      </TrackButtonMenu>
    );
  }

  function BpmComponentTest21() {
    const BPM_MIN = 0;
    const BPM_MAX = 300;

    // 양방향 슬라이드를 구현하기 위하여, 2개의 슬라이드를 겹쳐서 처리해야함
    // 위치를 고정시켜야 하므로 position은 absolute임
    // 슬라이드의 위치가 변경됨으로 인하여, span 위치도 absolute로 처리함
    // tc-range-slider 태그는 양방향 슬라이더를 표현할 수 있는 태그입니다.
    return (
      <TrackButtonMenu>
        <div style={{ width: '90%', padding: '5%' }}>
          <tc-range-slider
            id="rangeslider"
            ref={rangeSlider}
            animate-onclick="false"
            slider-bg-fill="#6A6CFC"
            slider-width="330px"
            pointer-border="2px solid #6A6CFC"
          />
        </div>
        <div>
          <TrackButtonMenuBpmSlideNumber style={{ float: 'left' }}>
            {BPM_MIN}
          </TrackButtonMenuBpmSlideNumber>
          <TrackButtonMenuBpmSlideNumber style={{ float: 'right' }}>
            {BPM_MAX}+
          </TrackButtonMenuBpmSlideNumber>
        </div>
        <span style={{ position: 'absolute', top: '30%', left: '5%' }}>
          {lang.MENU_MUSIC_BPM_TEXT}
        </span>
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            top: '36%',
            left: '0%',
            justifyContent: 'center',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <TrackButtonMenuBpmNumberDiv>
            {bpmFromRef.current}
          </TrackButtonMenuBpmNumberDiv>
          <div style={{ width: '30%', textAlign: 'center' }}> ~ </div>
          <TrackButtonMenuBpmNumberDiv>
            {bpmToRef.current}
          </TrackButtonMenuBpmNumberDiv>
        </div>
        <TrackButtonMenuBpmButtonGroup
          style={{ position: 'absolute', top: '80%', width: '100%' }}
        >
          <TrackButtonMenuBpmButtonGroupSubmit
            onClick={() => {
              bpmFromRef.current = 0;
              bpmToRef.current = 300;
              setForceUpdate(forceUpdate + 1); // 값 변화 이후 강제 렌더링
            }}
          >
            {lang.MENU_MUSIC_BPM_ALL_DELETE}
          </TrackButtonMenuBpmButtonGroupSubmit>
          <TrackButtonMenuBpmButtonGroupSubmit
            onClick={() => selectMenuInput('', '')}
          >
            {lang.MENU_MUSIC_BPM_ACCEPT}
          </TrackButtonMenuBpmButtonGroupSubmit>
        </TrackButtonMenuBpmButtonGroup>
      </TrackButtonMenu>
    );
  }

  function TrackButtonArea() {
    return (
      <div>
        <TrackButtonDiv>
          <TrackButton onClick={() => selectMenuCall(MENU_GENRE)}>
            {MENU_GENRE}
            {selectMenu === MENU_GENRE && <TrackButtonHighlight />}
          </TrackButton>
          <TrackButton onClick={() => selectMenuCall(MENU_MOODS)}>
            {MENU_MOODS}
            {selectMenu === MENU_MOODS && <TrackButtonHighlight />}
          </TrackButton>
          <TrackButton onClick={() => selectMenuCall(MENU_BPM)}>
            {MENU_BPM}
            {selectMenu === MENU_BPM && <TrackButtonHighlight />}
          </TrackButton>
        </TrackButtonDiv>
        {selectMenu === MENU_GENRE && <GenreListComponent></GenreListComponent>}
        {selectMenu === MENU_MOODS && <MoodsListComponent></MoodsListComponent>}
        {selectMenu === MENU_BPM && <BpmComponentTest21></BpmComponentTest21>}
      </div>
    );
  }

  const onClickItem = async (x: ITrack) => {
    if (timelineStore.backgroundSounds.length >= 5) {
      console.warn('No more music can be added.');
      return;
    }

    const videoDuration = videoStore.getDuration();

    /** 음악의 재생 길이: 영상의 길이를 초과할 수 없음 */
    const targetDuration =
      x.duration < videoDuration ? x.duration : videoDuration;

    timelineStore.addBackgroundSound({
      name: x.title,
      volume: 0.25,
      start: 0,
      end: 0 + targetDuration,
      musicDuration: x.duration,
      url: x.url,
      channel: 0,
      rangeStart: 0,
      rangeEnd: targetDuration,
      rangeDuration: targetDuration,
      rangeEndback: 0,
      fadeIn: 3,
      fadeOut: 3,
    });

    if (IS_MOBILE) {
      // 현재는 음악을 추가해도 음악 창을 닫지 않음
      // uiStore.showSidebar = false;
    }
  };

  /** onClickItem과 유사하지만, 이것은 음악 미리듣기에서 음악을 추가할 때 사용합니다. */
  const onClickItemOptions = (
    title: string,
    url: string,
    duration: number,
    rangeStart = 0,
    rangeDuration = 0,
  ) => {
    const videoDuration = videoStore.getDuration();

    /** 음악의 재생 길이: 영상의 길이를 초과할 수 없음 */
    const maxDuration = duration < videoDuration ? duration : videoDuration;

    // 범위 시간은 영상의 최대 시간을 초과할 수 없음
    if (rangeDuration > maxDuration) {
      rangeDuration = maxDuration;
    }

    timelineStore.addBackgroundSound({
      name: title,
      volume: 0.25,
      start: 0,
      end: 0 + rangeDuration,
      musicDuration: duration,
      url: url,
      channel: 0,
      rangeStart: rangeStart,
      rangeEnd: rangeStart + rangeDuration,
      rangeDuration: rangeDuration,
      rangeEndback: 0,
      fadeIn: 3,
      fadeOut: 3,
    });

    setShowMusicPreview(false);
  };

  /** 시간 길이를 분 초 텍스트로 변환합니다. */
  const durationToMinuteSecond = (value: number) => {
    value = Math.floor(value); // 소수 버리기
    let minute = Math.floor(value / 60);
    let second = value % 60;
    let secondText = ('' + second).padStart(2, '0');

    return minute + ':' + secondText;
  };

  const loadMore = useCallback(
    async (q: string, page: number) => {
      const PerPage = 20;

      if (isLoading) {
        return;
      }

      try {
        setIsLoading(true);
        bpmMinMaxChange(); // 만약 bpm의 최대 최소가 반전되어있다면, 이를 강제로 변경함.
        const audioApi = new AudioApi();
        const queryParams = {
          query: q,
          view: 'full',
          sort: 'score',
          page,
          per_page: PerPage,
          bpm_from: bpmFromRef.current,
          bpm_to: bpmToRef.current,

          // 참고: 장르 선택은, 배열로 전달해야 하는데, 값이 올바르지 않으면 아무런 검색결과가 없습니다.
          // 만약, 장르가 없다면, 배열이 아닌 string값을 넣어주세요.
          // NOT_SELECT 로 선택된 경우, 장르는 없는것으로 처리
          // 장르는 영어만 인정됩니다. (한글 사용 불가능)
          // moods도 장르랑 동일한 방식임
          genre:
            selectGenre.current === NOT_SELECT ? '' : [selectGenre.current],
          moods:
            selectMoods.current === NOT_SELECT ? '' : [selectMoods.current],
        };

        const { data } = await audioApi.searchTracks(queryParams);

        setPage(page);
        setHasNextPage(data.length === PerPage);
        setTracks(prev => [
          ...prev,
          ...data.map((x: any) => ({
            title: x.title,
            description: x.description,
            url: x.assets.preview_mp3.url,
            thumbnail: x.assets.waveform.url,
            duration: x.duration,
          })),
        ]);

        console.log(data);
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading],
  );

  const search = useCallback(
    debounce(async (q: string) => {
      setPage(1);
      setHasNextPage(true);
      setTracks([]);

      loadMore(q, 1);
    }, 500),
    [],
  );

  // 어떤 이유인지는 모르겠으나, setState를 사용한 변수들은 다른곳에서는 인식을 못하고
  // 이 useEffect 함수 내에서만 제대로 인식됨
  useEffect(() => {
    search(q);
  }, [q]);

  useEffect(() => {
    // 오디오 crossOrigin 무시하도록 변경 (처음 실행 시만 동작)
    previewAudio.current.crossOrigin = 'anonymous';

    return () => {
      // 컴포넌트가 닫히면 오디오 정지
      previewAudio.current.pause();
      previewAudio.current.src = '';
      watermark.current.pause();
      watermark.current.src = '';
    };
  }, []);

  /** bpm이 변경되었을 때, bpmTo ~ bpmFrom의 값이 서로 반전되어있다면, 이를 보정함 */
  const bpmMinMaxChange = () => {
    if (bpmFromRef.current == null) return;
    if (bpmToRef.current == null) return;

    const bpmTo = Number(bpmToRef.current);
    const bpmFrom = Number(bpmFromRef.current);

    // 반전되어있는경우 (최대/최소가 서로 뒤바뀐 경우)만, 값을 보정
    if (bpmTo < bpmFrom) {
      bpmFromRef.current = bpmTo;
      bpmToRef.current = bpmFrom;
    }
  };

  /** 음악 미리듣기 영역 */
  const MusicPreviewArea = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
      return () => {
        // 미리듣기 창을 종료한다면, 미리듣기된 음악은 강제로 정지됨
        previewAudio.current.pause();
      };
    }, []);

    // 캔버스를 일정 시간단위로 계속 드로잉 하는 함수
    // 이미지를 로드하는 시간이 필요하기 때문에, 일정 시간마다 해당 함수를 호출해서 언젠가는 이미지가 표시됨
    useEffect(() => {
      function abc() {
        if (canvasRef.current) {
          const context = canvasRef.current.getContext('2d');
          if (context == null) return;
          const image = new Image();

          image.crossOrigin = 'anonoymous'; // 교차 출처 검증 없음
          image.src = previewTarget.current.thumbnail; // 이미지 경로 설정

          /** 섬네일 이미지의 너비 */ const IMAGE_WIDTH = 660;
          /** 섬네일 이미지의 나누어진 높이 (4개의 파형이 붙여있는 형태라 이를 1개로 나누어야 합니다.) */ const IMAGE_HEIGHTDIV = 135;

          // 이미지를 캔버스에 그림 (일부분만 잘라서)
          // 참고: 이미지가 로드되지 않으면 그려지지 않음. 이 경우 다시 그려야 함
          context.clearRect(
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height,
          );
          context.drawImage(
            image,
            0,
            0,
            IMAGE_WIDTH,
            IMAGE_HEIGHTDIV,
            0,
            0,
            IMAGE_WIDTH,
            IMAGE_HEIGHTDIV,
          );

          // 현재 음악 진행 상황을 막대바로 표시
          // 캔버스의 크기를 기준으로 막대 위치를 정해야 함
          let percent =
            previewAudio.current.currentTime / previewAudio.current.duration;
          let x = percent * canvasRef.current.width;

          context.fillStyle = '#cb1092';
          context.fillRect(x, 0, 4, canvasRef.current.height);
        }
      }

      let p = setInterval(abc, 100);
      return () => clearInterval(p);
    });

    const [f, setF] = useState(0); // forceUpdate

    // 강제 렌더링 요청이 프리뷰 영역에 도착한 경우, 다시 렌더링 함
    // 이것은, 다른 음악을 선택했을 때, 선택된 음악이 변경된것을 출력하기 위함
    useEffect(() => {
      setF(f => f + 1);
    }, [requestForceUpdatePreview]);

    /** 음악 재생 범위 시작값을 저장하는 ref */ const rangeStartRef = useRef(0);
    /** 음악 재생 범위 길이값을 저장하는 ref */ const rangeDurationRef =
      useRef(0);

    /** 플레이 시간 표시, 이 컴포넌트는 일정시간마다 강제렌더링을 시도함 (그래야 시간이 계속 변경되는게 보여지니까) */
    function PlayTime() {
      const [f, setF] = useState(0); // forceUpdate

      useEffect(() => {
        const intervalId = setInterval(() => {
          setF(f => f + 1);
        }, 100);

        return () => {
          clearInterval(intervalId);
        };
      }, [f]);

      return (
        <div>
          <MusicPreviewSmallText>
            {durationToMinuteSecond(previewAudio.current.currentTime)}
          </MusicPreviewSmallText>
          <MusicPreviewSmallText style={{ float: 'right' }}>
            {durationToMinuteSecond(previewAudio.current.duration)}
          </MusicPreviewSmallText>
        </div>
      );
    }

    function WaveArea() {
      useEffect(() => {
        if (canvasRef.current != null) {
          rangeStartRef.current = 0;
          rangeDurationRef.current = previewTarget.current.duration; // 길이의 기본값은 오디오 길이만큼으로 처리
        }
      }, []);

      // 왼쪽 오른쪽에서 얼마나 떨어져있는지에 대한 비율, 이 값은 음악의 길이를 조정하는 데에 사용됨
      // 참고: wave 내부의 길이는, left, right 수치값에 따라 알아서 조정됨
      // 값 오류 또는 0 나누기 방지를 위해 최솟값이 존재할 수 있음.
      const [mouse, setMouse] = useState({
        /** 왼쪽은 0 ~ 100, 시작 지점을 조정함 */ left: 0,
        /** 오른쪽은 0 ~ 100, 끝 지점을 조정함 */ right: 0,
        /** 왼쪽 이동 여부 */ isLeft: false,
        /** 오른쪽 이동 여부 */ isRight: false,
      });

      const divRef = useRef<HTMLDivElement>(null);
      const iconLeftRef = useRef<HTMLImageElement>(null);
      const iconRightRef = useRef<HTMLImageElement>(null);
      const timeLeftRef = useRef<HTMLSpanElement>(null);
      const timeRightRef = useRef<HTMLSpanElement>(null);

      const mouseDownProcess = (clientX: number, clientY: number) => {
        if (iconLeftRef.current == null || iconRightRef.current == null) return;

        const left = iconLeftRef.current.getBoundingClientRect();
        const right = iconRightRef.current.getBoundingClientRect();

        const isLeft = clientX >= left.x && clientX <= left.x + left.width;
        const isRight = clientX >= right.x && clientX <= right.x + right.width;
        if (isLeft) {
          setMouse({
            left: mouse.left,
            right: mouse.right,
            isLeft: isLeft,
            isRight: false,
          });
        } else if (isRight) {
          setMouse({
            left: mouse.left,
            right: mouse.right,
            isLeft: false,
            isRight: true,
          });
        }
      };

      const onMouseDown = (event: MouseEvent) => {
        mouseDownProcess(event.clientX, event.clientY);
      };

      const onMouseMove = (event: MouseEvent) => {
        mouseMoveProcess(event.clientX, event.clientY);
      };

      const mouseMoveProcess = (clientX: number, clientY: number) => {
        if (
          iconLeftRef.current == null ||
          iconRightRef.current == null ||
          divRef.current == null
        )
          return;
        if (
          timeLeftRef.current == null ||
          timeRightRef.current == null ||
          canvasRef.current == null
        )
          return;
        const wave = canvasRef.current.getBoundingClientRect();
        const div = divRef.current.getBoundingClientRect();
        const leftDiv = iconLeftRef.current.getBoundingClientRect();
        const rightDiv = iconRightRef.current.getBoundingClientRect();

        // 전체 div 길이중 마우스의 지점에 어느 지점에 위치했는지를 기록함.
        // 그리고, 어느것이 눌렸나에 따라 해당 커서의 위치를 재조정
        // 모바일은 가로 영역 전체를 기준으로 판단함
        // PC 버전은 사이드 바 내 영역을 기준으로 판단함, 두개가 서로 위치를 파악하는 공식이 다르므로 주의

        let mouseX = clientX;
        let mouseXPercent = 0;

        // 좌표 이동 제한
        if (mouseX < div.left) mouseX = div.left;
        else if (mouseX > div.right) mouseX = div.right;

        if (mouse.isLeft) {
          // 왼쪽에 있는것이 움직일 때
          // 오른쪽에 있는 rect보다 더 오른쪽으로 이동하면 안됨, 그럴경우 강제로 위치 조정
          if (mouseX >= rightDiv.left || leftDiv.right > rightDiv.left) {
            mouseX = rightDiv.left - rightDiv.width;
          }

          // 마우스 퍼센트 조정
          mouseXPercent = ((leftDiv.right - wave.x) / wave.width) * 100;

          // 마우스 설정 및, 아이콘 위치 조정
          setMouse({
            left: mouseXPercent,
            right: mouse.right,
            isLeft: mouse.isLeft,
            isRight: mouse.isRight,
          });

          // 모바일 버전은 마우스 좌표를 그대로 입력
          if (IS_MOBILE) {
            iconLeftRef.current.style.left = mouseX + 'px';
            timeLeftRef.current.style.left = mouseX + 'px';
          } else {
            // PC 버전은 사이드바 위치만큼 좌표가 조정됨, 그리고 마우스의 안정적인 이동을 위해 일부 값이 보정되어있음
            // 보정치가 없으면, 드래그 도중 마우스가 사각형 바깥을 벗어날 수 있음
            iconLeftRef.current.style.left = mouseX - div.x - 12 + 'px';
            timeLeftRef.current.style.left = mouseX - div.x - 12 + 'px';
          }
        } else if (mouse.isRight) {
          // 왼쪽에 있는 rect보다 더 왼쪽으로 이동하면 안됨, 그럴경우 강제로 위치 조정
          if (mouseX <= leftDiv.right || rightDiv.left < leftDiv.right) {
            mouseX = leftDiv.right + leftDiv.width;
          }

          // 마우스 퍼센트 조정
          mouseXPercent = ((rightDiv.left - wave.x) / wave.width) * 100;

          // 참고: right는 left랑 반대 방향이므로, %적용도 반대로 계산해야 함
          setMouse({
            left: mouse.left,
            right: mouseXPercent,
            isLeft: mouse.isLeft,
            isRight: mouse.isRight,
          });
          if (IS_MOBILE) {
            iconRightRef.current.style.right =
              div.x + div.width - mouseX + 'px';
            timeRightRef.current.style.right =
              div.x + div.width - mouseX + 'px';
          } else {
            // PC 버전은 사이드바 위치만큼 좌표가 조정됨, 그리고 마우스의 안정적인 이동을 위해 일부 값이 보정되어있음
            // 보정치가 없으면, 드래그 도중 마우스가 사각형 바깥을 벗어날 수 있음
            iconRightRef.current.style.right =
              div.x + div.width - mouseX - 12 + 'px';
            timeRightRef.current.style.right =
              div.x + div.width - mouseX - 12 + 'px';
          }
        }
      };

      const onTouchStart = (event: TouchEvent) => {
        mouseDownProcess(event.touches[0].clientX, event.touches[0].clientY);
      };

      const onTouchMove = (event: TouchEvent) => {
        mouseMoveProcess(event.touches[0].clientX, event.touches[0].clientY);
      };

      /** 마우스를 뗄 때 동작하는 함수, 이 함수는 event 변수를 사용하지 않기 때문에 event 전달 없이 직접 사용됨 */
      const mouseUpProcess = () => {
        if (
          iconLeftRef.current == null ||
          iconRightRef.current == null ||
          divRef.current == null
        )
          return;
        if (timeLeftRef.current == null || timeRightRef.current == null) return;

        const div = divRef.current.getBoundingClientRect();
        const leftDiv = iconLeftRef.current.getBoundingClientRect();
        const rightDiv = iconRightRef.current.getBoundingClientRect();

        // 두 개의 값이 반전되어있는지 확인하고, 반전되어있으면 재조정
        if (mouse.left > mouse.right) {
          mouse.left = mouse.right - 0.1;
        }

        // 터치를 끝냈을 때, 사각형의 위치가 겹치지 않도록 위치를 조정함
        // 이동하는 쪽이 겹치지 않도록 처리
        // pc버전에서는 div.x 위치만큼의 보정값을 넣어야 함 (모바일은 div.x값이 0이라 모바일 구분을 할 필요는 없음)
        if (mouse.isLeft && leftDiv.right >= rightDiv.left) {
          iconLeftRef.current.style.left =
            rightDiv.left - leftDiv.width - div.x + 'px';
          timeLeftRef.current.style.left =
            rightDiv.left - leftDiv.width - div.x + 'px';
        } else if (mouse.isRight && leftDiv.right >= rightDiv.left) {
          // 오른쪽은 rightPX 기준으로 계산해야해서 값을 반전시켜야 함
          iconRightRef.current.style.right =
            div.x + div.width - (leftDiv.right + rightDiv.width) + 'px';
          timeRightRef.current.style.right =
            div.x + div.width - (leftDiv.right + rightDiv.width) + 'px';
        }

        // 값 범위 제한 및 사각형 강제 이동
        if (mouse.left < 0) {
          mouse.left = 0;
          // 이 css의 값은 waveArea를 기준으로 위치가 정해지므로, 여기서는 0px로 설정해야 함
          iconLeftRef.current.style.left = '0px';
          timeLeftRef.current.style.left = '0px';
        }
        if (mouse.right > 100) {
          mouse.right = 100;
          // 이 css의 값은 waveArea를 기준으로 위치가 정해지므로, 여기서는 0px로 설정해야 함, 오른쪽에서 떨어진 만큼이므로 0px임
          iconRightRef.current.style.right = '0px';
          timeRightRef.current.style.right = '0px';
        }

        // 마우스 다운 해제
        setMouse({
          left: mouse.left,
          right: mouse.right,
          isLeft: false,
          isRight: false,
        });

        // 값 최종 처리
        rangeStartRef.current =
          (previewTarget.current.duration / 100) * mouse.left;
        const rangeRight = (previewTarget.current.duration / 100) * mouse.right;
        rangeDurationRef.current = rangeRight - rangeStartRef.current;
      };

      // 이 영역에는 파형 표시, 파형을 클릭 시 음악 재생 구간 변경, 사각형을 조정하여 음악 컷팅? 기능이 있습니다.
      return (
        <div
          ref={divRef}
          onTouchStart={e => onTouchStart(e)}
          onTouchMove={e => onTouchMove(e)}
          onTouchEnd={e => mouseUpProcess()}
          onMouseDown={e => onMouseDown(e)}
          onMouseMove={e => onMouseMove(e)}
          onMouseUp={e => mouseUpProcess()}
          style={{
            position: 'relative',
            width: '100%',
            left: '0%',
            height: '100%',
          }}
        >
          <canvas
            ref={canvasRef}
            width={660}
            height={135}
            style={{
              width: '80%',
              height: '60%',
              position: 'absolute',
              left: '10%',
              top: '-20%',
            }}
            onClick={e => canvasClick(e)}
          />
          <img
            src={IconLeft}
            alt=""
            ref={iconLeftRef}
            style={{
              left: '0px',
              width: '10%',
              position: 'absolute',
              backgroundColor: 'white',
            }}
          />
          <img
            src={IconRight}
            alt=""
            ref={iconRightRef}
            style={{
              right: '0px',
              width: '10%',
              position: 'absolute',
              backgroundColor: 'white',
            }}
          />
          <MusicPreviewSmallText
            ref={timeLeftRef}
            style={{
              color: '#6A6CFC',
              position: 'absolute',
              top: '40px',
              left: '0px',
            }}
          >
            {durationToMinuteSecond(
              Math.floor((previewAudio.current.duration * mouse.left) / 100),
            )}
          </MusicPreviewSmallText>
          <MusicPreviewSmallText
            ref={timeRightRef}
            style={{
              color: '#6A6CFC',
              position: 'absolute',
              top: '40px',
              right: '0px',
            }}
          >
            {durationToMinuteSecond(
              Math.floor((previewAudio.current.duration * mouse.right) / 100),
            )}
          </MusicPreviewSmallText>
        </div>
      );
    }

    /** 플레이 버튼이 있는 영역 */
    function PlayArea() {
      const [isPlay, setIsPlay] = useState(false);

      const musicPlay = () => {
        setIsPlay(true);
        previewAudio.current.play();
      };

      const musicPause = () => {
        setIsPlay(false);
        previewAudio.current.pause();
      };

      function PlayBlue() {
        return (
          <img
            src={IconPlayBlue}
            style={{ padding: '20px' }}
            onClick={() => musicPlay()}
          />
        );
      }

      function PauseBlue() {
        return (
          <img
            src={IconPauseBlue}
            style={{ padding: '20px' }}
            onClick={() => musicPause()}
          />
        );
      }

      return <div> {isPlay ? <PauseBlue /> : <PlayBlue />} </div>;
    }

    /** iconPlus를 클릭했을 때 음악을 추가하는 함수 */
    const iconPlusOnclick = () => {
      console.log(rangeStartRef.current, rangeDurationRef.current);
      onClickItemOptions(
        previewTarget.current.title,
        previewTarget.current.url,
        previewTarget.current.duration,
        rangeStartRef.current,
        rangeDurationRef.current,
      );
    };

    /** 캔버스를 클릭한 경우 사용되는 함수 */
    const canvasClick = (e: MouseEvent) => {
      if (canvasRef.current == null) return;

      // 캔버스가 눌린 마우스 좌표를 가져와서,
      const canvasRect = canvasRef.current.getBoundingClientRect();
      let mouseX = e.clientX - canvasRect.x;
      let percent = (mouseX / canvasRect.width) * 100; // 좌표의 퍼센트 값으로 변환하고
      let time = (previewAudio.current.duration / 100) * percent; // 그 값을 다시 시간으로 변환
      if (isNaN(time)) return; // 잘못된 값인경우 무효

      previewAudio.current.currentTime = time; // 변환된 시간을 현재 재생 시점으로 처리
    };

    return (
      <MusicPreviewDiv>
        <div style={{ marginLeft: 'auto', padding: '10px' }}>
          <img
            src={IconClose}
            alt=""
            onClick={() => setShowMusicPreview(false)}
          />
        </div>
        <div style={{ borderBottom: '1px solid #4D4D4D', width: '100%' }}></div>
        <div style={{ display: 'flex', width: '100%', padding: '10px' }}>
          <PlayArea></PlayArea>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TrackText>{previewTarget.current.title}</TrackText>
            <TrackTextSub>{previewTarget.current.title}</TrackTextSub>
            <TrackTextSub>
              {durationToMinuteSecond(previewTarget.current.duration)}
            </TrackTextSub>
          </div>
          {timelineStore.nameSerach(previewTarget.current.title) ? (
            <img
              src={IconPlusBlue}
              alt=""
              style={{ marginLeft: 'auto', padding: '10px' }}
              onClick={() => iconPlusOnclick()}
            />
          ) : (
            <img
              src={IconPlus}
              alt=""
              style={{ marginLeft: 'auto', padding: '10px' }}
              onClick={() => iconPlusOnclick()}
            />
          )}
        </div>
        <div style={{ width: '100%', height: '70%' }}>
          <PlayTime />
          <WaveArea />
        </div>
      </MusicPreviewDiv>
    );
  };

  /**
   * 음악을 미리듣기 할 대상을 선택합니다.
   * 이 함수를 실행시키면, 무조건 음악 미리듣기 화면이 표시됩니다.
   */
  const setMusicPreviewTarget = (
    title: string,
    url: string,
    duration: number,
    thumbnail: string,
  ) => {
    setRequestForceUpdatePreview(f => f + 1);
    setShowMusicPreview(true);

    previewTarget.current.title = title;
    previewTarget.current.url = url;
    previewTarget.current.duration = duration;
    previewTarget.current.thumbnail = thumbnail;

    // 음악 미리듣기 url을 미리듣기 대상으로 바로 지정함
    previewAudio.current.src = previewTarget.current.url;
  };

  return (
    <Container expand={expand}>
      <Search
        value={q}
        onChange={newQ => setParams({ q: newQ })}
        placeHolder={lang.MENU_MUSIC_SEARCH_PLACEHOLDER}
      />
      <div style={{ display: 'flex' }}>
        {selectGenre.current !== NOT_SELECT && (
          <TrackButtonSelectList># {selectGenre.current}</TrackButtonSelectList>
        )}
        {selectMoods.current !== NOT_SELECT && (
          <TrackButtonSelectList># {selectMoods.current}</TrackButtonSelectList>
        )}
      </div>
      <TrackButtonArea />

      {/* 드래그 시 이미지를 보이지 않게 하기 위한 이미지 태그 (임의 영역에 강제로 배치시킴, 보여지지 않음.) */}
      <img
        width={1}
        height={1}
        ref={blankImage}
        style={{ position: 'fixed', visibility: 'hidden' }}
      />

      <InfiniteScroll
        pageStart={0}
        loadMore={() => loadMore(q, page + 1)}
        hasMore={hasNextPage}
        loader={
          <LoadingContainer key={7}>
            <CircularProgress />
          </LoadingContainer>
        }
        useWindow={false}
        style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}
      >
        {tracks.map((x: ITrack, index) => (
          <TrackContainer key={x.title + index}>
            <img
              src={IconPlay}
              alt=""
              onClick={() =>
                setMusicPreviewTarget(x.title, x.url, x.duration, x.thumbnail)
              }
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '10px',
              }}
            >
              <TrackText>{x.title}</TrackText>
              <TrackTextSub>{x.title}</TrackTextSub>
              <TrackTextSub>{durationToMinuteSecond(x.duration)}</TrackTextSub>
            </div>
            {timelineStore.nameSerach(x.title) ? (
              <img
                src={IconPlusBlue}
                style={{ marginLeft: 'auto' }}
                onClick={() => onClickItem(x)}
              ></img>
            ) : (
              <img
                src={IconPlus}
                style={{ marginLeft: 'auto' }}
                onClick={() => onClickItem(x)}
              ></img>
            )}
          </TrackContainer>
        ))}
      </InfiniteScroll>

      {showMusicPreview && <MusicPreviewArea />}
    </Container>
  );
});

// 참고: effectMenu는 grid 기반이고,
// shutterstockMenu는 flex 기반이라, 서로의 padding이 다릅니다.
// 서로의 레이아웃 구조가 달라 배치를 완벽하게 통일할 수 없습니다.
const Container = styled.div<any>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  background-color: #191919;
  gap: 6px;

  padding: 12px;

  /* 오버플로우 속성은, 마우스 드래그 시 화면이 강제로 오른쪽으로 이동되는 문제가 있어 x축은 hidden으로 처리함 */
  overflow-x: hidden;
  overflow-y: auto;
`;

const TrackContainer = styled.div`
  position: relative;
  display: flex;

  width: 100%;
  height: 60px;

  align-items: center;

  ${NonSelectable}
`;

const TrackText = styled.div`
  color: #fff;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;
`;

const TrackTextSub = styled.div`
  color: #a0a3a6;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.28px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;

  padding: 20px 20px;
`;

const TrackButtonDiv = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  border-radius: 10px 10px 0px 0px;
  background: #212121;
`;

const TrackButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  height: 40px;

  width: 34%;
  background: #212121;
  transition: 0.4s;
  color: #777;
  padding: 4px;

  &:hover {
    background-color: #424242;
  }
`;

const TrackButtonHighlight = styled.div`
  height: 4px;
  align-self: stretch;
  background: #6a6cfc;
`;

const TrackButtonSelectList = styled.div`
  display: inline-flex;
  padding: 5px 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 30px;
  border: 2px solid #6a6cfc;
  background: #03070e;

  color: #fff;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.28px;
`;

const TrackButtonMenu = styled.div`
  transition: 0.2s;
  position: relative;
  background-color: #212121;
  height: 50vh; /* %로 적용하면, 전체적인 메뉴 영역 크기만큼 적용되어 의미가 없음 */
  color: #ffffff;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  opacity: 1;
  border-radius: 0px 0px 10px 10px;
  background: #212121;
`;

const TrackButtonMenuList = styled.div`
  display: flex;
  padding: 14px 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: #212121;

  color: #fff;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;

  &:hover {
    background-color: #444444;
    color: white;
  }
`;

const TrackButtonMenuBpmSlideNumber = styled.span`
  color: #fff;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: -0.24px;
`;

const TrackButtonMenuBpmNumberDiv = styled.div`
  display: flex;
  width: 30%;
  padding: 19px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #2e2e2e;

  color: #fff;
  text-align: center;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
`;

const TrackButtonMenuBpmButtonGroup = styled.div`
  display: inline-flex;
  padding: 20px 23px 20px 10px;
  align-items: center;
  gap: 40%;
  border-top: 1px solid #4d4d4d;
  background: #212121;

  color: #777;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
`;

const TrackButtonMenuBpmButtonGroupSubmit = styled.div`
  display: flex;
  height: 40px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #2e2e2e;
  cursor: pointer;
`;

/** 사이드바 영역의 PC 너비 (사이드바가 고정px라 이 값을 계산해야 할 필요가 있음) */
const sidebarAreaPCWidth =
  parseInt(constLayout.PC_SIDEBAR_EXPAND_WIDTH) -
  parseInt(constLayout.PC_SIDEBAR_WIDTH);

/** 사이드 바 내부의 시작 영역 */
const sidebarInnerStart = parseInt(constLayout.PC_SIDEBAR_WIDTH);

const MusicPreviewDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #2c2c2c;
  position: fixed;
  top: 70%;
  width: ${IS_MOBILE ? '100%' : sidebarAreaPCWidth + 'px'};
  left: ${IS_MOBILE ? '0%' : sidebarInnerStart + 'px'};
  display: flex;
  height: 30%;
  align-items: center;
  flex-shrink: 0;
`;

const MusicPreviewSmallText = styled.span`
  color: #d1d1d1;
  background-color: #333333;

  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.2px;
`;
