import { isNil, uniqueId } from 'lodash-es';
import { DateTime } from 'luxon';
import { observable } from 'mobx';
import { EffectTypes, IBackgroundSound, IEffect } from 'model';
import { useStores } from 'store';

import { uiStore } from './ui';
import { videoStore } from './video';

export interface IAppliedEffect {
  id: string;

  effect: IEffect;

  group: number;
  start: number;
  end: number;
}

export interface ITimelineStore {
  effects: IAppliedEffect[];
  backgroundSounds: IBackgroundSound[];
  hoveredEffect: IEffect | null;

  _scheduledUpdate: boolean;

  getCurrentEffect(): EffectTypes | null;

  save(): object;
  load(json: any): void;

  addEffect(effect: Omit<IAppliedEffect, 'id'>): void;
  removeEffect(id: string): void;
  clearEffects(): void;

  /** 이름 검색: 만약, 해당 이름을 가진 배경음악이 1개 이상 존재한다면, true, 아닐경우 false */
  nameSerach(name: string): boolean;

  addBackgroundSound(sound: Omit<IBackgroundSound, 'id'>): void;
  removeBackgroundSound(id: string): void;
  _updateBackgroundSound(): Promise<void>;

  reorder(): void;
}
export const timelineStore = observable({
  effects: [],
  backgroundSounds: [],
  hoveredEffect: null,

  _scheduledUpdate: false,

  getCurrentEffect() {
    const { videoStore } = useStores();
    const time = videoStore.time;

    for (const e of this.effects) {
      if (e.start <= time && e.end > time) return e;
    }
    return null;
  },

  save() {
    return {
      backgroundSounds: this.backgroundSounds,
      effects: this.effects,
    };
  },
  load(json: any) {
    if (isNil(json.backgroundSounds) || isNil(json.effects)) {
      return;
    }

    this.backgroundSounds = json.backgroundSounds;
    this.effects = json.effects;

    // 참고: 만약, 데이터가 제대로 존재하지 않는 경우가 있다면, 기본 값을 추가
    // 정확한 값을 여기서 바로 로드할 수도 있으나, 이러면 로드 등으로 시간이 추가로 소요되어 비추천
    // 참고로 rangeStart, rangeEnd, start, end는 timeline에서 추가적인 보정을 합니다. (이 값을 기본으로 사용하면 에러가 날 수 있음)
    for (let i = 0; i < this.backgroundSounds.length; i++) {
      if (this.backgroundSounds[i].url == null) continue // 음악 url가 없으면 절대로 로드할 수 없음
      if (this.backgroundSounds[i].channel == null) this.backgroundSounds[i].channel = 0
      if (this.backgroundSounds[i].end == null) this.backgroundSounds[i].end = 60
      if (this.backgroundSounds[i].fadeIn == null) this.backgroundSounds[i].fadeIn = 3
      if (this.backgroundSounds[i].fadeOut == null) this.backgroundSounds[i].fadeOut = 3
      if (this.backgroundSounds[i].id == null) this.backgroundSounds[i].id = i + ''
      if (this.backgroundSounds[i].musicDuration == null) this.backgroundSounds[i].musicDuration = 60
      if (this.backgroundSounds[i].name == null) this.backgroundSounds[i].name = 'undefined data'
      if (this.backgroundSounds[i].rangeDuration == null) this.backgroundSounds[i].rangeDuration = 60
      if (this.backgroundSounds[i].rangeEnd == null) this.backgroundSounds[i].rangeEnd = 60
      if (this.backgroundSounds[i].rangeEndback == null) this.backgroundSounds[i].rangeEndback = 0
      if (this.backgroundSounds[i].rangeStart == null) this.backgroundSounds[i].rangeStart = 0
      if (this.backgroundSounds[i].start == null) this.backgroundSounds[i].start = 0
      if (this.backgroundSounds[i].volume == null) this.backgroundSounds[i].volume = 0.25
    }

    // url값이 있는 데이터만 다시 적용함
    this.backgroundSounds = this.backgroundSounds.filter(value => value.url !== '')

    // 값이 누락되어 있는지 살펴보고, 누락이 없을 때 이펙트로 적용하도록 변경
    this.effects = this.effects.filter((value) => {
      if (value.effect != null && value.end != null && value.group != null 
        && value.id != null && value.start != null
      ) {
        return true
      } else {
        return false
      }
    })

    // 클라우드에서 음악과 이펙트를 불러오면 해당 내용을 갱신
    this._updateBackgroundSound();
  },

  addEffect(effect: Omit<IAppliedEffect, 'id'>) {
    this.effects = []; // 이펙트 전부 삭제
    this.effects = [...this.effects, { id: uniqueId(), ...effect }];
    this._updateBackgroundSound();
  },
  removeEffect(id: string) {
    this.effects = this.effects.filter(x => x.id !== id);
    this.reorder();
    this._updateBackgroundSound();
  },
  async clearEffects() {
    this.effects = [];

    await this._updateBackgroundSound();
  },

  nameSerach(name: string) {
    const find = this.backgroundSounds.find((value, index, obj) => {
      if (value.name === name) {
        return true
      } else {
        return false
      }
    })

    return find ? true : false
  },

  addBackgroundSound(sound: Omit<IBackgroundSound, 'id'>) {
    this.backgroundSounds.push({ id: uniqueId(), ...sound });
    this._updateBackgroundSound();
  },
  removeBackgroundSound(id: string) {
    this.backgroundSounds = this.backgroundSounds.filter(x => x.id !== id);
    this._updateBackgroundSound();
  },

  async _updateBackgroundSound() {
    if (this._scheduledUpdate) {
      return false;
    }

    this._scheduledUpdate = true;

    queueMicrotask(async () => {
      this._scheduledUpdate = false;

      try {
        uiStore.showLoading();
        await videoStore._updateMixer(this.backgroundSounds, this.effects);
      } finally {
        uiStore.hideLoading();
      }
    });
  },

  reorder() {
    let offset = 0;
    for (const e of this.effects) {
      const len = e.end - e.start;
      e.start = offset;
      e.end = offset + len;
      offset = e.end;
    }
  },
} as ITimelineStore);
