/** 언어 기본값 설정, 기본값은 영어 */
class LanguageDefault {
  // 헤더 메뉴
  /** 헤더 공유 */ HEADER_SHARE = 'share';
  /** 헤더 내보내기 */ HEADER_EXPORT = 'export';

  // 클라우드 메뉴
  /** 클라우드 제목: 내 프로젝트 */ CLOUD_MY_PROJECT = 'My Project';
  /** 클라우드 메뉴: 검색 메뉴의 placeHolder */ CLOUD_SEARCH_PLACE_HOLDER =
    'Project Name Search';
  /** 클라우드 메뉴: 섬네일 */ CLOUD_THUMBNAIL = 'Thumbnail';
  /** 클라우드 메뉴: 프로젝트 이름 */ CLOUD_PROJECT_NAME = 'Project Name';
  /** 클라우드 메뉴: 동영상 크기 */ CLOUD_VIDEO_SIZE = 'Size';
  /** 클라우드 메뉴: 마지막 수정 시간 */ CLOUD_LAST_MODIFIED_TIME =
    'Last Modified Time';
  /** 클라우드 메뉴: 마지막 수정 날짜 */ CLOUD_LAST_MODIFIED_DATE =
    'Last Modified Date';
  /** 클라우드 메뉴: 링크 복사 */ CLOUD_COPY_LINK = 'Copy Link';
  /** 클라우드 메뉴: 다운로드 */ CLOUD_DOWNLOAD = 'Download';
  /** 클라우드 메뉴: 삭제 */ CLOUD_DELETE = 'Delete';
  /** 클라우드 메뉴: 취소 */ CLOUD_CANCLE = 'Cancle';
  /** 클라우드: 링크 복사 완료 메세지 */ CLOUD_COPY_LINK_MESSAGE =
    'Link copied successfully.';
  /** 클라우드: 파일 삭제시 나오는 화면의 제목 */ CLOUD_DELETE_TITLE =
    'Do you want to delete the file?';
  /** 클라우드: 파일 삭제시 나오는 화면의 내용 */ CLOUD_DELETE_CONTENT =
    'This action cannot be undone. \n Please proceed with caution.';

  // 사이드바 메뉴 리스트
  /** 사이드바 마스터링 */ SIDEBAR_MASTERING = 'Mastering';
  /** 사이드바 음악 */ SIDEBAR_MUSIC = 'Music';
  /** 사이드바 비디오 */ SIDEBAR_VIDEO = 'Video';
  /** 사이드바 클라우드 */ SIDEBAR_CLOUD = 'Cloud';

  // 사이드바 음악 설정
  /** 사이드바 음악 메뉴: 장르 */ MENU_MUSIC_GENRE = 'Genre';
  /** 사이드바 음악 메뉴: 무드 */ MENU_MUSIC_MOODS = 'Moods';
  /** 사이드바 음악 메뉴: Bpm */ MENU_MUSIC_BPM = 'BPM';
  /** Bpm 설정 */ MENU_MUSIC_BPM_TEXT = 'Set BPM';
  /** Bpm 설정의 모두 지우기 버튼의 텍스트 */ MENU_MUSIC_BPM_ALL_DELETE =
    'Clear All';
  /** Bpm 설정을 적용하는 버튼의 텍스트 */ MENU_MUSIC_BPM_ACCEPT = 'Apply';
  /** 음악 메뉴에 있는 검색 영역의 placeHolder */ MENU_MUSIC_SEARCH_PLACEHOLDER =
    'Search Music';

  // 사이드바 마스터링 설정
  /** 사이드바 마스터링 메뉴 실행시 표시되는 텍스트 */ MENU_MASTERING_TITLE =
    'Mastering';
  /** 사이드바 마스터링 메뉴 페이지 */ MENU_MASTERING_PAGE = 'Page';
  /** 사이드바 마스터링 검색 영역의 placeHolder */ MENU_MASTERING_SEARCH_PLACEHOLDER =
    'Search Mastering Mood (#Holiday #Morning in the field)';

  // 사이드바 비디오 설정
  /** 사이드바 비디오 메뉴에서 비디오 볼륨 */ MENU_VIDEO_VOLUME =
    'Video Volume';

  /** 플레이그라운드, 비디오 임포트 */ PLAYGROUND_PLEASE_IMPORT_VIDEO =
    'Please import video.';

  // 음악 상세 설정
  /** 음악 설정에서 비디오 내의 음악을 재생할 위치 */ MUSICEDIT_POSITION =
    'Position';
  /** 음악 설정에서 음악의 재생 범위 */ MUSICEIDT_PLAYBACK_RANGE =
    'Playback Range';
  /** 음악 설정에서 볼륨 */ MUSICEDIT_VOLUME = 'Volume';
  /** 음악 설정에서 페이드 인 */ MUSICEDIT_FADEIN = 'Fade In';
  /** 음악 설정에서 페이드 아웃 */ MUSICEDIT_FADEOUT = 'Fade Out';
  /** 음악 설정에서 음악 이름 */ MUSICEDIT_NAME = 'Name';
  /** 음악 설정에서 닫기 */ MUSICEDIT_CLOSE = 'Close';
  /** 음악 설정에서 삭제 */ MUSICEDIT_DELETE = 'Delete';

  // 내 프로젝트 메뉴
  /** 내 프로젝트 내의 내 프로젝트 텍스트 */ MYPROJECT_MYPROJECT = 'My Project';
  /** 내 프로젝트가 없을 때 출력되는 '서라운디오, 원클릭 동영상 마스터링' */ MYPROJECT_SURROUNDIO =
    'Surroundio, One-Click Video Mastering';
  /** 내 프로젝트가 없을 때 출력되는 '프로젝트 시작하기' */ MYPROJECT_START_PROJECT =
    '+ Start Project';

  // 내 사이드 메뉴
  /** 내 사이드 메뉴의 구매하기 */ MYSIDE_PURCHASE = 'Purchase Subscription';
  /** 내 사이드 메뉴의 로그아웃 */ MYSIDE_LOGIN = 'Login';
  /** 내 사이드 메뉴의 로그아웃 */ MYSIDE_LOGOUT = 'Logout';
}

// 영어를 기본 언어로 정의함
class LangEn extends LanguageDefault {}

// 이건 한글, 만약 속성을 지정하지 않으면 영어가 출력됨
class LangKr extends LanguageDefault {
  SIDEBAR_MASTERING = '마스터링';
  SIDEBAR_VIDEO = '오디오';
  SIDEBAR_MUSIC = '음악';
  MENU_MASTERING_PAGE = '페이지';
  MENU_MASTERING_TITLE = '마스터링';
  MENU_MUSIC_BPM_ACCEPT = '적용';
  HEADER_EXPORT = '내보내기';
  HEADER_SHARE = '공유하기';
  MENU_MUSIC_BPM_ALL_DELETE = '모두 지우기';
  MENU_MUSIC_BPM_TEXT = 'BPM 설정하기';
  MENU_MUSIC_SEARCH_PLACEHOLDER =
    '마스터링 무드 검색 (#Holiday #Morning in the field)';

  MUSICEDIT_POSITION = '위치';
  MUSICEIDT_PLAYBACK_RANGE = '재생 범위';
  MUSICEDIT_VOLUME = '볼륨';
  MUSICEDIT_FADEIN = '페이드 인';
  MUSICEDIT_FADEOUT = '페이드 아웃';
  MUSICEDIT_CLOSE = '닫기';
  MUSICEDIT_DELETE = '삭제';

  MYPROJECT_MYPROJECT = '내 프로젝트';
  MYPROJECT_SURROUNDIO = '서라운디오, 원클릭 동영상 마스터링';
  MYPROJECT_START_PROJECT = '+ 프로젝트 시작하기';

  MYSIDE_PURCHASE = '이용권 구매';
  MYSIDE_LOGOUT = '로그아웃';
}

// 모든 음악 클래스 선언
const langEn = new LangEn();
const langKr = new LangKr();

/**
 * 언어 데이터를 담당하는 클래스를 가져옵니다.
 *
 * 현재는 en, kr정도만 허용, 기본값은 en(영어)
 */
export const getLanguageData = (lang: string = '') => {
  if (lang === 'en' || lang === '') {
    return langEn;
  } else if (lang === 'kr') {
    return langKr;
  } else {
    console.error('unsupported language. auto return english.');
    return langEn;
  }
};
