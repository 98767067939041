import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { uiMobileBgmMenu, uiMobileEffectMenu } from 'store/ui';
import { useStores } from '../../store';
import { Timeline } from '../timeline/Timeline';

export const EffectMenu = (setting: any) => {
  const { uiTimeLine } = useStores();

  useEffect(() => {}, [setting.block]);

  const menuOnClickHandle = (type: number) => {
    uiMobileEffectMenu.hideLoading();

    if (uiTimeLine.loading == false) {
      uiTimeLine.showLoading(type);
      if (type == 1) {
        uiMobileBgmMenu.showLoading();
      }
    } else {
      uiTimeLine.hideLoading();
    }
  };

  return null;

  return (
    <EffectMenuContainer isBlock={setting.block}>
      <Menu onClick={() => menuOnClickHandle(0)}>
        <span>Effect</span>
      </Menu>

      <Menu onClick={() => menuOnClickHandle(1)}>
        <span>Music</span>
      </Menu>
    </EffectMenuContainer>
  );
};

const EffectMenuContainer = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 60px;
  background-color: #091425;

  ${({ isBlock }) =>
    isBlock == true
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}

  div {
    &:first-child {
      border-right: thin solid white;
    }
  }
`;

const Menu = styled.div`
  width: 48%;
  height: 70%;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-weight: bold;
    font-size: 1.2rem;
    color: white;
  }
`;
