import React from 'react';
import { isMobile } from 'util/mobile';

import { SidebarDesktop } from './Sidebar.Desktop';
import { SidebarMobile } from './Sidebar.Mobile';

export const Sidebar = () => {
  const mobile = isMobile();

  if (mobile) {
    return <SidebarMobile />;
  }
  return <SidebarDesktop />;
};
