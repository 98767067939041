//@ts-check

/** 이 파일에서 사용하는 frequency 배열의 인덱스를 어느값으로만 참조할 것인지에 대한 배열 */
function getHzValueIndexArray () {
  let count = 0;
  let indexArray = [];
  for (let i = 0; i < 256; i++) {
    indexArray.push(count)

    if (i >= 0 && i < 128) count += 1 // 0 ~ 3000hz (전체 영역의 50%)
    else if (i >= 128 && i < 192) count += 2 // 3000 ~ 6000hz (전체 영역의 25%)
    else if (i >= 192 && i < 224) count += 8 // 6000 ~ 12000hz (전체 영역의 12.5%)
    else if (i >= 224 && i < 256) count += 16 // 12000 ~ 24000hz (전체 영역의 12.5%)
  }

  return indexArray
}

const hzValueIndexArray = getHzValueIndexArray()

/**
 * 주파수 빈도에 대한 그래프를 그리는 함수
 *
 * @param {HTMLCanvasElement} canvas
 * @param {AnalyserNode} analyserNode 
 * @param {{color?: string[], dBLine?: boolean, hzLine?: boolean, clear?: boolean}?} option 
 * 옵션,  
 * color: 색 지정, 여러 색을 사용할경우, 그라디언트 형태로 지정됨  
 * dbLine: 데시벨 값 표시,  
 * hzLine: 주파수 값 표시,  
 * clear: 
 */
export let drawFrequencyGraph = (canvas, analyserNode, option = {dBLine: false, hzLine: false, color: undefined, clear: true}) => {
  /** 파형 정밀도 */
  const FFTSIZE = 2048

  /** 시간 변화에 따른 데이터 변경 속도를 얼마나 매끄럽게 할 건지에 대한 값, 높을수록 매끄러움 */
  const SMOOTHING_TIME_CONSTANT = 0.75

  analyserNode.fftSize = FFTSIZE
  analyserNode.smoothingTimeConstant = SMOOTHING_TIME_CONSTANT

  /** 
   * 주파수 데이터가 있는 배열, float형태로 가져오므로, Float32Array로 생성해야 함 
   * 
   * 이 값들은 평균적으로 -20 ~ -180 사이에 분포해있고, -100이하의 값이면 해당 부분은 거의 들리지 않음.
   */
  let frequencyArray = new Float32Array(analyserNode.frequencyBinCount)

  // 주파수 데이터 가져오기
  analyserNode.getFloatFrequencyData(frequencyArray)

  let context = canvas.getContext('2d')
  if (context == null) return

  // 화면 전체 지우기, 옵션이 없는 경우, 옵션이 있지만 clear속성이 없는 경우
  // 옵션이 있고, clear값이 true로 지정된 경우만 화면을 지움
  const isClear = (option == null) || (option != null && option.clear == null)
   || (option != null && option.clear === true)

  if (isClear) {
    context.clearRect(0, 0, canvas.width, canvas.height)
  }

  // 그라디언트 생성, 색은 세로형태로
  let gradient = context.createLinearGradient(0, 0, 0, canvas.height)
  if (option && (option))

  if (option && option.color) {
    if (option.color.length === 0) {
      gradient.addColorStop(0, '#444444')
      gradient.addColorStop(1, '#CCCCCC')
    } else if (option.color.length === 1) {
      gradient.addColorStop(0, option.color[0])
      gradient.addColorStop(1, option.color[0])
    } else if (option.color.length >= 2) {
      for (let i = 0; i < option.color.length; i++) {
        if (i === 0) { // 0 번호는 무조건 0
          gradient.addColorStop(0, option.color[0])
        } else if (i === option.color.length - 1) { // 마지막 번호는 무조건 1
          gradient.addColorStop(1, option.color[option.color.length - 1])
        } else { // 중간 값
          // 나누기 값 (맨 끝은 무조건 1이기 때문에, 중간값을 구하려면 맨 마지막에서 1을 빼서 구해야 함)
          const divide = option.color.length - 1
          gradient.addColorStop(i / divide, option.color[i])
        }
      }
    }
  } else {
    // 각각 빨강, 주황, 노랑, 연두, 초록입니다. (기본색 보다 밝은색임)
    gradient.addColorStop(0, '#ff9d9d')
    gradient.addColorStop(0.4, '#ffbe7d')
    gradient.addColorStop(0.6, '#fff399')
    gradient.addColorStop(0.8, '#dcff70')
    gradient.addColorStop(1, '#a9ff89')
  }

  // 그라디언트 설정 및 라인 길이 설정
  context.fillStyle = gradient
  context.lineWidth = 1
  context.strokeStyle = 'black'

  // 사각형의 개수와 길이 설정
  const rectCount = 256
  let rectWidth = Math.floor(canvas.width / rectCount)
  if (rectWidth < 1) rectWidth = 1
  
  /** 최대 데시벨, 이 값 이상은 처리하지 않음 */ const MAXDB = -10
  /** 최소 데시벨, 이 값 이하는 처리하지 않음 */ const MINDB = -100
  /** 데시벨 범위에 해당하는 구간 값 */ const DBRANGE = MINDB + Math.abs(MAXDB) // -100 + 10 = -90

  for (let i = 0; i < rectCount; i++) {
    const currentIndex = hzValueIndexArray[i]
    const currentValue = frequencyArray[currentIndex]

    // infinity 값 무시
    if (currentValue === -Infinity || currentValue === Infinity) {
      continue
    }

    // 최대 데시벨 이상 감지할 수 없으므로, 그래프를 그릴때는 그만큼 보정치를 넣음
    // 최소 데시벨 이하인경우, 출력 무시
    let outputValue = currentValue + Math.abs(MAXDB)
    if (outputValue > 0) outputValue = 0
    if (outputValue < MINDB) continue

    // h값은 캔버스 전체 크기를 기준으로 일정 영역을 %로 환산해서 처리
    let x = i * rectWidth
    let h = (Math.abs(DBRANGE) - Math.abs(outputValue)) * (canvas.height / Math.abs(DBRANGE))

    // 밑에서 위로 올라가는 형태로 출력시켜야 하므로, h값은 반전되어있음.
    // h값이 1미만인경우, 출력하지 않음
    if (h > 1) {
      context.fillRect(x, canvas.height, rectWidth, -h)
      // context.strokeRect(x, canvas.height, rectWidth, -h)
    }
  }

  // dB 간격 표시
  if (option && option.dBLine) {
    context.fillStyle = 'red'
    for (let i = 1; i < 10; i++) {
      let text = '-' + (i * 10)
      context.fillText(text, 0, (canvas.height / 9 * i))
    }
  }

  // hz 간격 표시
  if (option && option.hzLine) {
    context.fillStyle = 'blue'
    /** 이 함수에서 사용하는 주파수 기준값 */
    const hzArray = [20, 375, 750, 1125, 1500, 1875, 2250, 2625, 3000, 3750, 4500, 5250, 6000, 9000, 12000, 18000]
    for (let i = 0; i < hzArray.length; i++) {
      let text = '' + hzArray[i] + 'hz'
      let y = i % 2 === 0 ? canvas.height - 16 : canvas.height - 32
      context.fillRect((canvas.width / hzArray.length) * i, 0, 2, canvas.height)
      context.fillText(text, (canvas.width / hzArray.length) * i, y)
    }
  }
}

/**
 * 시간 값에 대한 파형을 그리는 함수
 *
 * @param {HTMLCanvasElement} canvas
 * @param {AnalyserNode} analyserNode 
 * @param {{color?: string[], volumeLine?: boolean, clear?: boolean}?} option 
 * 옵션,  
 * color: 색 지정, 여러 색을 사용할경우, 그라디언트 형태로 지정됨 
 * volumeLine: 볼륨 값을 구간으로 표시 범위는 -1 ~ 1까지
 * clear: 캔버스를 지우는 여부 (false로 지정하면 지우지 않음), 해당 속성이 없거나 옵션이 지정되지 않으면 true로 처리
 */
export let drawTimeDomainGraph = (canvas, analyserNode, option = {color: undefined, volumeLine: false, clear: true}) => {
  /** 파형 정밀도 */
  const FFTSIZE = 2048

  /** 시간 변화에 따른 데이터 변경 속도를 얼마나 매끄럽게 할 건지에 대한 값, 높을수록 매끄러움 */
  const SMOOTHING_TIME_CONSTANT = 0.2

  analyserNode.fftSize = FFTSIZE
  analyserNode.smoothingTimeConstant = SMOOTHING_TIME_CONSTANT

  /** 
   * 시간 도메인 데이터의 배열, float형태로 가져오므로, Float32Array로 생성해야 함 
   * 
   * 이 값은 -1 ~ 1의 범위를 가지고 있음.
   */
  let timeDomainArray = new Float32Array(analyserNode.fftSize)

  // 주파수 데이터 가져오기
  analyserNode.getFloatTimeDomainData(timeDomainArray)

  let context = canvas.getContext('2d')
  if (context == null) return

  // 화면 전체 지우기, 옵션이 없는 경우, 옵션이 있지만 clear속성이 없는 경우
  // 옵션이 있고, clear값이 true로 지정된 경우만 화면을 지움
  const isClear = (option == null) || (option != null && option.clear == null)
   || (option != null && option.clear === true)

  if (isClear) {
    context.clearRect(0, 0, canvas.width, canvas.height)
  }

  // 그라디언트 생성, 색은 가로형태로
  let gradient = context.createLinearGradient(0, 0, canvas.width, 0)

  if (option && option.color) {
    if (option.color.length === 0) {
      gradient.addColorStop(0, '#444444')
      gradient.addColorStop(1, '#CCCCCC')
    } else if (option.color.length === 1) {
      gradient.addColorStop(0, option.color[0])
      gradient.addColorStop(1, option.color[0])
    } else if (option.color.length >= 2) {
      for (let i = 0; i < option.color.length; i++) {
        if (i === 0) { // 0 번호는 무조건 0
          gradient.addColorStop(0, option.color[0])
        } else if (i === option.color.length - 1) { // 마지막 번호는 무조건 1
          gradient.addColorStop(1, option.color[option.color.length - 1])
        } else { // 중간 값
          // 나누기 값 (맨 끝은 무조건 1이기 때문에, 중간값을 구하려면 맨 마지막에서 1을 빼서 구해야 함)
          const divide = option.color.length - 1
          gradient.addColorStop(i / divide, option.color[i])
        }
      }
    }
  } else {
    // 각각 빨강, 주황, 노랑, 연두, 초록입니다. (frequency에서 사용하는 색 보다 더 원색에 가까움)
    gradient.addColorStop(0, '#ff3232')
    gradient.addColorStop(0.4, '#ff9831')
    gradient.addColorStop(0.6, '#ffe41c')
    gradient.addColorStop(0.8, '#c5ff11')
    gradient.addColorStop(1, '#56ff17')
  }

  // 그라디언트 설정 및 라인 길이 설정
  context.fillStyle = gradient
  context.strokeStyle = gradient
  context.lineWidth = 2

  /** 표시할 선 개수 */ const lineCount = 512
  /** 배열의 각 간격 */ const divCount = timeDomainArray.length / lineCount

  context.beginPath()
  context.moveTo(0, canvas.height / 2) // 캔버스 x축 0, y축 중앙이 시작값
  for (let i = 0; i < lineCount; i++) {
    const index = i * divCount // 인덱스는 4단위로 건너뜀

    // 참고: 값을 +1을 하는 이유는, 범위를 0 ~ 2로 변경하기 위해서
    // 그러면, y축의 위치를 더 편하게 계산할 수 있음.
    const value = timeDomainArray[index] + 1 

    const x = (canvas.width / lineCount) * i
    
    // y축은 맨 위를 2, 맨 아래를 0으로 보고, 이에 맞게 배치함
    // 따라서 캔버스의 높이 - (값의 따른 높이) 의 값으로 y축을 배치함
    const y = canvas.height - (canvas.height / 2 * value)
    context.lineTo(x, y)
  }
  context.stroke()
}