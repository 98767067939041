import { range } from "./decorators";

export enum NodeKind {
  Gain = "gain",
  Compressor = "compressor",
  Delay = "delay",
  Convolver = "convolver",
  Panner = "panner",
  BiquadFilter = "biquadFilter",

  // n
  Surround = "surround",

  // TunaNodes
  Tuna_Compressor = "tuna_compressor",
  Tuna_Chorus = "tuna_chorus",
  Tuna_Phaser = "tuna_phaser",
  Tuna_Bitcrusher = "tuna_bitcrusher",
  Tuna_Moog = "tuna_moog",
  Tuna_Tremolo = "tuna_tremolo",
  Tuna_Overdrive = "tuna_overdrive",
  Tuna_Convolver = "tuna_convolver",
  Tuna_Cabinet = "tuna_cabinet",

  // PizzicatoNodes
  Pizzicato_Distortion = "pizzicato_distortion",
  Pizzicato_Quadrafuzz = "pizzicato_quadrafuzz",
  Pizzicato_Reverb = "pizzicato_reverb",
  Pizzicato_HighPassFilter = "pizzicato_highpassFilter",
}

export const getControlValue = (a: IEffect) => {
  if (a.nodeKind === NodeKind.Gain) {
    return (a as IGainEffect).gain;
  } else if (a.nodeKind === NodeKind.BiquadFilter) {
    return (a as IBiquadFilterEffect).gain;
  } else if (a.nodeKind === NodeKind.Panner) {
    return (a as IPannerEffect).position;
  }
  return null;
};

export const isSameEffect = (a: IEffect, b: IEffect) => {
  if (a.nodeKind !== b.nodeKind) {
    return false;
  }

  if (a.nodeKind === NodeKind.Gain) {
    return true;
  } else if (a.nodeKind === NodeKind.BiquadFilter) {
    const _a = a as IBiquadFilterEffect;
    const _b = b as IBiquadFilterEffect;

    return (
      _a.nodeKind === _b.nodeKind &&
      _a.frequency === _b.frequency &&
      _a.type === _b.type
    );
  } else if (a.nodeKind === NodeKind.Panner) {
    const _a = a as IPannerEffect;
    const _b = b as IPannerEffect;
    return (
      _a.panningModel === _b.panningModel &&
      _a.distanceModel === _b.distanceModel &&
      _a.refDistance === _b.refDistance &&
      _a.maxDistance === _b.maxDistance &&
      _a.rolloffFactor === _b.rolloffFactor &&
      _a.coneInnerAngle === _b.coneInnerAngle &&
      _a.coneOuterAngle === _b.coneOuterAngle &&
      _a.coneOuterGain === _b.coneOuterGain
    );
  } else if (a.nodeKind === NodeKind.Surround) {
    const _a = a as ISurroundEffect;
    const _b = b as ISurroundEffect;
    return (
      _a.panningModel === _b.panningModel &&
      _a.distanceModel === _b.distanceModel &&
      _a.refDistance === _b.refDistance &&
      _a.maxDistance === _b.maxDistance &&
      _a.rolloffFactor === _b.rolloffFactor &&
      _a.coneInnerAngle === _b.coneInnerAngle &&
      _a.coneOuterAngle === _b.coneOuterAngle &&
      _a.coneOuterGain === _b.coneOuterGain
    );
  }

  return false;
};

export class IEffect {
  get nodeKind(): NodeKind {
    throw new Error("Not Implemented: " + this);
  }

  get controlValue(): number | null {
    return null;
  }

  start?: number = 0;
  end?: number = 0;
}

export class IConvolverEffect extends IEffect {
  get nodeKind() {
    return NodeKind.Convolver;
  }

  buffer!: ConvolverNode["buffer"];
  normalize?: ConvolverNode["normalize"];
}

export class IGainEffect extends IEffect {
  get nodeKind() {
    return NodeKind.Gain;
  }

  get controlValue(): number {
    return this.gain;
  }

  @range(0, 1)
  gain!: GainNode["gain"]["value"];
}

export class ICompressorEffect extends IEffect {
  get nodeKind() {
    return NodeKind.Compressor;
  }

  threshold?: DynamicsCompressorNode["threshold"]["value"];
  knee?: DynamicsCompressorNode["knee"]["value"];
  ratio?: DynamicsCompressorNode["ratio"]["value"];
  attack?: DynamicsCompressorNode["attack"]["value"];
  release?: DynamicsCompressorNode["release"]["value"];
}

export class IDelayEffect extends IEffect {
  get nodeKind() {
    return NodeKind.Delay;
  }

  delayTime!: DelayNode["delayTime"]["value"];
}

export class IPannerEffect extends IEffect {
  get nodeKind() {
    return NodeKind.Panner;
  }

  panningModel?: PannerNode["panningModel"];
  distanceModel?: PannerNode["distanceModel"];
  refDistance?: PannerNode["refDistance"];
  maxDistance?: PannerNode["maxDistance"];

  rolloffFactor?: PannerNode["rolloffFactor"];
  coneInnerAngle?: PannerNode["coneInnerAngle"];
  coneOuterAngle?: PannerNode["coneOuterAngle"];
  coneOuterGain?: PannerNode["coneOuterGain"];

  position!: {
    x: PannerNode["positionX"]["value"];
    y: PannerNode["positionY"]["value"];
    z: PannerNode["positionZ"]["value"];
  };
}

export class ISurroundEffect extends IEffect {
  get nodeKind() {
    return NodeKind.Panner;
  }

  get controlValue(): number {
    return this.positions[0].x; // 숫자형이 리턴되도록 변경
  }

  panningModel?: PannerNode["panningModel"];
  distanceModel?: PannerNode["distanceModel"];
  refDistance?: PannerNode["refDistance"];
  maxDistance?: PannerNode["maxDistance"];

  rolloffFactor?: PannerNode["rolloffFactor"];
  coneInnerAngle?: PannerNode["coneInnerAngle"];
  coneOuterAngle?: PannerNode["coneOuterAngle"];
  coneOuterGain?: PannerNode["coneOuterGain"];

  positions!: {
    x: PannerNode["positionX"]["value"];
    y: PannerNode["positionY"]["value"];
    z: PannerNode["positionZ"]["value"];
  }[];
}

export class IBiquadFilterEffect extends IEffect {
  get nodeKind() {
    return NodeKind.BiquadFilter;
  }

  get controlValue(): number {
    return this.gain;
  }

  frequency!: BiquadFilterNode["frequency"]["value"];
  Q?: BiquadFilterNode["Q"]["value"];
  detune?: BiquadFilterNode["detune"]["value"];
  @range(-5, 5)
  gain!: BiquadFilterNode["gain"]["value"];
  type!: BiquadFilterNode["type"];
}

export class ITunaCompressorEffect extends IEffect {
  get nodeKind() {
    return NodeKind.Tuna_Compressor;
  }

  @range(-100, 0)
  threshold!: number;
  @range(0, 100)
  makeupGain!: number;
  @range(0, 1000)
  attack!: number;
  @range(0, 3000)
  release!: number;
  @range(1, 20)
  ratio!: number;
  @range(0, 40)
  knee!: number;
  automakeup!: boolean;
  bypass!: 0 | 1;
}

export class ITunaChorusEffect extends IEffect {
  get nodeKind() {
    return NodeKind.Tuna_Chorus;
  }

  @range(0.01, 8)
  rate!: number;
  @range(0, 1.5)
  feedback!: number;
  @range(0, 1)
  depth!: number;
  @range(0, 1)
  delay!: number;
  bypass!: 0 | 1;
}

export class ITunaPhaserEffect extends IEffect {
  get nodeKind() {
    return NodeKind.Tuna_Phaser;
  }

  @range(0.01, 8)
  rate!: number; //0.01 to 8 is a decent range, but higher values are possible
  @range(0, 1)
  depth!: number; //0 to 1
  @range(0, 1)
  feedback!: number; //0 to 1+
  @range(0, 100)
  stereoPhase!: number; //0 to 180
  @range(500, 1500)
  baseModulationFrequency!: number; //500 to 1500
  bypass!: 0 | 1;
}

export class ITunaBitcrusherEffect extends IEffect {
  get nodeKind() {
    return NodeKind.Tuna_Bitcrusher;
  }

  @range(1, 16, { step: 1 })
  bits!: number;
  @range(0, 1)
  normFreq!: number;
  @range(256, 16384, { step: 1 })
  bufferSize!: number;
}

export class ITunaMoogEffect extends IEffect {
  get nodeKind() {
    return NodeKind.Tuna_Moog;
  }

  @range(0, 1)
  cutoff!: number;
  @range(0, 4)
  resonance!: number;
  @range(256, 16384, { step: 1 })
  bufferSize!: number;
}

export class ITunaTremoloEffect extends IEffect {
  get nodeKind() {
    return NodeKind.Tuna_Tremolo;
  }

  @range(0, 1)
  intensity!: number;
  @range(0.001, 8)
  rate!: number;
  @range(0, 180)
  stereoPhase!: number;
  bypass!: 0 | 1;
}

export class ITunaOverdriveEffect extends IEffect {
  get nodeKind() {
    return NodeKind.Tuna_Overdrive;
  }

  @range(-42, 0)
  outputGain!: number;
  @range(0, 1)
  drive!: number;
  @range(0, 1)
  curveAmount!: number;
  @range(0, 5, { step: 1 })
  algorithmIndex!: 0 | 1 | 2 | 3 | 4 | 5;
  bypass!: 0 | 1;
}

export class ITunaConvolverEffect extends IEffect {
  get nodeKind() {
    return NodeKind.Tuna_Convolver;
  }

  @range(20, 22050)
  highCut!: number;
  @range(20, 22050)
  lowCut!: number;
  @range(0, 1.5)
  dryLevel!: number;
  @range(0, 1.5)
  wetLevel!: number;
  @range(0, 1.5)
  level!: number;
  impulse!: string;
  bypass!: 0 | 1;
}

export class ITunaCabinetEffect extends IEffect {
  get nodeKind() {
    return NodeKind.Tuna_Cabinet;
  }

  makeupGain!: number;
  impulsePath!: string;
  bypass!: 0 | 1;
}

export class IPizzicatoDistortionEffect extends IEffect {
  get nodeKind() {
    return NodeKind.Pizzicato_Distortion;
  }

  @range(0, 1)
  gain!: number;
}

export class IPizzicatoQuadrafuzzEffect extends IEffect {
  get nodeKind() {
    return NodeKind.Pizzicato_Quadrafuzz;
  }

  @range(0, 1)
  lowGain!: number;
  @range(0, 1)
  midLowGain!: number;
  @range(0, 1)
  midHighGain!: number;
  @range(0, 1)
  highGain!: number;
  mix!: number;
}

export class IPizzicatoReverbEffect extends IEffect {
  get nodeKind() {
    return NodeKind.Pizzicato_Reverb;
  }

  @range(0.01, 3)
  time!: number;
  @range(0, 3)
  decay!: number;
  reverse!: boolean;
  @range(0, 1)
  mix!: number;
}

export class IPizzicatoHighPassFilterEffect extends IEffect {
  get nodeKind() {
    return NodeKind.Pizzicato_HighPassFilter;
  }

  @range(10, 22050)
  frequency!: number;
  @range(0.0001, 20)
  peak!: number;
}
