/** 
 * 레이아웃의 상수 목록 
 * 
 * 참고: css 값을 그대로 사용하기 때문에 모든 값은 string입니다. (number가 아님!)
 */
export const constLayout = {
  /** pc 버전의 헤더 높이 */ PC_HEADER_HEIGHT: '100px',
  /** pc 버전의 사이드바 너비 */ PC_SIDEBAR_WIDTH: '96px',
  /** pc 버전의 사이드바가 확장된 너비 */ PC_SIDEBAR_EXPAND_WIDTH: '440px',
  /** 모바일 버전의 헤더 높이 */ MOBILE_HEADER_HEIGHT: '60px',
  /** 모바일 버전의 사이드바 높이 */ MOBILE_SIDEBAR_HEIGHT: '80px',
}