import { offlineRenderStore } from './offline-render';
import { ITimelineStore, timelineStore } from './timeline';
import {
  uiHistory,
  uiLoading,
  uiMobileBgmMenu,
  uiMobileEffectMenu,
  uiPopup,
  uiStore,
  uiTimeLine,
  uiToast,
} from './ui';
import { userStore } from './user';
import { IVideoStore, videoStore } from './video';
import { youtubeStore } from './youtube';

export const useStores = () => {
  return {
    timelineStore,
    videoStore,
    youtubeStore,
    offlineRenderStore,
    userStore,
    uiStore,
    uiHistory,
    uiToast,
    uiLoading,
    uiPopup,
    uiTimeLine,
    uiMobileEffectMenu,
    uiMobileBgmMenu,
  };
};
