import { updateDoc } from 'firebase/firestore';
import { isNil } from 'lodash-es';

import { offlineRenderStore } from 'store/offline-render';

/**
 * 클래스 LoadingProgress
 *
 * 이 클래스는 로딩 진행바를 연산하기 위한 클래스 객체입니다.
 *
 * movePercent 함수를 이용하여 로딩 진행바를 어디까지 진행시킬지를 결정하고, 100%로 완료했다고 생각하면 complete함수를 호출하면 됩니다.
 *
 * 참고: 이 클래스는 퍼센트 값만 연산하므로, 그래픽은 따로 만드셔야 합니다.
 *
 * 참고2: 만약 컨테이너(react에서)를 언마운트 해야한다면, 이 클래스에서 cancle 함수를 사용해주세요.
 * 이 클래스는 setInterval을 사용하여 퍼센트를 실시간으로 계산합니다.
 * 그렇기 때문에 언마운트 할 때 cancle을 호출하지 않으면 setInterval이 계속 동작할 가능성이 있습니다.
 *
 * @version 2023/01/02
 */
export class LoadingProgress {
  constructor() {
    /** 증가를 시작할 지점의 퍼센트 */
    this.startPercent = 0;

    /** 어느 퍼센트까지 게이지바를 증가시킬건지에 대한 값 */
    this.endPercent = 0;

    /** 현재 진행된 퍼센트값 */
    this.currentPercent = 0;

    /** 1틱당 증가하는 기준 퍼센트 */
    this.tickPercent = 0;

    /** 예상보다 빨리 처리되었을 때, 빠르게 증가하기 위한 보너스 퍼센트 */
    this.bonusPercent = 0;

    /** 시작지점에서 게이지바를 올리는데 소요된 총 시간 */
    this.elapsedTime = 0;

    /** 타이머 ID를 사용하지 않는 경우 */
    this.UNUSED_TIMER_ID = -999;

    /** 현재 사용하고 있는 타이머 ID */
    this.currentTimerId = this.UNUSED_TIMER_ID;

    /** 타이머 확인 간격 (단위: ms) -> 0.02초 */
    this.TIMER_INTERVAL = 20;

    /** 타이머 확인을 위한 초당 프레임 수 */
    this.FPS = 50;

    /** 현재 상태 값 */
    this.state = '';

    this.STATE_UNUSED = 'unused';
    this.STATE_RUNNING = 'running';
    this.STATE_CANCLE = 'cancle';
    this.STATE_COMPLETE = 'complete';
    this.STATE_STOP = 'stop';
  }

  /**
   * 게이지를 특정 퍼센트까지 증가시킵니다.
   *
   * 주의: 이 값을 100 이상으로 입력해도 게이지바는 100%로 채워지는것이 아닌 99.99%로 채워집니다.
   *
   * 따라서, 게이지를 100%상황으로 만들고 싶다면, 반드시 complete 함수를 호출해서 게이지 증가 작업을 완료하세요.
   *
   * 그리고, 가능하다면, 예상 시간을 길게 설정하는것이 좋습니다. 그래야 좀 더 자연스럽게 로딩 게이지를 지정할 수 있습니다.
   * @param {number} nextEndPercent 게이지바의 목표 퍼센트(이 퍼센트까지 진행도가 오름)
   * @param {number} estimatedTime 게이지바를 올리는데 걸리는 예상 시간(단위: 초) (60를 설정하면, 60초에 걸쳐서 상승함. 따라서 가능하다면 최대한 높은 숫자로 설정)
   */
  movePercent(nextEndPercent, estimatedTime) {
    const fireDoc = offlineRenderStore.fireDoc;

    console.log('movePercent', nextEndPercent, estimatedTime);

    if (!isNil(fireDoc)) {
      updateDoc(fireDoc.ref, {
        progress: nextEndPercent,
      });
    }

    // 진행 중인 상태에서 새 입력이 들어왔다면, 이전 입력은 완료된것으로 간주하고 새로운것으로 대체됩니다.
    // 그러나, 다음에 지정한 엔드퍼센트값이 이전 값보다 낮다면 해당 처리에 대한 경고를 표시하고, 무시합니다.
    if (this.state === this.STATE_RUNNING) {
      if (nextEndPercent <= this.endPercent) {
        console.warn(
          '다음 endPercent는 이전 endPercent보다 높은 값이어야 합니다. 진행된 값보다 뒤로 가는것은 불가능합니다. 값을 리셋하려면 해당 객체의 reset 함수를 사용하세요.',
        );
        return;
      } else {
        // 이전의 도착지점이 새로운 지점으로 변경됨
        this.startPercent = this.endPercent;

        // 그리고 남은 진행된 퍼센트만큼을 보너스로 추가
        this.bonusPercent = this.endPercent - this.currentPercent;

        // 그리고 새로운 도착 지점 지정
        this.endPercent = nextEndPercent;

        // 틱 퍼센트 (공식은 다음과 같음.) 매 틱마다 오로는 값의 기준점
        this.tickPercent =
          (this.endPercent - this.startPercent) / this.FPS / estimatedTime;
      }
    } else {
      // 만약 진행중인 상태가 아니면, 어떠한 미터바도 동작하지 않는 상태이므로,
      // 시작 지점은 0으로 설정
      this.startPercent = 0;

      // 보너스 퍼센트는 없음
      this.bonusPercent = 0;

      // 엔드 퍼센트는 다음 엔드 퍼센트로 지정됨
      this.endPercent = nextEndPercent;

      // 틱 퍼센트 (공식은 다음과 같음.) 매 틱마다 오로는 값의 기준점
      this.tickPercent =
        (this.endPercent - this.startPercent) / this.FPS / estimatedTime;

      // 지금은 아무것도 동작하지 않는 상태이므로, 해당 상태를 running으로 변경합니다.
      this.state = this.STATE_RUNNING;

      // 새로운 타이머 생성
      this.setIntervalTimer();
    }
  }

  /**
   * 완료 상태로 설정 하기
   *
   * 게이지바가 순식간에 100%로 변경되며, 타이머는 게이지바가 100이 넘는 순간 해제됩니다.
   *
   * 반드시 해당 함수를 사용해야 게이지바의 증가가 완료됨을 기억하세요.
   */
  complete() {
    this.state = this.STATE_COMPLETE;
  }

  /**
   * 지금까지 설정된 게이지 기준값을 취소합니다.
   *
   * 게이지바의 증가 및 감소는 즉시 중단되고, 게이지 값이 0으로 설정된 이후, 등록되었던 타이머가 즉시 취소됩니다.
   */
  cancle() {
    this.state = this.STATE_CANCLE;
  }

  /**
   * 지금까지 설정된 게이지 기준값을 리셋하고 게이지 증가를 정지합니다.
   *
   * 취소 상태랑 비슷하지만, 현재 퍼센트를 0으로 설정합니다.
   */
  reset() {
    this.state = this.STATE_CANCLE;
    this.currentPercent = 0;
  }

  setIntervalTimer() {
    if (this.currentTimerId === this.UNUSED_TIMER_ID) {
      let bindFunction = this.process.bind(this);
      this.currentTimerId = setInterval(bindFunction, this.TIMER_INTERVAL);
    }
  }

  clearIntervalTimer() {
    if (this.currentTimerId !== this.UNUSED_TIMER_ID) {
      clearInterval(this.currentTimerId);
      this.currentTimerId = this.UNUSED_TIMER_ID;
    }
  }

  /** process 함수 기능 분해용 */
  processRunning() {
    // 현재 퍼센트가 엔드 퍼센트보다 낮으면 해당 틱만큼 지속적으로 퍼센트가 증가합니다.
    if (this.currentPercent < this.endPercent) {
      this.currentPercent += this.tickPercent;
    }

    // 보너스 퍼센트가 남아있는경우, 해당 틱값 이상일 때 남은 보너스의 10%만큼 매 틱마다 추가하고,
    // 해당 틱값 이하라면 모든 보너스 퍼센트를 한번에 추가합니다.
    // (남은 보너스의 10%만큼 하다보면 결국 나중에는 증가 속도가 느려지기 때문)
    if (this.bonusPercent > 0) {
      if (this.bonusPercent > this.tickPercent) {
        let bonusTick = this.bonusPercent / 10;
        this.bonusPercent -= bonusTick;
        this.currentPercent += bonusTick;
      } else {
        let bonusTick = this.bonusPercent;
        this.bonusPercent = 0;
        this.currentPercent += bonusTick;
      }
    }

    // 만약 도착지점 이상이라면, 더이상 퍼센트는 증가하지 않고, stop상태가 됩니다.
    // complete를 호출하지 않으면 진행 상황이 완료되지 않습니다. 이점은 주의해주세요.
    if (this.currentPercent >= this.endPercent) {
      this.currentPercent = this.endPercent; // 소수점 계산의 의한 오차 방지를 위해 강제로 해당 퍼센트 지정

      if (this.currentPercent === 100) {
        this.currentPercent = 99.99;
      } else {
        this.state = this.STATE_STOP;
      }

      this.clearIntervalTimer(); // (더이상 프로세스를 가동할 필요가 없으므로) 타이머 삭제
    }
  }

  /** process 함수 기능 분해용 */
  processComplete() {
    // 완료 상태에서는 100%으로 빠르게 증가합니다.
    // 다만 이 공식은 100%에 다가갈수록 더욱 느리게 증가하는 구조이기 때문에,
    // 어느 시점이 되면 100%으로 만들어야 합니다.
    let tickPercent = (100 - this.currentPercent) / 10;
    this.currentPercent += tickPercent;

    // 현재 퍼센트가 99% 이상이라면, 강제로 100%으로 지정됩니다.
    if (this.currentPercent > 99) {
      this.currentPercent = 100;
      this.clearIntervalTimer();
    }
  }

  /** process 함수 기능 분해용 */
  processCancle() {
    // 취소 상태에서는 더이상 process가 동작하지 않습니다.
    // 그리고 타이머가 해제됩니다.
    // 현재 퍼센트는 0으로 지정됩니다.

    this.currentPercent = 0;
    this.clearIntervalTimer();
  }

  process() {
    if (this.state === this.STATE_RUNNING) this.processRunning();
    else if (this.state === this.STATE_COMPLETE) this.processComplete();
    else if (this.state === this.STATE_CANCLE) this.processCancle();
  }
}
