import React, { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { firestore } from 'firebase-app';
import { doc, getDoc } from 'firebase/firestore';
import { useStores } from 'store';

import { Video } from 'component/video';

export const RenderPage = () => {
  const { videoStore, timelineStore, offlineRenderStore } = useStores();
  const [query] = useSearchParams();
  const contextID = query.get('context_id');

  useEffect(() => {
    (async () => {
      const firedoc = await getDoc(
        doc(firestore, `render_context`, `${contextID}`),
      );
      const context = firedoc.data();

      offlineRenderStore.fireDoc = firedoc;
      timelineStore.load(context);

      const createFile = async () => {
        const response = await fetch(context!.video_url);
        const data = await response.blob();
        const metadata = {
          type: 'video/mp4',
        };
        const file = new File([data], 'video.mp4', metadata);

        return file;
      };

      setTimeout(async () => {
        await videoStore.importVideoFromFile(await createFile());
        await new Promise(resolve => setTimeout(resolve, 1000));
        await videoStore.exportVideo(true);
      }, 3000);
    })();
  }, []);

  return (
    <div>
      <Video />
    </div>
  );
};
