import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";

import Listener from "asset/listener.png";
import { useStores } from "store";
import { setListenerPosition, setSpeakerPosition } from "sfx";

interface SpeakersProps {}
export const Speakers = ({}: SpeakersProps) => {
  const { videoStore } = useStores();
  const [clicked, setClicked] = useState(false);

  const onMouseMove = (e: any) => {
    if (!clicked) return;

    e.preventDefault();

    const t = e.target;
    const cx = e.clientX || e.touches[0].clientX;
    const cy = e.clientY || e.touches[0].clientY;
    const parent = t.parentNode;
    const parentRect = parent.getBoundingClientRect();
    const x = cx - parentRect.left;
    const y = cy - parentRect.top;
    if (x < 0 || x > 300) return;
    if (y < 0 || y > 300) return;
    //setListenerPosition(x, y);

    e.target.style.left = `${x - 23}px`;
    e.target.style.top = `${y - 23}px`;

    setListenerPosition(x, y);
  };

  return (
    <Container
      onClick={(e) => {
        e.stopPropagation();
        onMouseMove(e);
      }}
    >
      <ListenerImage
        className="handle"
        src={Listener}
        onMouseDown={() => setClicked(true)}
        onMouseUp={() => setClicked(false)}
        onMouseMove={onMouseMove}
      />
      <div className="control-area">
        {videoStore.speakers.map((x, idx) => (
          <Speaker
            key={idx}
            idx={idx}
            className="speaker speaker-l"
            style={{ transform: `rotateZ(${x.angle}deg)` }}
          />
        ))}
      </div>
    </Container>
  );
};

interface SpeakerProps {
  idx: number;
  className: string;
  style: any;
}
const Speaker = observer(({ idx, style, ...props }: SpeakerProps) => {
  const { videoStore } = useStores();
  const [clicked, setClicked] = useState(false);

  const onMouseMove = (e: any) => {
    if (!clicked) return;
    e.preventDefault();

    const t = e.target;
    const cx = e.clientX || e.touches[0].clientX;
    const cy = e.clientY || e.touches[0].clientY;
    const parent = t.parentNode;
    const parentRect = parent.getBoundingClientRect();
    const x = cx - parentRect.left;
    const y = cy - parentRect.top;

    if (x < 0 || x > 300) return;
    if (y < 0 || y > 300) return;

    e.target.style.left = `${x - 16}px`;
    e.target.style.top = `${y - 16}px`;

    setSpeakerPosition(idx, x, y);
  };

  return (
    <SpeakerImage
      src="/img/speaker.png"
      style={{
        left: `${videoStore.speakers[idx].x}px`,
        top: `${videoStore.speakers[idx].y}px`,
        ...style,
      }}
      onMouseDown={() => setClicked(true)}
      onMouseUp={() => setClicked(false)}
      onMouseMove={onMouseMove}
      {...props}
    />
  );
});

const Container = styled.div`
  position: relative;
  width: 300px;
  height: 300px;

  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
`;
const ListenerImage = styled.img`
  position: absolute;
  width: 46px;
  cursor: move;
  left: 125px;
  top: 130px;
  z-index: 1000;
`;
const SpeakerImage = styled.img`
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;
