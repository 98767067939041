import Pizzicato from 'pizzicato';
import Tuna from 'tunajs';

import {
  IBiquadFilterEffect,
  ICompressorEffect,
  IConvolverEffect,
  IDelayEffect,
  IEffect,
  IGainEffect,
  IPannerEffect,
  IPizzicatoDistortionEffect,
  IPizzicatoHighPassFilterEffect,
  IPizzicatoQuadrafuzzEffect,
  IPizzicatoReverbEffect,
  ISurroundEffect,
  ITunaBitcrusherEffect,
  ITunaCabinetEffect,
  ITunaChorusEffect,
  ITunaCompressorEffect,
  ITunaConvolverEffect,
  ITunaMoogEffect,
  ITunaOverdriveEffect,
  ITunaPhaserEffect,
  ITunaTremoloEffect,
  NodeKind,
} from './types';

export const createEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: IEffect,
) => {
  const _ctx = ctx as any;
  //if (!_ctx.tuna) {
  _ctx.tuna = new Tuna(ctx as any);
  //}

  if (!effect) {
    throw new Error('given effect is null');
  }

  switch (effect.nodeKind) {
    case NodeKind.Gain:
      return createGainEffect(ctx, effect as IGainEffect);
    case NodeKind.Compressor:
      return createCompressorEffect(ctx, effect as ICompressorEffect);
    case NodeKind.Delay:
      return createDelayEffect(ctx, effect as IDelayEffect);
    case NodeKind.Convolver:
      return createConvolverEffect(ctx, effect as IConvolverEffect);
    case NodeKind.Panner:
      return createPannerEffect(ctx, effect as IPannerEffect);
    case NodeKind.BiquadFilter:
      return createBiquadFilterEffect(ctx, effect as IBiquadFilterEffect);

    case NodeKind.Surround:
      return createSurroundEffect(ctx, effect as ISurroundEffect);

    case NodeKind.Tuna_Compressor:
      return createTunaCompressorEffect(ctx, effect as ITunaCompressorEffect);
    case NodeKind.Tuna_Chorus:
      return createTunaChorusEffect(ctx, effect as ITunaChorusEffect);
    case NodeKind.Tuna_Phaser:
      return createTunaPhaserEffect(ctx, effect as ITunaPhaserEffect);
    case NodeKind.Tuna_Bitcrusher:
      return createTunaBitcrusherEffect(ctx, effect as ITunaBitcrusherEffect);
    case NodeKind.Tuna_Moog:
      return createTunaMoogEffect(ctx, effect as ITunaMoogEffect);
    case NodeKind.Tuna_Tremolo:
      return createTunaTremoloEffect(ctx, effect as ITunaTremoloEffect);
    case NodeKind.Tuna_Overdrive:
      return createTunaOverdriveEffect(ctx, effect as ITunaOverdriveEffect);
    case NodeKind.Tuna_Convolver:
      return createTunaConvolverEffect(ctx, effect as ITunaConvolverEffect);
    case NodeKind.Tuna_Cabinet:
      return createTunaCabinetEffect(ctx, effect as ITunaCabinetEffect);

    case NodeKind.Pizzicato_Distortion:
      return createPizzicatoDistortionEffect(
        ctx,
        effect as IPizzicatoDistortionEffect,
      );
    case NodeKind.Pizzicato_Quadrafuzz:
      return createPizzicatoQuadrafuzzEffect(
        ctx,
        effect as IPizzicatoQuadrafuzzEffect,
      );
    case NodeKind.Pizzicato_Reverb:
      return createPizzicatoReverbEffect(ctx, effect as IPizzicatoReverbEffect);
    case NodeKind.Pizzicato_HighPassFilter:
      return createPizzicatoHighPassFilterEffect(
        ctx,
        effect as IPizzicatoHighPassFilterEffect,
      );
  }

  throw new Error(`unknown nodeKind: ${effect.nodeKind}`);
};

export const createEffectNodes = (
  ctx: AudioContext | OfflineAudioContext,
  effects: IEffect[],
) => {
  const nodes = effects.map(x =>
    //@ts-expect-error
    createEffect(ctx, { ...x, start: 0, end: Number.MAX_SAFE_INTEGER }),
  );
  let first = nodes[0];

  console.log('createf', effects);

  if (Array.isArray(first)) {
    //@ts-ignore
    const gain = createGainEffect(ctx, {
      nodeKind: NodeKind.Gain,
      gain: 1,
      start: 0,
      end: Number.MAX_SAFE_INTEGER,
    });
    gain.connect(first[0]);
    first = gain;
    nodes.unshift(gain);
  }

  nodes.slice(1).reduce((prev, current) => {
    console.log('connect', current);
    if (Array.isArray(current)) {
      prev.connect(current[0]);
      return current[1];
    } else {
      prev.connect(current);
    }

    return current;
  }, first);

  const last = nodes[nodes.length - 1];
  if (Array.isArray(last)) {
    //@ts-ignore
    const gain = createGainEffect(ctx, {
      nodeKind: NodeKind.Gain,
      gain: 1,
      start: 0,
      end: Number.MAX_SAFE_INTEGER,
    });
    last[1].connect(gain);
    nodes.push(gain);
  }

  return nodes;
};

/**
 * 오디오에 적용할 이펙트들을 생성하고 각 이펙트들을 연결합니다.
 *
 * 최종 출력 결과물을 얻기 위해서는 반드시 offlineAudioContext.startRendering() 함수를 실행해야 합니다.
 * 이 함수로는 버퍼 관련 작업만 진행하고 최종 출력하지는 않습니다.
 * 
 * @deprecated 2024/06/11 기준 사용되지 않음
 * @param ctx
 * @param effects
 * @param options
 * @returns {Promise<AudioBuffer>} 오프라인 오디오 작업의 최종 결과물
 */
export const createEffects = (
  ctx: AudioContext | OfflineAudioContext,
  effects: IEffect[],
  mainAudioBuffer: AudioBuffer,
) => {

  // 만들어진 이펙트 정보를 가진 노드
  const nodes = effects.map(x => createEffect(ctx, x));
  const aggregator = ctx.createGain();

  if (nodes.length === 0) {
    const buffer = ctx.createBufferSource();
    buffer.buffer = mainAudioBuffer;
    buffer.start();

    buffer.connect(aggregator);
  }

  nodes.forEach((node, index) => {
    let timeBuffer = ctx.createBufferSource(); // 새로운 버퍼 소스
    let tBuffer = ctx.createBuffer(
      2,
      ctx.sampleRate * (effects[index].end! - effects[index].start!),
      ctx.sampleRate,
    );

    // 메인 오디오 버퍼를 복사하기 위해, getChannelData로 float32Array를 얻어온 뒤에,
    // mainAudioBuffer의 copyFromChannel을 통해 버퍼를 각 채널에 맞게 복사한 후, timeBuffer에 버퍼를 넣습니다.
    let tBuffer0 = tBuffer.getChannelData(0);
    let tBuffer1 = tBuffer.getChannelData(1);
    mainAudioBuffer.copyFromChannel(
      tBuffer0,
      0,
      effects[index].start! * ctx.sampleRate,
    );
    mainAudioBuffer.copyFromChannel(
      tBuffer1,
      1,
      effects[index].start! * ctx.sampleRate,
    );
    timeBuffer.buffer = tBuffer;

    timeBuffer.connect(node).connect(aggregator);

    timeBuffer.start(effects[index].start);
  });

  return aggregator;
};

const setValueWithRange = (
  param: AudioParam,
  value: number,
  outValue: number,
  options: IEffect,
) => {
  if (options.start !== undefined && options.end !== undefined) {
    // 참고: exponentialRampToValueAtTime은 0을 인자로 받을 수 없습니다.
    // 따라서 볼륨이 0으로 지정된다면, 이 값을 0.0001 같은 형태로 변경합니다.
    if (value === 0) value = 0.0001;

    param.setValueAtTime(outValue, 0);
    param.setValueAtTime(outValue, options.start);
    param.exponentialRampToValueAtTime(value, options.start + 1);
    param.setValueAtTime(value, options.end - 1);
    param.exponentialRampToValueAtTime(outValue, options.end);
  } else {
    param.setValueAtTime(value, 0);
  }
};

const createGainEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: IGainEffect,
) => {
  const node = ctx.createGain();
  setValueWithRange(node.gain, effect.gain, 1, effect);
  return node;
};

const createCompressorEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: ICompressorEffect,
) => {
  const node = ctx.createDynamicsCompressor();
  node.threshold.setValueAtTime(effect.threshold!, 0);
  node.knee.setValueAtTime(effect.knee!, 0);
  node.ratio.setValueAtTime(effect.ratio!, 0);
  node.attack.setValueAtTime(effect.attack!, 0);
  node.release.setValueAtTime(effect.release!, 0);
  return node;
};

const createConvolverEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: IConvolverEffect,
) => {
  const node = ctx.createConvolver();
  node.buffer = effect.buffer;
  node.normalize = effect.normalize!;
  return node;
};

const createDelayEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: IDelayEffect,
) => {
  const node = ctx.createDelay();
  setValueWithRange(node.delayTime, effect.delayTime, 0, effect);
  return node;
};

const createPannerEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: IPannerEffect,
) => {
  const node = ctx.createPanner();

  node.panningModel = effect.panningModel!;
  node.distanceModel = effect.distanceModel!;
  node.refDistance = effect.refDistance!;
  node.maxDistance = effect.maxDistance!;
  node.rolloffFactor = effect.rolloffFactor!;
  node.coneInnerAngle = effect.coneInnerAngle!;
  node.coneOuterAngle = effect.coneOuterAngle!;
  node.coneOuterGain = effect.coneOuterGain!;

  node.positionX.setValueAtTime(effect.position.x ?? 0, 0);
  node.positionY.setValueAtTime(effect.position.y ?? 0, 0);
  node.positionZ.setValueAtTime(effect.position.z ?? 0, 0);

  return node;
};

const createSurroundEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: ISurroundEffect,
) => {
  const splitter = ctx.createChannelSplitter(2);
  const merger = ctx.createGain();

  let i = 0;
  for (const p of effect.positions) {
    const node = ctx.createPanner();

    node.panningModel = effect.panningModel!;
    node.distanceModel = effect.distanceModel!;
    node.refDistance = effect.refDistance!;
    node.maxDistance = effect.maxDistance!;
    node.rolloffFactor = effect.rolloffFactor!;
    node.coneInnerAngle = effect.coneInnerAngle!;
    node.coneOuterAngle = effect.coneOuterAngle!;
    node.coneOuterGain = effect.coneOuterGain!;

    node.positionX.setValueAtTime(p.x ?? 0, 0);
    node.positionY.setValueAtTime(p.y ?? 0, 0);
    node.positionZ.setValueAtTime(p.z ?? 0, 0);

    splitter.connect(node, i === 1 ? 1 : 0);
    node.connect(merger, 0);

    i++;
  }

  return [splitter, merger];
};

const createBiquadFilterEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: IBiquadFilterEffect,
) => {
  const node = ctx.createBiquadFilter();

  setValueWithRange(node.gain, effect.gain, 1, effect);
  node.frequency.value = effect.frequency;
  node.type = effect.type;
  if (effect.detune) {
    node.detune.value = effect.detune;
  }
  if (effect.Q) {
    node.Q.value = effect.Q;
  }

  return node;
};

const createTunaCompressorEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: ITunaCompressorEffect,
) => {
  const node = new (ctx as any).tuna.Compressor({
    ...effect,
  });
  return node;
};

const createTunaChorusEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: ITunaChorusEffect,
) => {
  const node = new (ctx as any).tuna.Chorus({
    ...effect,
  });
  return node;
};

const createTunaPhaserEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: ITunaPhaserEffect,
) => {
  const node = new (ctx as any).tuna.Phaser({
    ...effect,
  });
  return node;
};

const createTunaBitcrusherEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: ITunaBitcrusherEffect,
) => {
  const node = new (ctx as any).tuna.Bitcrusher({
    ...effect,
  });
  return node;
};

const createTunaMoogEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: ITunaMoogEffect,
) => {
  const node = new (ctx as any).tuna.MoogFilter({
    ...effect,
  });
  return node;
};

const createTunaTremoloEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: ITunaTremoloEffect,
) => {
  const node = new (ctx as any).tuna.Tremolo({
    ...effect,
  });
  return node;
};

const createTunaOverdriveEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: ITunaOverdriveEffect,
) => {
  const node = new (ctx as any).tuna.Tremolo({
    ...effect,
  });
  return node;
};

const createTunaConvolverEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: ITunaConvolverEffect,
) => {
  const node = new (ctx as any).tuna.Convolver({
    ...effect,
  });
  return node;
};

const createTunaCabinetEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: ITunaCabinetEffect,
) => {
  const node = new (ctx as any).tuna.Cabinet({
    ...effect,
  });
  return node;
};

const createPizzicatoDistortionEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: IPizzicatoDistortionEffect,
) => {
  //@ts-ignore
  Pizzicato.context = ctx;
  const node = new Pizzicato.Effects.Distortion({
    ...effect,
  });
  return node;
};

const createPizzicatoQuadrafuzzEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: IPizzicatoQuadrafuzzEffect,
) => {
  //@ts-ignore
  Pizzicato.context = ctx;
  const node = new Pizzicato.Effects.Quadrafuzz({
    ...effect,
  });
  return node;
};

const createPizzicatoReverbEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: IPizzicatoReverbEffect,
) => {
  //@ts-ignore
  Pizzicato.context = ctx;
  const node = new Pizzicato.Effects.Reverb({
    ...effect,
  });
  return node;
};

const createPizzicatoHighPassFilterEffect = (
  ctx: AudioContext | OfflineAudioContext,
  effect: IPizzicatoHighPassFilterEffect,
) => {
  //@ts-ignore
  Pizzicato.context = ctx;
  const node = new Pizzicato.Effects.HighPassFilter({
    ...effect,
  });
  return node;
};
