import React, { useEffect } from 'react';
import { useStores } from 'store';
import { isMobile } from 'util/mobile';

import { AppDesktop } from './AppDesktop';
import { AppMobile } from './AppMobile';

export const AppPage = () => {
  const mobile = isMobile();
  const { userStore } = useStores();

  useEffect(() => {
    userStore.getUserInfo();
  }, []);

  if (mobile) {
    return <AppMobile />;
  }
  return <AppDesktop />;
};
