import React, { useState } from 'react';
import {
  Person as LoginIcon,
  SwitchVideo,
  Upload as UploadIcon,
} from '@mui/icons-material';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { isNil } from 'lodash-es';
import { observer } from 'mobx-react';
import { useStores } from 'store';
import styled from 'styled-components';

import HeaderCircle from 'asset/header/circle.svg';
import HeaderExport from 'asset/header/export.svg';
import HeaderMenu from 'asset/header/menu.svg';
import SaveIcon from 'asset/header/save.svg';
import HeaderShare from 'asset/header/share.svg';
import LogoImage from 'asset/logo.svg';

import { getCookie } from 'util/cookie';
import { request } from 'util/http-client';
import { isMobile } from 'util/mobile';
import { isTestMode } from 'util/testmode';
import { SaveModal } from 'component/popup';
import { uiHistory, uiStore } from 'store/ui';
import { getLanguageData } from 'constant/language';
import { constLayout } from 'constant/layout';
import { HeaderIcon, ShareButton, YoutubeUploadButton } from './buttons';

const lang = getLanguageData();
const IS_MOBILE = isMobile();

interface HeaderProps {}

/** 앱 타이틀, 로고를 표시하는 공간 */
function AppTitleArea() {
  return (
    <AppTitle
      onClick={() => (window.location.href = 'https://surroundio.org/')}
    >
      <img src={LogoImage} />
    </AppTitle>
  );
}

/** 메뉴를 표시하는 모달창 */
function MenuModal() {
  const { userStore } = useStores();
  const isLoggedIn = userStore.user != null;

  return (
    <MenuModalDiv>
      {isLoggedIn ? (
        <>
          <MenuModalDivSubName>
            <div style={{ color: '#D1D1D1', fontWeight: 600 }}>
              {userStore.user?.name}
            </div>
            <div style={{ color: '#777', fontWeight: 400 }}>
              {userStore.user?.membertype}
            </div>
          </MenuModalDivSubName>
          <MenuModalDivSubText>{lang.MYSIDE_PURCHASE}</MenuModalDivSubText>
          <MenuModalDivSubText>{lang.MYSIDE_LOGOUT}</MenuModalDivSubText>
        </>
      ) : (
        <MenuModalDivSubText>{lang.MYSIDE_LOGIN}</MenuModalDivSubText>
      )}
    </MenuModalDiv>
  );
}

export const Header = observer(({}: HeaderProps) => {
  const { videoStore, timelineStore, userStore, uiLoading, uiToast } =
    useStores();
  const [saving, setSaving] = useState(false);

  /** 메뉴를 열었는지, 닫았는지에 대한 여부 */
  const [menuOpen, setMenuOpen] = useState(false);

  /** 메뉴 오픈을 변경합니다. */
  const changeMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  /** ??? */
  const onUploadVideo = async () => {
    if (!!videoStore.video) {
      if (
        !confirm('Do you want to stash current changes and import new video?')
      ) {
        return;
      }
    }
    await videoStore.importVideo();
  };

  /** ??? */
  const onSaveVideo = async () => {
    await videoStore.exportVideo();
  };

  /** 클라우드에 비디오를 저장 */
  const onShareToCloudVideo = async () => {
    if (videoStore.mixer == null || !videoStore.loaded) {
      alert('please import video.');
      return;
    }

    if (window.location.href.includes('web.app')) {
      onSaveVideo();
      return;
    }

    uiLoading.showLoading();

    try {
      const memberId = getCookie('SU_TOKEN');

      // 멤버 ID가 없거나 잘못되었다면 이 기능을 실행하지 않고 알림을 추가함
      if (!memberId) {
        alert('User Auth Invalid! Try Again Please.');
        // window.location.href = 'https://surroundio.org';
        return;
      }

      const { fileId } = await videoStore.uploadVideo();

      if (isMobile()) {
        const url = `https://${window.location.host}/video/${fileId}`;
        uiToast.showLoading(url);

        return;
      }

      const uploadedS3VideoUrl = `${process.env.REACT_APP_CF_ENDPOINT}/videos/${fileId}.mp4`;
      const thumbnailUrl = `${process.env.REACT_APP_CF_ENDPOINT}/thumbnails/${fileId}.png`;

      const data = await request('POST', '/history/register', {
        videoUrl: uploadedS3VideoUrl,
        title: videoStore.video.name,
        description: timelineStore.effects[0]?.effect?.name ?? '',
        bgm: timelineStore.backgroundSounds[0]?.name ?? '',
        data: JSON.stringify(timelineStore.save()),
        thumbnailUrl,
      });

      if (data.success) {
        const url = `https://${window.location.host}/video/${fileId}`;
        uiToast.showLoading(url);

        //window.open(`http://${window.location.host}/video/${fileId}`, "_blank");

        alert('Upload completed. Please check your Cloud');
      }
    } catch (error) {
      alert('A temporary error has occurred.');
      //window.location.reload();
    } finally {
      uiLoading.hideLoading();
    }
  };

  /** 영상 편집 상태일때 표시되어야 하는 버튼을 출력하는 컴포넌트 */
  function HeaderButtonAreaMovie() {
    // 참고: ShareButton의 스타일은 HeaderButton과 일치하도록 변경되었습니다.
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        &nbsp;&nbsp;
        <ShareButton />
        &nbsp;&nbsp;
        <HeaderButton onClick={onShareToCloudVideo}>
          <img width={20} height={20} src={HeaderExport} />
        </HeaderButton>
        &nbsp;&nbsp;
      </div>
    );
  }

  function HeaderArea() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <AppTitleArea />
        <IconContainer>
          <HeaderButtonAreaMovie />
          <IconContainerMenuButton onClick={changeMenuOpen}>
            <img src={HeaderMenu} alt="" />
            <img src={HeaderCircle} alt="" />
            <span style={{ position: 'absolute', left: '60%' }}>J</span>
          </IconContainerMenuButton>
        </IconContainer>

        {menuOpen && <MenuModal />}
      </div>
    );
  }

  return (
    <Container>
      <SAppBar>
        <HeaderArea />
        <SaveModal show={saving} onClose={() => setSaving(false)} />
      </SAppBar>
    </Container>
  );
});

const Container = styled(Box).attrs({
  sx: { flexGrow: 1 },
})`
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiToolbar-root {
    height: 100%;
  }
`;

// height는 고정 크기를 가지게 하기 위해 px 단위로 변경했습니다.
const SAppBar = styled(AppBar).attrs({
  position: 'static',
})`
  height: ${IS_MOBILE
    ? constLayout.MOBILE_HEADER_HEIGHT
    : constLayout.PC_HEADER_HEIGHT};
  ${!IS_MOBILE && 'padding: 20px 40px 20px 40px'};
  background-color: #101011;
`;

const AppTitle = styled(Typography).attrs({
  component: 'div',
})`
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;

  align-items: center;

  // PC버전은 양 끝에 배치하기 위해, width: 100%가 추가되어있습니다.
  // 만약 width가 삭제되면, 이 컨테이너가 공간을 차지하지 않아서 중앙에 배치됩니다.
  ${!IS_MOBILE && 'width: 100%'};
  height: 100%;
  justify-content: end;
`;

// 여기서 margin을 넣은 것은, 각 div 사이의 공간을 띄우기 위해서입니다.
const IconContainerMenuButton = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Default-Gray-100, #eee);
  background: #2e2e2e;
  margin: 4px;
`;

const HeaderButton = styled.div`
  display: inline-flex;
  height: 40px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  cursor: pointer;
  border-radius: 8px;
  background: linear-gradient(104deg, #d63cab -4.93%, #6a6cfc 110.7%);
`;

const MenuModalDiv = styled.div`
  position: fixed;
  right: 4%;
  top: 64px;
  display: inline-flex;
  height: 183px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #eee;
  background: #2e2e2e;

  /* shadow */
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(48.722511291503906px);

  z-index: 100;
`;

const MenuModalDivSubName = styled.div`
  display: flex;
  width: 203px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const MenuModalDivSubText = styled.div`
  display: inline-flex;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;

  color: #d1d1d1;

  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
  text-transform: capitalize;
`;
