export const downloadURI = async (uri: string, file: File, data: any) => {
  const form = new FormData();
  data.append('file', file);
  data.append('nodes', '[]');

  return await fetch(uri, {
    method: 'POST',
    body: form,
  })
    .then(response => response.blob())
    .then(response => {
      const blob = new Blob([response], {
        type: 'video/mp4',
      });
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = 'file.mp4';
      document.body.appendChild(a);
      a.click();
    });
};

export const downloadFileUrl = async (uri: string, filename = 'file.mp4') => {
  var link = document.createElement('a');
  // If you don't know the name or want to use
  // the webserver default set name = ''
  link.setAttribute('download', filename);
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
  return;

  return await fetch(uri, {
    method: 'GET',
  })
    .then(response => response.blob())
    .then(response => {
      const blob = new Blob([response], {
        type: 'video/mp4',
      });

      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    });
};
