import React, { useEffect } from 'react';
import { Box, Modal } from '@mui/material';
import { observer } from 'mobx-react';
import { useStores } from 'store';
import styled from 'styled-components';

import FacebookIcon from 'asset/header/facebook.svg';
import KakaoIcon from 'asset/header/kakao.png';
import TwitterIcon from 'asset/header/twitter.svg';
import WhatsAppIcon from 'asset/header/whatsapp.png';

interface ShareModalProps {
  show: boolean;
  onClose: () => void;
}
export const ShareModal = observer(({ show, onClose }: ShareModalProps) => {
  const { videoStore, uiLoading } = useStores();
  const isPlayerPage = window.location.href.includes('/video/');

  const getURL = async () => {
    if (isPlayerPage) {
      return { player: window.location.href, thumbnail: '' };
    }

    uiLoading.showLoading();

    try {
      const { fileId, thumbnail } = await videoStore.uploadVideo();

      return {
        player: `https://${window.location.host}/video/${fileId}`,
        thumbnail,
      };
    } finally {
      uiLoading.hideLoading();
    }
  };

  const onClickWhatsApp = async () => {
    onClose();

    const { player: url } = await getURL();
    window.open(`https://wa.me/?text=${encodeURI(url)}`, '_blank');
  };

  const onClickTwitter = async () => {
    onClose();

    const { player: url } = await getURL();
    window.open(
      `https://twitter.com/intent/tweet?text=${encodeURI(url)}`,
      '_blank',
    );
  };

  const onClickKakao = async () => {
    onClose();

    const { player: url, thumbnail } = await getURL();

    Kakao.Share.sendDefault({
      objectType: 'feed',
      content: {
        title: 'Surroundio',
        imageUrl: thumbnail,
        link: {
          mobileWebUrl: url,
          webUrl: url,
        },
      },
      buttons: [
        {
          title: 'Play video',
          link: {
            mobileWebUrl: url,
            webUrl: url,
          },
        },
      ],
    });
  };

  const onClickFacebook = async () => {
    onClose();

    const { player: url } = await getURL();
    FB.ui(
      {
        method: 'share',
        href: url,
      },
      () => {},
    );
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Container>
        <Title>Share</Title>
        <Buttons>
          <ShareButton onClick={onClickTwitter}>
            <ShareIcon src={TwitterIcon} />
            Twitter
          </ShareButton>
          <ShareButton onClick={onClickKakao}>
            <ShareIcon src={KakaoIcon} />
            KakaoTalk
          </ShareButton>
          <ShareButton onClick={onClickWhatsApp}>
            <ShareIcon src={WhatsAppIcon} />
            WhatsApp
          </ShareButton>
          <ShareButton onClick={onClickFacebook}>
            <ShareIcon src={FacebookIcon} />
            Facebook
          </ShareButton>
        </Buttons>
      </Container>
    </Modal>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 390px;
  max-width: 90vw;

  align-items: center;

  padding: 20px 20px;

  background: white;
  border-radius: 8px;

  overflow: hidden;
`;

const Title = styled.div``;

const Buttons = styled.div`
  display: flex;

  gap: 60px;

  margin-top: 18px;
`;

const ShareButton = styled.div`
  display: flex;
  flex-direction: column;

  gap: 6px;

  align-items: center;

  font-size: 11px;
  font-weight: bold;
  color: #1a1a1acc;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
const ShareIcon = styled.img`
  width: 46px;
  height: 49px;

  border-radius: 12px;

  overflow: hidden;
`;
