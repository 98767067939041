import { debounce } from 'lodash-es';

import { finalGain } from './SurroundSound';
import { createEffect } from './typed-webaudio';
import { IEffect } from './types';
import { IBackgroundSound } from 'model';

/**
 * 이 클래스의 기능
 *
 * 1. 오디오의 이펙트들을 연결하고 적용해 오디오 효과를 적용할 수 있습니다.
 * 2. 오프라인 작업을 이용하여 오디오 결과를 얻을 수 있습니다.
 * 3. 아날라이저 노드(analyserOriginal, analyserEffect)를 이용하여
 * 이펙트가 적용된 오디오 파형과 원본 오디오 파형을 얻어 비교할 수 있습니다.
 *
 * update (2024-05-22)
 * 
 * mainAudioGain 추가로 이제 해당 비디오(또는 오디오)의 볼륨을 조절할 수 있습니다.
 * 
 * @version 2024-05-22
 */
export class MixAudio {
  /** 해당 클래스에서 직접적으로 사용하는 오디오 컨텍스트 */
  public audioContext: AudioContext;

  /** 해당 클래스에서 직접 연결되어 있는 오디오 또는 비디오 엘리먼트 */
  private mainAudio: HTMLAudioElement | HTMLVideoElement;

  /** 이 클래스가 사용하는 mainAudio의 audioContext의 destination (출력 지점) 또는 최종결과에 대한 노드 마지막 지점 */
  public destination: AudioDestinationNode | AudioNode;

  /** 입력할 데이터(오디오 트랙) */
  public inputs: IBackgroundSound[];

  /** inputs 데이터를 기준으로 만들어진 오디오 객체 */
  public inputAudios: HTMLMediaElement[] = [];

  /**
   * input 데이터를 기준으로 만들어진 오디오 노드. 이 노드는 inputAudioGains에 연결해야 합니다.
   *
   * 연결 예시: inputAudioNodes[i].connect(inputAudioGains[i])
   *
   * 참고: 이 노드를 집적 출력지점(audioContext.destination)에 연결하게되면, 볼륨 조절 기능을 사용할 수 없습니다.
   */
  public inputAudioSources: MediaElementAudioSourceNode[] = [];

  /**
   * input 데이터로 만들어진 오디오 노드의 게인 조절 용도. 이 노드를 출력 노드에 연결해야 합니다.
   *
   * 주의: 오디오 객체의 볼륨과 게인노드는 서로 다릅니다.
   */
  public inputAudioGainNodes: GainNode[] = [];

  /** 입력한 오디오의 페이드 관리용 게인 (페이드 효과는 이 게인으로만 구현합니다.) */
  public inputAudioFadeNodes: GainNode[] = [];

  /** 메인 오디오(또는 비디오)에 연결된 AudioSourceNode */
  public mainAudioNode: MediaElementAudioSourceNode;

  /** 메인 오디오(또는 비디오)에 적용되는 개별적 게인 (오디오 내의 볼륨과는 다른 개념입니다.) */
  public mainAudioGain: GainNode;

  /**
   * 메인 오디오의 경로(url)
   *
   * 경고: 비디오를 변경할 때마다 해당 비디오의 경로를 다시 설정해 주어야 합니다.
   * 안그러면 오프라인 작업(mixAudio) 할 때 잘못된 영상의 소리로 작업하게 됩니다.
   */
  public mainAudioUrl: string = '';

  /** 믹스된 최종 결과 버퍼(오프라인 작업 최종 결과) */
  public mixResultBuffer: AudioBuffer | null = null;

  /**
   * 오디오를 빠르게 불러오기 위한 캐시 오디오 버퍼
   *
   * 이미 기존에 오디오가 추가되어있다면, 클래스에 저장되어 있는 버퍼를 가져와 빠르게 작업을 처리할 수 있도록 합니다.
   */
  public cacheAudioBuffer: Map<string, AudioBuffer> = new Map();

  /** 이벤트 등록용 함수 */
  public onPlay: any;

  /** 이벤트 등록용 함수 */
  public onPause: any;

  /** 이벤트 등록용 함수 */
  public onInput: any;

  /** 이벤트 등록용 함수 */
  public onVolumeChange: any;

  /** @deprecated 기능 사용 보류 */
  public currentPlayBuffer: any;

  /**
   * 이펙트를 적용할 모든 노드를 여기에 저장합니다.
   * 이 노드들은 미리듣기 용도로 사용합니다.
   */
  public effectNodes: AudioNode[] = [];

  /**
   * 디스커넥트와 커넥트 작업을 최소화하기 위해서 이 노드를 통해 이전 노드와 구조가 같은지를 비교합니다.
   *
   * 이 변수가 추가된 이유는, 실시간 작업을 할 때, 불필요한 disconeect(디스커넥트), connect(연결)
   * 작업을 짧은 시간내에 계속 하게 될 경우, 소리에 잡음(처리지연으로 인한)이 끼는 현상이 발생하므로
   *
   * 이 문제를 수정하기 위해 추가했습니다.
   *
   * 이전 노드와 현재 노드가 같다면, connect 작업이 호출되어도 아무 변화가 없습니다.
   *
   * 이것으로, 소리에 잡음이 끼는 현상을 어느 정도 막을 수 있습니다.
   */
  public prevEffectNodes: AudioNode[] = [];

  /**
   * 이펙트 정보를 가진 데이터
   *
   * 이 데이터를 기반으로 오프라인, 미리듣기 작업이 진행됩니다.
   */
  public effects: IEffect[] = [];

  /** 현재 사용중인 이펙트 리스트 (실시간 처리에서 현재이펙트의 비교용도로 사용) */
  private effectsCurrent: IEffect[] = [];

  /**
   * 사용불가, 이제 backgroundData를 이용하여 직접적인 fade 시간을 조절합니다.
   * 
   * 페이드 시간(페이드 인, 페이드 아웃 시간 동일, 단위: 초), 기본값 3
   *
   * 음악 시간보다 높은 페이드 시간을 설정할경우 버그가 발생할 수 있음.
   * 음악 시간보다 2배 더 낮아야 정상적으로 적용됩니다.
   * 
   * @deprecated
   */
  public fadeTime: number = 3;

  /** mixAudio 클래스에서 사용하는 setInterval 함수의 id */
  private intervalId;

  /** 원본 오디오의 파형 분석용 아날라이저 노드 */
  public analyserOriginal: AnalyserNode;

  /** 이펙트가 적용된 오디오의 파형 분석용 아닐라이저 노드 */
  public analyserEffect: AnalyserNode;

  /**
   * 마스터 게인 (최종 출력 지점의 기준 볼륨 값)
   *
   * 이 객체가 가지고 있는 gain 변수의 value값을 변경하면, (이펙트 + 오디오 트랙을 적용한) 최종 오디오의 볼륨이 변경됩니다.
   *
   * 기본값: 1
   */
  public masterGain: GainNode;

  /**
   * not supported
   * 
   * 사용자가 임의로 추가한 오디오 트랙 (input와 별개)
   * 
   * @deprecated
   */
  public customAudio: HTMLAudioElement;

  /** 사용자가 임의로 추가한 오디오 트랙에 대한 오디오 노드 */
  public customAudioNode: MediaElementAudioSourceNode;

  /**
   * 메인 오디오의 음소거 여부 (커스텀 트랙을 추가할 때 이 옵션을 사용해서 메인 오디오를 음소거 할 수 있습니다.)
   */
  public isMainAudioMuted = false;

  /**
   * 워터마크의 경로 (워터마크가 누군지를 알아야 하므로, 해당 값을 지정해야 워터마크를 반복재생 할 수 있습니다.)
   *
   * 만약 워터마크의 경로가 /watermark.mp3 가 아니라면 이 값을 수동으로 수정해주셔야합니다.
   */
  public watermarkSrc = '/watermark.mp3';

  /** 로그 표시 여부 (false로 설정하면 로그가 표시되지 않음.) */
  public logView = true;

  /**
   * mixAudio 클래스
   * @param {AudioContext} context 오디오 컨텍스트
   * @param {HTMLAudioElement | HTMLVideoElement} mainAudio 오디오 또는 비디오 엘리먼트
   * @param {any} source ?
   * @param {AudioDestinationNode} destination 오디오 컨텍스트에 있는 destination (audioContext.destination을 뜻함.)
   * @param {IBackgroundSound[]} inputs 입력할 이펙트 데이터 (나중에 setInputs 로 수정 가능)
   */
  constructor(
    context: AudioContext,
    mainAudio: HTMLAudioElement | HTMLVideoElement,
    source: any, // <- ?
    destination: AudioDestinationNode | AudioNode,
    inputs: IBackgroundSound[] = [],
  ) {
    if (context == null || destination == null || mainAudio == null) {
      throw new Error(
        '오류: context 그리고 destination 그리고 audioElement 대한 정보가 없어 클래스를 생성할 수 없습니다.',
      );
    }

    // 생성자에서 받은 인수들을 저장
    this.audioContext = context;
    this.destination = destination;
    this.mainAudio = mainAudio;
    this.inputs = inputs;

    this.mainAudioNode = source;
    this.mainAudioUrl = mainAudio.src;
    this.mainAudioGain = this.audioContext.createGain();

    // 아날라이저 노드 추가
    this.analyserOriginal = this.audioContext.createAnalyser();
    this.analyserEffect = this.audioContext.createAnalyser();

    // 마스터 게인 추가
    this.masterGain = this.audioContext.createGain();

    // 커스텀 오디오 트랙을 저장할 수 있는 변수 추가
    this.customAudio = new Audio();
    this.customAudioNode = this.audioContext.createMediaElementSource(
      this.customAudio,
    );

    // 이벤트 리스너 등록
    this.onPlay = this.play.bind(this);
    this.onPause = this.pause.bind(this);
    this.onInput = this.timeChange.bind(this);
    this.onVolumeChange = debounce(this.volumeChange.bind(this), 150);

    this.mainAudio.addEventListener('play', this.onPlay);
    this.mainAudio.addEventListener('pause', this.onPause);
    this.mainAudio.addEventListener('input', this.onInput);
    this.mainAudio.addEventListener('volumechange', this.onVolumeChange);

    // 미리 오디오 버퍼 객체를 얻어옵니다. (promise를 미리 처리하기 위함.)
    this.getCacheAudioBuffer(mainAudio.src);

    // 이펙트 연결 (이것은 메인 오디오를 출력 지점에 연결하기 위한 것입니다.)
    // 경고: 이 함수는 생성자에서 무조건 호출되므로, 이 함수를 사용하기 전에 연결할 모든 노드들을 초기화해주세요.
    this.connectEffect(true);

    // 실시간 처리를 위한 함수 추가
    let intervalFunction = this.intervalFunction.bind(this);
    this.intervalId = setInterval(intervalFunction, 100);

    // 아이폰 모바일을 위한 터치 이벤트 (오디오 컨텍스트 강제 활성화)
    addEventListener('touchstart', () => {
      this.audioContextResume();
    });

    console.log('mixAudio의 로그를 확인하려면, mixAudio 멤버변수의 logView를 true로 변경하세요.')
  }

  /**
   * 오디오 컨텍스트의 상태를 running 상태로 변경합니다.
   *
   * 경고: 이 함수는 반드시 사용자와의 상호 작용을 가정하는 이벤트(touchstart, keydown)
   * 을 사용한 후 호출하여 주십시오. 다른 경우의 수는 인식하지 않을 수 있습니다.
   *
   * 아이폰의 경우, 반드시 touchstart 또는 touchend 이벤트를 사용한 후에 이 함수를 호출해야합니다.
   * 예를 들어
   * addEventlistener('touchstart', () => {audioContext.resume()})
   * 이 형태로 작성하세요.
   */
  audioContextResume() {
    if (this.audioContext.state !== 'running') {
      this.audioContext.resume();
    }
  }

  /**
   * 오디오 해제 함수? (용도를 모르겠음.)
   * (다만, 이 함수를 사용하기 보다는 해당 객체를 null로 만들고, 새 객체를 생성하는것이 좋습니다.)
   */
  close() {
    //@ts-ignore
    finalGain?.disconnect();
    this.disconnectEffect(); // 모든 연결된 이펙트 해제 (mainAudio 포함)

    this.mainAudio.removeEventListener('play', this.onPlay);
    this.mainAudio.removeEventListener('pause', this.onPause);
    this.mainAudio.removeEventListener('input', this.onInput);
    this.mainAudio.removeEventListener('volumechange', this.onVolumeChange);

    clearInterval(this.intervalId);
  }

  /**
   * 이전 이펙트와 현재 이펙트가 같은지를 확인합니다.
   *
   * 다를경우, 이펙트는 새로운 이펙트로 교체됩니다. (이 함수를 사용할때마다 교체됩니다.)
   */
  prevEffectSameCheck(targetEffectNodes: AudioNode[]) {
    // 현재 배열의 길이와 이전 배열의 길이가 같은지를 먼저 확인
    // 다를 경우, 서로의 이펙트는 다른것으로 취급
    let isSame = false; // 기본값은 일단 false로 지정

    if (targetEffectNodes.length === this.prevEffectNodes.length) {
      isSame = true; // 배열길이가 같으면 true로 초기화

      // 같을 경우, 각 모든 배열을 조사해서 같은지를 전부 비교
      for (let i = 0; i < targetEffectNodes.length; i++) {
        if (targetEffectNodes[i] !== this.prevEffectNodes[i]) {
          // 배열 내의 객체가 서로 다르면 같지 않기 때문에 false
          isSame = false;
          break;
        }
      }

      // 전부 같다면 isSame이 true값을 유지하게 됨.
    }

    if (isSame) {
      return true;
    } else {
      // 배열이 서로 같지 않다면, prevEffectNodes는 새로운것으로 교체되고, 함수에서 false를 리턴합니다.
      this.prevEffectNodes = targetEffectNodes;
      return false;
    }
  }

  /**
   * 지금까지 입력된 모든 이펙트를 다시 연결합니다.
   *
   * 참고: 이 함수는 모든 연결을 해제한 이후 처음부터 다시 연결합니다. (그래야 기존에 있는 노드에게 영향을 받지 않음.)
   *
   * setInput를 호출해도, 해당 함수를 실행하여, 다시 연결합니다.
   *
   * @param {boolean} permission 퍼미션: 비교 작업을 무시하고 강제로 디스커넥트 및 연결작업을 수행합니다.
   * 처음 실행했을 때, 음악 재생시 오디오 컨텍스트에 강제로 연결하기 위한 변수값입니다.
   *
   * 실시간 처리에 퍼미션 옵션을 넣으면 안됩니다.
   */
  connectEffect(permission: boolean = false) {
    // 현재 시간값에 맞는 이펙트를 따로 모아서 새 변수에 넣습니다.
    let currentEffects = [];
    let currentEffectNodes = [];
    let audioTime = this.mainAudio.currentTime;
    for (let i = 0; i < this.effects.length; i++) {
      let start = this.effects[i].start;
      let end = this.effects[i].end;
      if (start == null || end == null) break;

      if (audioTime >= start && audioTime < end) {
        currentEffects.push(this.effects[i]);
        currentEffectNodes.push(this.effectNodes[i]);
      }
    }

    // 퍼미션이 true인경우 이전 이펙트가 같은지의 비교를 하지 않고 강제로 연결 해제 및 연결 작업을 수행
    // 이전 이펙트와 이후 이펙트가 같은지를 비교해서 같다면 연결 작업을 처리하지 않습니다.
    if (!permission && this.prevEffectSameCheck(currentEffectNodes)) return;

    // 강제 수행인경우, prev 이펙트를 현재 이펙트로 강제로 덮어씌웁니다.
    if (permission) {
      this.prevEffectNodes = currentEffectNodes;
    }

    // 모든 이펙트를 연결 해제 후 다시 연결해야 합니다.
    this.disconnectEffect();

    // 원본 오디오에 대해 아날라이저 노드 추가 연결
    // 이펙트가 적용된 아날라이저는 밑에 이펙트를 연결하면서 추가적으로 연결됩니다. (이펙트가 없으면 연결되지 않음)
    this.mainAudioNode.connect(this.analyserOriginal);
    this.customAudioNode.connect(this.analyserOriginal);

    // 메인 오디오를 메인 오디오게인에 연결
    this.mainAudioNode.connect(this.mainAudioGain);

    // 연결 순서
    // mainAudio -> mainAudioGain -> effect -> masterGain
    // inputAudio -> inputAudioGain -> inputAudioFade -> masterGain (inputAudioGain을 거치치 않는다면 볼륨 조절 불가능)
    // effect -> effect -> masterGain
    // mainAudio, effect 두개는 합쳐서 연결할 수 없습니다.
    // masterGain -> destination

    if (currentEffectNodes.length === 0) {
      // 아무 이펙트 노드도 연결되어있지 않을경우, 모든 오디오 노드는 마스터 게인으로 연결됩니다.
      // 오디오 연결을 한곳으로 모으기 위해 임시 게인을 생성했습니다.
      let tempGain = this.audioContext.createGain(); // 임시 게인
      this.mainAudioGain.connect(tempGain);
      this.customAudioNode.connect(tempGain);
      for (let i = 0; i < this.inputAudioGainNodes.length; i++) {
        this.inputAudioSources[i].connect(this.inputAudioGainNodes[i]);
        this.inputAudioGainNodes[i].connect(this.inputAudioFadeNodes[i]);
        this.inputAudioFadeNodes[i].connect(tempGain);
      }
      tempGain.connect(this.masterGain);
    } else if (currentEffectNodes.length === 1) {
      // 이펙트가 단 1개만 있을 경우
      this.mainAudioGain.connect(currentEffectNodes[0]);
      this.customAudioNode.connect(currentEffectNodes[0]);
      for (let i = 0; i < this.inputAudioGainNodes.length; i++) {
        this.inputAudioSources[i].connect(this.inputAudioGainNodes[i]);
        this.inputAudioGainNodes[i].connect(this.inputAudioFadeNodes[i]);
        this.inputAudioFadeNodes[i].connect(currentEffectNodes[0]);
      }

      // 이미 effectNodes[0]에 모든 오디오 노드가 연결이 되어있으므로, 이 노드만 마스터 게인에 연결하면 됩니다.
      currentEffectNodes[0].connect(this.masterGain);
    } else if (currentEffectNodes.length >= 2) {
      // 이펙트 2개 이상
      // 먼저, 메인 오디오와 입력된 버퍼를 이펙트 노드에 연결
      this.mainAudioGain.connect(currentEffectNodes[0]);
      this.customAudioNode.connect(currentEffectNodes[0]);
      for (let i = 0; i < this.inputAudioGainNodes.length; i++) {
        this.inputAudioSources[i].connect(this.inputAudioGainNodes[i]);
        this.inputAudioGainNodes[i].connect(this.inputAudioFadeNodes[i]);
        this.inputAudioFadeNodes[i].connect(currentEffectNodes[0]);
      }

      // 이펙트 처음부터 끝까지 연결
      // 첫번째 노드부터 마지막 노드까지 연결합니다. (그래서 i 초기값이 1)
      for (let i = 1; i < currentEffectNodes.length; i++) {
        currentEffectNodes[i - 1].connect(currentEffectNodes[i]);
      }

      // 마지막 노드 연결
      currentEffectNodes[currentEffectNodes.length - 1].connect(
        this.masterGain,
      );
    }

    // 모든 연결이 끝난 후 최종 출력지점에 연결
    this.masterGain.connect(this.audioContext.destination);

    // 최종 게인은 모든 이펙트가 적용된 값과 같으므로, analyserEffect에도 연결
    this.masterGain.connect(this.analyserEffect);

    if (this.logView) console.log('mixAudio -> connectEffect: disconnect and reconnect complete');
  }

  /**
   * 지금까지 연결되었던 모든 노드를 해제합니다.
   *
   * 참고: connectEffect 함수를 호출해도 이 함수가 같이 호출됩니다.
   * 그러므로, 이 함수를 독단적으로 사용할 일은 아마 없을 것.
   */
  disconnectEffect() {
    this.analyserEffect.disconnect();
    this.analyserOriginal.disconnect();
    this.masterGain.disconnect();
    this.mainAudioNode.disconnect();
    this.mainAudioGain.disconnect();
    this.customAudioNode.disconnect();

    for (let i = 0; i < this.inputAudioSources.length; i++) {
      this.inputAudioSources[i].disconnect();
      this.inputAudioFadeNodes[i].disconnect();
      this.inputAudioGainNodes[i].disconnect();
    }

    for (let i = 0; i < this.effectNodes.length; i++) {
      this.effectNodes[i].disconnect();
    }
  }

  /**
   * 파일 경로를 입력하여 캐시된 버퍼를 얻어옵니다. 캐시된 버퍼를 얻어오지 못했다면 오디오 버퍼로 다운로드 합니다.
   */
  async getCacheAudioBuffer(url: string) {
    if (this.cacheAudioBuffer.has(url)) {
      return this.cacheAudioBuffer.get(url);
    } else {
      let getArrayBuffer = await (await fetch(url)).arrayBuffer();
      let decodeBuffer = await this.audioContext.decodeAudioData(
        getArrayBuffer,
      );
      this.cacheAudioBuffer.set(url, decodeBuffer);
      return this.cacheAudioBuffer.get(url);
    }
  }

  /**
   * 현재까지 입력된 inputs와 effects를 통해 mixAudio한 결과물을 오디오 버퍼로 저장하고 결과값을 리턴합니다.
   * 이 함수를 실행한 결과는 이 클래스의 resultBuffer에 저장됩니다.
   *
   * 참고: setInputs를 설정하거나 setEffect를 설정하면, 이펙트 설정이 자동으로 반영되므로,
   * 오프라인 작업 또는 버퍼를 얻기 위한 목적이 아니라면 이 함수를 실행할 필요가 없습니다.
   * 그래서 미리듣기 목적으로 이 함수를 사용하는것은 추천하지 않습니다. 매 렌더링 마다 상당한 시간이 필요합니다.
   *
   * 참고: mixAudio를 실행한 이후, 이펙트를 변경하게되면, 다시 mixAudio를 해야 결과 버퍼에 제대로 반영이 됩니다.
   * 
   * @param [timeLengthSec=undefined] 시간 길이, 이 값을 설정하게 되면, 해당 시간 값 만큼만 버퍼를 만듭니다.
   */
  async mixAudio(timeLengthSec: number | undefined = undefined) {
    // 버퍼를 만들 오프라인 오디오 컨텍스트 생성
    const sampleRate = this.audioContext!.sampleRate;
    const channel = 2;

    // 시간 길이가 지정되면, 해당 시간 길이만큼만 지정함
    const audioLength = timeLengthSec === undefined ? this.mainAudio.duration * sampleRate : timeLengthSec * sampleRate;
    let offlineAudioContext = new OfflineAudioContext({
      sampleRate: sampleRate,
      numberOfChannels: channel,
      length: audioLength,
    });

    /** 오프라인 전용 마스터 게인 */
    let masterOfflineGain = offlineAudioContext.createGain();
    masterOfflineGain.gain.value = this.masterGain.gain.value;

    /** 오프라인 전용 메인 오디오 게인 */
    let mainAudioGain = offlineAudioContext.createGain();
    mainAudioGain.gain.value = this.mainAudioGain.gain.value;

    /** 렌더링 시작 시간 */
    const startRenderingTime = performance.now();

    /** 시간 길이에 대한 콘솔용 로그 출력 테스트 출력 (단, 없을경우 출력되지 않음.) */
    const timeLengthText = timeLengthSec ? '(buffer: ' + timeLengthSec + 'sec) ' : ''
    if (this.logView) console.log('mixAudio.mixAudio ' + timeLengthText + '-> offline job start');

    /** 오프라인 컨텍스트에서 사용할 오디오 버퍼 (음소거 되어있을경우(엘리먼트 음소거와 별개) 사용하지 않습니다.) */
    let mainBuffer = null;
    if (this.isMainAudioMuted) {
      mainBuffer = null;
    } else {
      mainBuffer = await this.getCacheAudioBuffer(this.mainAudioUrl);
    }

    let customBuffer = null;
    /** 커스텀 오디오의 경로가 있을경우, customBuffer를 추가함 */
    if (this.customAudio.src !== '') {
      customBuffer = await this.getCacheAudioBuffer(this.customAudio.src);
    }

    /**  inputs 데이터를 기준으로 만들어진 오디오 버퍼입니다. */
    let inputsBuffer: any = [];
    for (let i = 0; i < this.inputs.length; i++) {
      inputsBuffer.push(await this.getCacheAudioBuffer(this.inputs[i].url));
    }

    /** timeTable에서 같은 데이터들이 있는것을 압축한 테이블 */
    let indexTable = this.getIndexTable();

    /** 입력된 버퍼의 게인값(사용자가 지정한 볼륨 값 입력 용도) */
    let inputsBufferGain: GainNode[] = inputsBuffer.map(
      (value: GainNode, index: number) => {
        let gainNode = offlineAudioContext.createGain();
        gainNode.gain.value = this.inputs[index].volume;
        return gainNode;
      },
    );

    /** 입력된 버퍼의 페이드 조절 용도로 사용되는 게인 */
    let inputsFadeGain: GainNode[] = inputsBuffer.map(() => {
      return offlineAudioContext.createGain();
    });

    // 게인에 대한 처리
    // 이 코드는 오디오 시작 지점을 0초로 기준점을 정하고, 그에 따라 언제 게인의 값을 조정할 지 결정합니다.
    for (let i = 0; i < this.inputs.length; i++) {
      // 페이드 인 시작시간은 start를 사용하지만, 페이드 아웃 시작시간은 end - fadeTime을 사용해야 합니다.
      // 이것은, end가 음악의 끝지점이기 때문입니다.
      let fadeInStart = this.inputs[i].start;
      let fadeInEnd = this.inputs[i].start + this.inputs[i].fadeIn;
      let fadeOutStart = this.inputs[i].end - this.inputs[i].fadeOut;
      let fadeOutEnd = this.inputs[i].end;

      let currentGain = inputsFadeGain[i];

      // 시간은 마이너스 값이 있을 수 없습니다. 그리고 오프라인 컨텍스트의 시간은 0초에서 시작합니다.
      // 따라서 모든 게인에 페이드 인, 유지, 페이드 아웃 작업이 진행됩니다.

      // 페이드 인 작업
      currentGain.gain.setValueAtTime(0, fadeInStart);
      currentGain.gain.linearRampToValueAtTime(1, fadeInEnd);

      // 유지 작업
      currentGain.gain.setValueAtTime(1, fadeOutStart);

      // 페이드 아웃 작업
      currentGain.gain.linearRampToValueAtTime(0, fadeOutEnd);
    }

    // 이펙트 연결
    for (let i = 0; i < indexTable.length; i++) {
      let indexStart = indexTable[i].start;
      let indexEnd = indexTable[i].end;
      let duration = indexEnd - indexStart;

      let mainBufferSource = null;
      if (mainBuffer != null) {
        mainBufferSource = offlineAudioContext.createBufferSource();
        mainBufferSource.buffer = mainBuffer;
      }

      let customAudioBufferSource = null;
      if (customBuffer != null) {
        customAudioBufferSource = offlineAudioContext.createBufferSource();
        customAudioBufferSource.buffer = customBuffer;
      }

      let inputsBufferSource: AudioBufferSourceNode[] = inputsBuffer.map(
        (value: GainNode, index: number) => {
          let bufferSource = offlineAudioContext.createBufferSource();
          bufferSource.buffer = inputsBuffer[index];

          return bufferSource;
        },
      );

      let effectData = indexTable[i].data.map(effect => {
        return createEffect(offlineAudioContext, effect);
      });

      if (effectData.length === 0) {
        // 이펙트가 0개일경우, 바로 마스터게인(오프라인)에 연결
        // 여기서는 offLineContext로 작업해야 합니다.
        if (mainBufferSource != null) {
          mainBufferSource.connect(mainAudioGain);
          mainAudioGain.connect(masterOfflineGain);
        }
        if (customAudioBufferSource != null) {
          // (만약 있다면) 커스텀 트랙도 추가
          customAudioBufferSource.connect(masterOfflineGain);
        }

        inputsBufferSource.forEach((node, index) => {
          node.connect(inputsBufferGain[index]);
          inputsBufferGain[index].connect(inputsFadeGain[index]);
          inputsFadeGain[index].connect(masterOfflineGain);
        });
      } else if (effectData.length === 1) {
        if (mainBufferSource != null) {
          mainBufferSource.connect(mainAudioGain);
          mainAudioGain.connect(effectData[0]);
        }
        if (customAudioBufferSource != null) {
          customAudioBufferSource.connect(effectData[0]);
        }

        inputsBufferSource.forEach((node, index) => {
          node.connect(inputsBufferGain[index]);
          inputsBufferGain[index].connect(inputsFadeGain[index]);
          inputsFadeGain[index].connect(effectData[0]);
        });

        // 이미 이 이펙트에 여러 오디오 노드를 연결하였으므로, 이 이펙트 노드만 마스터게인(오프라인)에 연결하면 됩니다.
        effectData[0].connect(masterOfflineGain);
      } else if (effectData.length >= 2) {
        if (mainBufferSource != null) {
          mainBufferSource.connect(mainAudioGain);
          mainAudioGain.connect(effectData[0]);
        }
        if (customAudioBufferSource != null) {
          customAudioBufferSource.connect(effectData[0]);
        }

        inputsBufferSource.forEach((node, index) => {
          node.connect(inputsBufferGain[index]);
          inputsBufferGain[index].connect(inputsFadeGain[index]);
          inputsFadeGain[index].connect(effectData[0]);
        });

        // 반복문 안에서의 반복문이므로, i값을 사용해 또 반복문을 만들면 안됩니다.
        // 첫번째 이펙트부터 마지막 이펙트까지 연결합니다.
        for (let j = 1; j < effectData.length; j++) {
          effectData[j - 1].connect(effectData[j]);
        }

        // 마지막 이펙트는 마스터게인(오프라인)에 연결
        effectData[effectData.length - 1].connect(masterOfflineGain);
      }

      // 마스터 게인을 최종 출력 지점에 연결
      masterOfflineGain.connect(offlineAudioContext.destination);

      if (mainBufferSource != null) {
        mainBufferSource.start(indexStart, indexStart, duration);
      }

      if (customAudioBufferSource != null) {
        customAudioBufferSource.start(indexStart, indexStart, duration);
      }

      // inputs 데이터는 다른 방식으로 버퍼를 재생합니다.
      for (let i = 0; i < this.inputs.length; i++) {
        let inputStart = this.inputs[i].start;
        // offset은, 음악 내부 파일의 재생 위치입니다.
        let inputOffset = this.inputs[i].start - inputStart + this.inputs[i].rangeStart;
        let inputMaxDuration = indexEnd - this.inputs[i].start;
        let inputDuration = this.inputs[i].end - this.inputs[i].start;

        // 입력오프셋이 0 미만인경우 오디오 출력은 재생되지 않습니다.
        if (inputOffset < 0) continue;

        // 입력된 오디오가 현재 이펙트 길이의 최대 재생 지점를 초과할경우, 강제로 재생 시간 조정
        if (inputDuration > inputMaxDuration) {
          inputDuration = inputMaxDuration;
        }

        // 워터마크를 재생할때는 루프하도록 설정됩니다. (반복적인 워터마크 출력을 위해서)
        let isWarterMark = this.inputs[i].url === this.watermarkSrc;
        if (isWarterMark) {
          inputsBufferSource[i].loop = true;
        }

        inputsBufferSource[i].start(inputStart, inputOffset, inputDuration);
      }
    }

    if (this.logView) console.log('mixAudio.mixAudio -> startRendering... need much time...');

    /** 렌더링 완료된 버퍼 */
    const st = Date.now();
    let renderBuffer = await offlineAudioContext.startRendering();
    if (this.logView) console.log('offlineAudioContext.startRendering', Date.now() - st);

    /** 렌더링 끝 시간 */
    const endRenderingTime = performance.now();

    /** 총 렌더링 시간 */
    const totalRenderingTime = (endRenderingTime - startRenderingTime) / 1000;

    if (this.logView) {
      console.log(
        'mixAudio.mixAudio -> rendering complete. total time: ',
        totalRenderingTime,
      );
    }
    this.mixResultBuffer = renderBuffer;

    return renderBuffer;
  }

  /**
   * 각 시간(초) 마다 어떤 이펙트들이 적용될지를 결정하는 시간 테이블
   *
   * 이 함수는 mixAudio에서 사용하는 함수입니다.
   * 
   * @private
   * @param [timeLengthSec=undefined] 시간 길이, 이 값을 설정하게 되면, 해당 시간 값 만큼만 적용됨
   */
  getTimeTable(timeLengthSec: number | undefined = undefined) {
    let timeTable: IEffect[][] = Array.from(
      { length: Math.ceil(timeLengthSec ? timeLengthSec : this.mainAudio.duration) },
      () => [],
    );

    // 시간 테이블에 이펙트 정보를 추가합니다.
    for (let i = 0; i < this.effects.length; i++) {
      let start = this.effects[i].start;
      let end = this.effects[i].end;

      // 시작과 끝 값이 없다면 무시합니다.
      if (start == null || end == null) continue;

      for (let t = start; t < end; t++) {
        // 이펙트 시간 값이 타임테이블의 길이를 초과할경우, 배열 길이 초과 문제가 발생하므로, 이를 무시해야함.
        if (t >= timeTable.length) break;

        timeTable[t].push(this.effects[i]);
      }
    }

    return timeTable;
  }

  /**
   * timeTable에서 같은 데이터들이 있는것을 압축한 테이블
   *
   * 이 함수는 mixAudio에서 사용하는 함수입니다.
   * 그리고 이 함수 자체가 timeTable의 값을 얻어오기 때문에 getTimeTable 함수를 사용할 이유는 없습니다.
   * 
   * @private
   * @param [timeLengthSec=undefined] 시간 길이, 이 값을 설정하게 되면, 해당 시간 값 만큼만 적용됨
   */
  getIndexTable(timeLengthSec: number | undefined = undefined) {
    let indexTable = [];
    let timeTable = this.getTimeTable(timeLengthSec);

    // 인덱스테이블의 첫번째 데이터 입력
    indexTable.push({
      start: 0,
      end: timeTable.length,
      data: timeTable[0],
    });

    /** indexTable에서 이전 데이터랑 비교하는 용도로 사용하는 변수 */
    let prevIndex = indexTable[0].data;

    for (let i = 1; i < timeTable.length; i++) {
      // 이전 데이터의 배열 길이와 현재 데이터의 배열 길이가 같을경우,
      // 이전 데이터랑 현재 데이터가 같은지를 비교합니다.
      let isSame = true;
      if (prevIndex.length === timeTable[i].length) {
        let currentTimeTable = timeTable[i];
        for (let j = 0; j < currentTimeTable.length; j++) {
          if (prevIndex[j] !== currentTimeTable[j]) {
            isSame = false;
            break;
          }
        }
      } else {
        // 배열 길이가 다르다면 서로 다른것과 마찬가지
        isSame = false;
      }

      // 서로가 다르다면, indexTable에 현재 데이터를 추가합니다.
      if (!isSame) {
        // 이미 입력된 인덱스 테이블의 마지막 end 지점을 현재 위치로 변경합니다.
        indexTable[indexTable.length - 1].end = i;

        // 새로운 인덱스 테이블 추가
        indexTable.push({
          start: i,
          end: timeTable.length,
          data: timeTable[i],
        });

        // 이제 비교할 인덱스를 현재 인덱스로 교체합니다.
        prevIndex = timeTable[i];
      }
    }

    return indexTable;
  }

  /** mixAudio를 한 결과 버퍼 얻어오기 */
  getMixResultBuffer() {
    if (this.mixResultBuffer == null) {
      console.warn(
        'mixAudio: ',
        '믹스된 최종 결과 버퍼가 없습니다. mixAudio 함수를 먼저 사용해 주세요.',
      );
      return null;
    } else {
      return this.mixResultBuffer;
    }
  }

  /**
   * 더이상 사용되지 않습니다.
   * @deprecated
   */
  setUp() {
    // if (this.currentPlayBuffer != null) {
    //   //this.currentPlayBuffer.stop();
    //   this.currentPlayBuffer.disconnect();
    //   this.currentPlayBuffer = null;
    // }
    // let playBufferSource = this.audioContext.createBufferSource();
    // playBufferSource.buffer = this.mixResultBuffer;
    // playBufferSource.connect(finalGain);
    // finalGain.connect(this.audioContext.destination);
    // this.currentPlayBuffer = playBufferSource;
    // return playBufferSource;
  }

  /**
   * 오디오 또는 비디오를 재생합니다. (오프라인 작업은 해당하지 않음.)
   *
   * 이 함수에서는 inputAudios에 있는 오디오를 추가로 재생해서 여러 오디오를 동시에 재생하는 효과를 냅니다.
   *
   * interval process 만들어야, 여러 오디오 트랙을 재생할 수 있음. (미리 예약 불가능)
   */
  play() {
    if (this.mainAudio.paused) return;

    // 메인 오디오가 음소거 되어있는경우에 대한 처리
    if (this.isMainAudioMuted) {
      this.mainAudio.muted = true;
    } else {
      this.mainAudio.muted = false;
    }

    if (this.customAudio.src != '') {
      this.customAudio.play();
    }

    let audioTime = this.mainAudio.currentTime;
    for (let i = 0; i < this.inputs.length; i++) {
      let current = this.inputs[i];
      let currentAudio = this.inputAudios[i];
      let playStart = current.rangeStart
      let playEnd = current.rangeEnd
      const playDuration = playEnd - playStart
      const basePlayTime = audioTime - current.start + playStart

      // 시간 조건 확인 및 정해진 시간 값에 맞게 입력된 오디오 시간 조정
      if (audioTime >= current.start && audioTime < current.end && basePlayTime < playDuration) {
        currentAudio.currentTime = basePlayTime;
        currentAudio.play();
      } else {
        currentAudio.pause();
      }
    }

    // 페이드 효과를 가진 게인 노드 재설정
    this.fadeProcess();
  }

  /**
   * 오디오 또는 비디오를 일시정지합니다. (오프라인 작업은 해당하지 않음.)
   *
   * 이 함수에서는 inputAudios에 있는 오디오를 추가로 정지합니다.
   */
  pause() {
    this.customAudio.pause();
    for (let currentAudio of this.inputAudios) {
      currentAudio.pause();
    }
  }

  /**
   * 오디오 또는 비디오 볼륨을 변경합니다.
   *
   * inputAudios에도 영향을 줍니다.
   */
  volumeChange() {
    for (let current of this.inputAudios) {
      if (this.isMainAudioMuted) {
        current.volume = this.mainAudio.volume;
      } else {
        current.muted = this.mainAudio.muted;
        current.volume = this.mainAudio.volume;
      }
    }
  }

  /**
   * 재생 오디오의 시간 변경
   *
   * inputAudios에도 영향을 줍니다.
   */
  timeChange() {
    // play함수랑 코드가 동일한 것 같지만, 여기서는 음악을 재생하는것이 아닌 시간만 조정합니다.
    let audioTime = this.mainAudio.currentTime;

    // 커스텀 오디오의 시간은 fadeProcess 함수에서 처리합니다.
    // timeChange 함수에서 처리할경우, 커스텀 오디오의 재생 위치가 맞지 않는 문제가 있습니다.
    // 원인은 잘 모르겠습니다.
    // this.customAudio.currentTime = audioTime; // 이것은 fadeProcess에서 처리됨.

    for (let i = 0; i < this.inputs.length; i++) {
      let current = this.inputs[i];
      let currentAudio = this.inputAudios[i];
      const playDuration = current.rangeEnd - current.rangeStart
      const basePlayTime = audioTime - current.start + current.rangeStart

      // 시간 조건 확인 및 정해진 시간 값에 맞게 입력된 오디오 시간 조정
      if (audioTime >= current.start && audioTime < current.end) {
        currentAudio.currentTime = basePlayTime;
      }
    }

    // 페이드 효과를 가진 게인 노드 재설정
    this.fadeProcess();
  }

  /**
   * inputs를 재설정합니다. 설정한 후에 mixAudio를 해야 호출 최종 출력 결과가 반영됩니다.
   *
   * 만약, inputs의 일부를 바꾸고 싶다면, getInputs를 이용해 inputs를 가져오고 그 값을 수정한 후
   * 다시 setInputs 함수를 사용해 수정된 inputs값을 넣어주시기 바랍니다.
   *
   * inputs를 지우고 싶다면, 빈 배열을 넣어주세요.
   *
   * (가능하다면, setInputs, getInputs를 사용해주세요. inputs의 직접 수정은 권장하지 않습니다.)
   */
  setInputs(inputs: IBackgroundSound[] = []) {
    if (inputs == null) return;

    if (this.logView) console.log('mixAudio.setInputs: ', inputs);
    this.inputs = inputs;

    // 재생중이였던 입력된 모든 오디오를 우선 정지
    for (let current of this.inputAudios) {
      current.pause();
    }

    // 모든 연결 일시 해제
    // 이 연결을 해제하지 않고 inputAudio를 초기화할 경우, 오디오 메모리 누수 문제가 발생합니다.
    this.disconnectEffect();

    // 그 후 (배열의 길이를 0으로 하는것으로) 입력 오디오 전체 삭제
    // 오디오 노드 또한 삭제, 오디오 게인 또한 삭제
    this.inputAudios.length = 0;
    this.inputAudioSources.length = 0;
    this.inputAudioGainNodes.length = 0;
    this.inputAudioFadeNodes.length = 0;

    for (let current of inputs) {
      let audio = new Audio(current.url);
      let audioNode = this.audioContext.createMediaElementSource(audio);
      audio.crossOrigin = 'ananymous'; // 크로스 오리진을 익명으로 설정해서 외부 사이트에서 음악을 사용할 수 있도록 합니다.
      let gainNode = this.audioContext.createGain();
      gainNode.gain.value = current.volume;
      let fadeNode = this.audioContext.createGain();
      fadeNode.gain.value = 1;

      // 워터마크는 루프를 적용시킵니다.
      if (current.url === this.watermarkSrc) {
        audio.loop = true;
      }

      // 참고, 게인노드는 나중에 connectEffect 함수에서 직접 연결합니다.
      // 나중에 연결노드를 끊고 재연결하기 때문에, 여기서 게인 노드를 연결하는것은 아무 의미가 없습니다.
      this.inputAudios.push(audio);
      this.inputAudioSources.push(audioNode);
      this.inputAudioGainNodes.push(gainNode);
      this.inputAudioFadeNodes.push(fadeNode);
    }

    // 새 입력을 할 때마다 강제로 연결 작업을 수행합니다.
    this.connectEffect(true);
    this.fadeProcess(); // 음악을 입력할 때, 페이드 작업도 수행함.
  }

  getInputs() {
    return this.inputs;
  }

  /**
   * inputs에 새로운 inputs를 추가합니다.(setInputs이랑 다름)
   *
   * 해당 함수는 사용 불가, 정확한 입력 결과를 보장받을 수 없음. 대신 setInputs를 사용해주세요.
   *
   * 조만간 해당 함수 삭제 예정
   * @deprecated
   */
  addInputs(inputs: IBackgroundSound[] = []) {
    for (let i = 0; i < inputs.length; i++) {
      this.inputs.push(inputs[i]);
    }
  }

  /**
   * inputs의 일부 데이터를 삭제하거나 전체 데이터를 삭제합니다. 그리고 mixAudio를 호출 해야 최종 출력 결과가 반영됩니다.
   *
   * 참고: setInput에 빈 배열을 넣어도, inputs를 전부 삭제할 수 있습니다.
   * (그래도 inputs 삭제가 목적이라면 이 함수를 사용하는것이 더 좋습니다.)
   */
  removeInputs(inputNumber: number | null = null) {
    if (inputNumber == null) {
      this.inputs = [];
    } else {
      this.inputs.splice(inputNumber, 1);
    }
  }

  /**
   * 이펙트를 설정합니다.
   *
   * 이펙트가 변경된 즉시 이펙트 연결 작업및 오디오 노드 설정 작업이 수행됩니다. (온라인 작업 한정)
   */
  setEffects(iEffect: IEffect[]) {
    this.effects = iEffect;

    this.disconnectEffect();
    this.effectNodes.length = 0; // 이펙트 노드 배열 전부 삭제

    // 각 이펙트 정보를 이용해 새로운 노드를 생성
    for (let current of this.effects) {
      let getEffect = createEffect(this.audioContext, current);

      // 만약, 저 이펙트가 배열(2개 이상의 노드)라면
      if (Array.isArray(getEffect)) {
        for (let i = 0; i < getEffect.length; i++) {
          // 각 배열에 있는 노드를 1개씩 추가
          this.effectNodes.push(getEffect[i]);
        }
      } else {
        // 배열이 아닌경우 그 노드를 그대로 추가
        this.effectNodes.push(getEffect);
      }

      // 만약 스테레오 효과가 있는 이펙트가 들어온다면
      // 이펙트의 개수와 이펙트 노드의 개수는 서로 다를 수 있습니다.
      // 다만 처리상에는 아무 문제가 없습니다.
    }

    if (this.logView) console.log('mixAudio.setEffects -> effectNodes: ', this.effectNodes);

    // 새 입력을 할 때마다 강제로 연결 작업을 수행합니다.
    this.connectEffect(true);
  }

  /**
   * 모든 이펙트 삭제
   * @param {number} removeIndex 삭제할 배열의 인덱스, 이 값이 없으면 배열 전체 삭제
   */
  removeEffects(removeIndex: number | null = null) {
    if (removeIndex != null) {
      this.effects.splice(removeIndex, 1);
      this.connectEffect(); // 이펙트 수가 변동되었으므로 다시 연결합니다.
    } else {
      this.disconnectEffect(); // 이펙트 전부 해제
      this.effects.length = 0; // 배열의 길이가 0일경우 배열은 전부 삭제됩니다.
    }
  }

  getEffects() {
    return this.effects;
  }

  getEffectNodes() {
    return this.effectNodes;
  }

  /**
   * 이펙트 또는 오디오가 적용되어 있는지 확인해주는 함수
   */
  getIsEnableEffectOrAudio() {
    if (this.inputs.length >= 1 || this.effects.length >= 1) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * 마스터 게인값을 변경합니다. 최종 출력되는 볼륨에 영향을 줍니다.
   *
   * 내부적으로는 2이상의 게인 값이 지정되지 않도록 했습니다.
   * @param gainValue 게인 값 (1보다 크면 소리가 더 커지고, 1보다 작아지면 소리가 작아집니다.)
   */
  setMasterGainValue(gainValue: number = 1) {
    if (gainValue >= 0 && gainValue <= 2) {
      this.masterGain.gain.value = gainValue;
    }
  }

  /**
   * 메인 오디오(또는 원본 비디오)의 게인을 변경합니다.
   * 
   * 기본값은 1, 범위는 0 ~ 2 사이 (참고: 2는 200% 볼륨입니다.)
   * 
   * 이 값은 비디오 또는 오디오에만 영향을 주며, 최종 게인값과는 관련이 없습니다.
   * @param gainValue 
   */
  setMainAudioGainValue (gainValue: number = 1) {
    if (gainValue >= 0 && gainValue <= 2) {
      this.mainAudioGain.gain.value = gainValue;
    }
  }

  /** 메인 오디오의 게인을 얻어옵니다. 범위: 0 ~ 2 */
  getMainAudioGainValue () {
    return this.mainAudioGain.gain.value
  }

  /**
   * 커스텀 오디오 트랙을 설정합니다.
   *
   * 이것을 추가할 때에는, htmlAudioElement를 사용해주세요.
   *
   * audioBuffer는 캐시에 자동으로 추가됩니다. (다만 시간이 걸릴 수 있음.)
   */
  setCustomTrack(audioElement: HTMLAudioElement) {
    // 캐시 버퍼 추가
    this.getCacheAudioBuffer(audioElement.src);

    // 현재 커스텀 오디오의 주소를 변경
    this.customAudio.src = audioElement.src;

    // 결과 반영을 위해 이펙트 다시 연결
    this.connectEffect(true);
  }

  /** 메인(원본) 오디오를 무음 상태로 설정 또는 해제 */
  setMainAudioMuted(isAudioMuted: boolean = true) {
    this.isMainAudioMuted = isAudioMuted;
  }

  /**
   * 페이드 관련 작업을 할 때 사용하는 함수입니다.
   *
   * 현재 음악 재생 시간에 맞추어, 게인 노드의 스케줄을 조정하여 페이드 효과가 적용되도록 합니다.
   *
   * 음악 재생 시간이 변경될 때마다 해당 함수가 호출됩니다.
   */
  fadeProcess() {
    // 커스텀 오디오의 시간 변경 작업은 이 함수에서 처리합니다. 자세한건 timeChange 함수를 살펴보세요.
    if (this.customAudio.src != '') {
      this.customAudio.currentTime = this.mainAudio.currentTime;
    }

    for (let i = 0; i < this.inputs.length; i++) {
      // 페이드 인 시작시간은 start를 사용하지만, 페이드 아웃 시작시간은 end - fadeTime을 사용해야 합니다.
      // 이것은, end가 음악의 끝지점이기 때문입니다.
      let fadeInStart = this.inputs[i].start;
      let fadeInEnd = this.inputs[i].start + this.inputs[i].fadeIn;
      let fadeOutStart = this.inputs[i].end - this.inputs[i].fadeOut;
      let fadeOutEnd = this.inputs[i].end;
      let audioTime = this.mainAudio.currentTime // 메인 오디오의 시간
      let startGain = 0; // 시작 게인

      // 가독성을 위해, 조건문의 결과를 미리 변수에 저장해서 사용합니다.
      let isFadeIn = audioTime >= fadeInStart && audioTime < fadeInEnd;
      let isSustain = audioTime >= fadeInEnd && audioTime < fadeOutStart;
      let isFadeOut = audioTime >= fadeOutStart && audioTime <= fadeOutEnd;
      let isNext = audioTime < fadeInStart; // 다음에 재생되는 곡인경우

      let currentGain = this.inputAudioFadeNodes[i];
      let sustainTime = fadeOutStart - audioTime; // 유지 시간
      let contextTime = this.audioContext.currentTime;

      // 현재 게인 노드의 모든 스케줄 작업 취소
      currentGain.gain.cancelScheduledValues(contextTime);

      if (isNext) {
        // 다음 곡인 상태인 경우 (해당 곡 재생 이전 시점)
        let nextWaitTime = fadeInStart - audioTime;
        let nextContextTime = contextTime + nextWaitTime;
        let fadeInDuration = fadeInEnd - fadeInStart;
        let fadeOutDuration = fadeOutEnd - fadeOutStart;
        let sustainTime = fadeOutStart - fadeInEnd;
        currentGain.gain.setValueAtTime(0, nextContextTime); // 현재 시점을 다음 대기시간까지 고정

        // 페이드 인
        currentGain.gain.linearRampToValueAtTime(
          1,
          nextContextTime + fadeInDuration,
        );

        // 현재 값 유지
        currentGain.gain.setValueAtTime(1, nextContextTime + sustainTime);

        // 페이드 아웃
        currentGain.gain.linearRampToValueAtTime(
          0,
          nextContextTime + sustainTime + fadeOutDuration,
        );
      } else if (isFadeIn || isSustain) {
        // 페이드 인 상태 또는 페이드 아웃 상태인경우
        if (isFadeIn) {
          // 페이드 인 상태인 경우
          // 페이드 인 과정에서 페이드 게인 값을 계산합니다.
          let fadeInDuration = fadeInEnd - fadeInStart;
          let currentPosition = fadeInDuration - (fadeInEnd - audioTime);
          startGain = currentPosition / fadeInDuration;

          // 페이드 인 작업
          currentGain.gain.setValueAtTime(startGain, contextTime);
          currentGain.gain.linearRampToValueAtTime(
            1,
            contextTime + (fadeInDuration - currentPosition),
          );

          // 볼륨 유지 (페이드 아웃 시작지점까지)
          currentGain.gain.setValueAtTime(1, contextTime + sustainTime);
        } else if (isSustain) {
          // 유지 상태인 경우
          currentGain.gain.setValueAtTime(1, contextTime); // 즉시 볼륨 1 적용
          currentGain.gain.setValueAtTime(1, contextTime + sustainTime); // 이것을 페이드 아웃 전까지 유지
        }

        // 페이드 아웃 (페이드 인, 유지 상태에서 페이드 아웃 코드는 공통으로 사용합니다.)
        let fadeOutDuration = fadeOutEnd - fadeOutStart;
        currentGain.gain.linearRampToValueAtTime(
          0,
          contextTime + (sustainTime + fadeOutDuration),
        );
      } else if (isFadeOut) {
        let fadeOutDuration = fadeOutEnd - fadeOutStart;
        let currentPosition = fadeOutDuration - (fadeOutEnd - audioTime);
        let startGain = 1 - currentPosition / fadeOutDuration;

        currentGain.gain.setValueAtTime(startGain, contextTime);
        currentGain.gain.linearRampToValueAtTime(
          0,
          contextTime + (fadeOutDuration - currentPosition),
        );
      } else {
        // 페이드 인, 페이드 아웃 상태가 아니고, 다음 음악 재생 상태가 아닌 경우
        // 이 음악은 출력되지 않으므로, 볼륨이 0이 됩니다.
        currentGain.gain.setValueAtTime(0, contextTime);
      }

      // 반복문 종료
    }
  }

  /**
   * 실시간 처리 함수
   *
   * mixAudio에서 미리듣기를 한다 해도, 시간에 따른 이펙트나, 시간에 따른 오디오 트랙 미리듣기를 위해서는
   * 실시간 처리로 같은함수를 일정시간마다 호출해야 합니다.
   *
   * 이 함수를 외부에서나 직접 호출하거나 다른 용도로 사용할 일은 없습니다.
   *
   * 페이드에 관한 처리는, fadeProcess에서 진행하며, 실시간으로 처리하진 않습니다.
   */
  intervalFunction() {
    // 메인오디오(비디오)가 정지 상태일 때는
    // 오디오를 정지 상태로 변경합니다. (새로 비디오를 임포트할 때 오디오가 정지되지 않는 버그가 있었음.)
    if (this.mainAudio.paused) {
      this.customAudio.pause();

      for (let i = 0; i < this.inputAudios.length; i++) {
        if (!this.inputAudios[i].paused) {
          this.inputAudios[i].pause();
        }
      }
      return;
    }

    // 시간에 따른 오디오 재생
    for (let i = 0; i < this.inputs.length; i++) {
      let current = this.inputs[i];
      let audioTime = this.mainAudio.currentTime;
      let inputAudio = this.inputAudios[i];

      let currentAudio = this.inputAudios[i];
      const playDuration = current.rangeEnd - current.rangeStart
      const basePlayTime = audioTime - current.start + current.rangeStart

      // 시간 조건 확인 및 정해진 시간 값에 맞게 입력된 오디오 시간 조정
      if (audioTime >= current.start && audioTime < current.end) {
        if(inputAudio.paused) {
          currentAudio.currentTime = basePlayTime;
          inputAudio.play(); // 오디오가 일시정지인 상태에서만 재생
        }
      }
    }

    this.connectEffect();
  }
}
