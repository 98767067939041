import { getAnalytics } from 'firebase/analytics';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Firestore, getFirestore } from 'firebase/firestore';
import { FirebaseStorage, getStorage } from 'firebase/storage';

export let app: FirebaseApp;
export let firestore: Firestore;
export let storage: FirebaseStorage;

try {
  const firebaseConfig = {
    apiKey: 'AIzaSyAcwLqxwu3MSBkyhWihx-zNJj7cCqqWl0Y',
    authDomain: 'soundapp-dc32a.firebaseapp.com',
    projectId: 'soundapp-dc32a',
    storageBucket: 'soundapp-dc32a.appspot.com',
    messagingSenderId: '117217818595',
    appId: '1:117217818595:web:abb7cb16f1c895ae0992d6',
    measurementId: 'G-N0QX3T72NB',
  };

  app = initializeApp(firebaseConfig);
  firestore = getFirestore(app);
  storage = getStorage(app);
  const analytics = getAnalytics(app);
} catch (e) {
  console.error(e);
}
