import React, { useEffect } from 'react';
import Lottie from 'react-lottie';
import { Box, Modal } from '@mui/material';
import downloadJs from 'downloadjs';
import { useStores } from 'store';
import styled from 'styled-components';

import * as loadingLottie from '../../asset/lottie/loading.json';

interface SaveModalProps {
  show: boolean;
  onClose: () => void;
}
export const SaveModal = ({ show, onClose }: SaveModalProps) => {
  useEffect(() => {
    if (!show) return;

    setTimeout(() => {
      downloadJs(useStores().videoStore.videoUrl);
      onClose();
    }, 6000);
  }, [show]);

  return (
    <Modal open={show}>
      <Box sx={{ background: 'white' }}>
        <Lottie
          options={{ loop: true, autoplay: true, animationData: loadingLottie }}
          height={400}
          width={400}
        />
      </Box>
    </Modal>
  );
};
