import { IEffect, NodeKind } from "./types";

interface RangeData {
  min: number;
  max: number;
  step?: number;
}

const ranges = {} as Record<string, RangeData>;

export const range = (
  min: number,
  max: number,
  options: { step?: number } = {}
) => {
  return (target: IEffect, name: PropertyKey): any => {
    ranges[`${target.nodeKind}_${String(name)}`] = {
      min,
      max,
      step: options.step,
    };
  };
};

export const getRange = (nodeKind: NodeKind, prop: string) => {
  return ranges[`${nodeKind}_${prop}`];
};
