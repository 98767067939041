import WaveSurfer from 'wavesurfer.js';

export const getMaxVolume = (input) => {
  return new Promise((resolve) => {
    const wavesurfer = WaveSurfer.create({
      container: '#waveform',
    });
    wavesurfer.loadBlob(input);
    wavesurfer.on('ready', async () => {
      const pcm = await wavesurfer.exportPCM();
      const pcm2 = pcm.map((x) => Math.abs(x) >= 0.065 ? (1 - Math.abs(x)) : 0);
      const max = Math.max(...pcm);
      const min = Math.min(...pcm);
      const min2 = Math.max(...pcm2);
      const maxAt = pcm.indexOf(max);
      const minAt = pcm.indexOf(min);
      const min2At = pcm2.indexOf(min2);
      
      console.log('max volume', max);
      resolve(max);
    });
  });
};
