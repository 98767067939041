import { useEffect, useState } from 'react';
import { useStores } from 'store';
import styled, { css } from 'styled-components';

import { uiPopup } from 'store/ui';
import { getLanguageData } from 'constant/language';
const lang = getLanguageData()

interface PopupPropsInter {
  header?: string;
  content?: string;
  block: boolean;
  confirmButtonOnClick?(type: boolean): void;
}

const Confirm = (setting: PopupPropsInter) => {
  const { header, content, block, confirmButtonOnClick } = setting;
  const [isBlock, setIsBlock] = useState(false);

  useEffect(() => {
    setIsBlock(block);
  }, [block]);

  return (
    <Container isBlock={isBlock}>
      <BackgroundOpacity onClick={() => confirmButtonOnClick!(false)}/>
      <LightBox>
        <Content>
          <span className='title'>{uiPopup.title ? uiPopup.title : header}</span>
          <span>&nbsp;</span>
          <span>{uiPopup.content ? uiPopup.content : content}</span>
        </Content>
        <br/>
        <Bottom>
          <ButtonCancle onClick={() => confirmButtonOnClick!(false)}>{lang.CLOUD_CANCLE}</ButtonCancle>
          <ButtonDelete onClick={() => confirmButtonOnClick!(true)}>{lang.CLOUD_DELETE}</ButtonDelete>
        </Bottom>
      </LightBox>
    </Container>
  );
};

export default Confirm;

const Container = styled.div<any>`
  width: 100%;
  height: 100%;

  z-index: 4;

  position: absolute;

  display: flex;
  justify-content: center;

  ${({ isBlock }) =>
    isBlock == true
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
`;

const BackgroundOpacity = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: grey;
  opacity: 0.5;
`;

const LightBox = styled.div`
  position: absolute;
  top: 20%;

  max-width: 424px;
  width: 90%;
  height: 182px;
  padding: 10px;

  border-radius: 16px;
  border: 1px solid var(--colors-common-white, #FFF);

  background: #2E2E2E;

  z-index: 5;
`;

const Content = styled.div`
  width: 100%;
  height: 120px;

  white-space: break-spaces;

  box-sizing: border-box;

  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;

  font-size: 1rem;
  font-weight: bold;
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span.title {
    color: var(--colors-common-white, #FFF);

    text-align: center;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 116.667% */
    letter-spacing: -0.48px;
  }

  span {
    color: var(--colors-common-white, #FFF);

    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.32px;
  }
`;

const Bottom = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4e6875;
  border-radius: 0 0 5px 5px;
  border-top: thin solid white;
`;

const ButtonCancle = styled.button`
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;

  border-radius: 8px;
  background: #FFF5F8;

  color: #E32326;

  text-align: center;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 122.222% */
  letter-spacing: -0.36px;

  &:hover {
    cursor: pointer;
  }
`

const ButtonDelete = styled.button`
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;

  border-radius: 8px;
  background: #E32326;

  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 122.222% */
  letter-spacing: -0.36px;

  &:hover {
    cursor: pointer;
  }
`