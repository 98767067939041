import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'store';
import styled from 'styled-components';

import { isMobile, isTestMode, mobile, string2color } from 'util/index';
import { TimelineView } from './timelineView';
import { RangeSlider } from 'toolcool-range-slider';
import { trace } from 'mobx';
import { getLanguageData } from 'constant/language';

const lang = getLanguageData()

/** 
 * audioSettingmodal을 사용할 때 사용되는 타입
 * 
 * 각각, music(음악 설정), main(메인 오디오 설정), ''(아무것도 표시하지 않음)
 */
type modalType = 'music' | 'main' | '';
interface TimelineProps {}
export const Timeline = observer(({}: TimelineProps) => {
  const { timelineStore, videoStore, uiStore } = useStores();
  const [forceUpdate, setForceUpdate] = useState(0);
  const forceUpdateRef = useRef(0);

  // 참고: observable로 만들어진 변수들은 observer에서 감지 가능
  // 다만, 배열의 경우, 주소를 참조하기 때문에, 배열의 값 변경을 감지하려면 스프레드 연산자를 사용해야 함
  // 여기서는 effect, backgroundSounds의 추가를 감지하기 위해 해당 배열의 length를 감지 대상에 포함시킴
  // 배열의 길이가 변경된다면, 새로운 요소가 추가되었다고 간주함
  const detectTimelineStoreBackgrounds = timelineStore.backgroundSounds.length
  const detectTimelineStoreEffects = timelineStore.effects.length

  // 비디오가 로드 되었는지 확인, 로드가 완료되면 타임라인 다시 갱신하여, 시간을 제대로 표시하도록 함
  // 이것은 observable의 감지를 위한 변수입니다.
  const detectVideoLoad = videoStore.loaded

  // trace() // 감지 변화 테스트 용도

  /** 타임 라인 표시 용도, 참고로 여기에 작성된 %값은 레이아웃 크기에 영향을 미칩니다. */
  const timelineView = useRef<TimelineView>(new TimelineView('100%', '40%'));
  const [audioSettingModalType, setShowAudioSettingModal] = useState<modalType>('');

  /** 오디오를 설정하는 용도로 사용되는 값, 이 값들의 정보는 TimelineStore.BackgroundSounds의 타입을 참고 */
  const audioSettingRef = useRef({
    name: '',
    start: 0,
    end: 0,
    musicDuration: 0,
    rangeStart: 0,
    rangeEnd: 0,
    rangeEndback: 0,
    fadeIn: 0,
    fadeOut: 0,
    volume: 0,
  })

  /** 레이어 리스트 목록 (번호랑 대응시키기 위해서) */
  const timelineViewLayerList = {
    TIME: 0,
    MASTERING: 1,
    MUSIC: 2,
  }

  // timelineView shadowText 자동 갱신 용도
  useEffect(() => {
    const intervalId = setInterval(() => {
      // 타임라인 뷰 데이터들 중 음악 엘리먼트를 전부 가져옴
      const layer = timelineView.current.getLayer(timelineViewLayerList.MUSIC)
      if (layer == null) return

      const videoDuration = videoStore.getDuration()
      
      const bgIdList = getBackgroundsIdList()
      if (bgIdList == null) return

      for (let i = 0; i < layer.subElement.length; i++) {
        const subElement = layer.subElement[i]
        const backgroundIndex = bgIdList.indexOf(subElement.id)
        if (backgroundIndex === -1) continue

        const background = timelineStore.backgroundSounds[i]
        const position = subElement.percent.position
        const length = subElement.percent.length

        if (videoDuration === 0) continue
        const targetTime = videoDuration / 100 * position
        const targetLength = videoDuration / 100 * length
        const targetEnd = targetTime + targetLength

        subElement.setShadowText('start: ' + targetTime.toFixed(1) + ' ~ ' + targetEnd.toFixed(1) + '/' + videoDuration.toFixed(1) + ', duration: ' + targetLength.toFixed(1))
      }
    }, 100)

    return () => clearInterval(intervalId)
  }, [])

  /** 슬라이더에서 마우스를 떼거나 터치를 종료할 때 사용하는 함수 */
  const onMouseUpOrTouchEndSlider = () => {
    onChangeMusicRefData()
  }

  // 참고: 마우스와 터치를 동시 지원하기 위하여 터치 이벤트와 마우스 이벤트를 동시에 사용합니다.
  // 마우스 이벤트가 onMouseUp인 이유는, 그래야만 사용자가 슬라이더를 조절할 때
  // 마우스가 슬라이더를 벗어나도 값이 반영되기 때문입니다. (mouseup으로는 안됨)

  /** 음악 재생의 시작과 끝 부분을 조정하는 슬라이드 */
  const tcRangeSliderRefStartEnd = useCallback((slider: RangeSlider) => {
    const onPointerClicked = (evt: Event) => {
      let customEvent = evt.currentTarget as any // 라이어브러리 예시에 따른 형변환
      // 둘중 하나의 값이 잘못된 값이라면, 변경 취소
      if (isNaN(customEvent.value1) || isNaN(customEvent.value2)) return

      // 만약, 서로의 값이 1초보다 낮다면, value2의 값은 value1보다 1초 더 크도록 변경
      // 참고: 숫자가 낮은쪽이 무조건 value1임
      if (customEvent.value1 > customEvent.value2 - 1) {
        customEvent.value2 = customEvent.value + 1
        // 만약, value2가 끝지점이라면, value1의 값을 다시 조정함
        if (customEvent.value2 > customEvent.max - 1) {
          customEvent.value1 = customEvent.max - 1
          customEvent.value2 = customEvent.max
        }
      }

      audioSettingRef.current.start = customEvent.value1
      audioSettingRef.current.end = customEvent.value2
    }

    if (slider != null) {
      slider.value1 = audioSettingRef.current.start
      slider.value2 = audioSettingRef.current.end
      slider.addEventListener('change', onPointerClicked)
      slider.addEventListener('onMouseUp', onMouseUpOrTouchEndSlider)
      slider.addEventListener('touchend', onMouseUpOrTouchEndSlider)
    }

    return () => {
      slider?.removeEventListener('change', onPointerClicked)
      slider?.addEventListener('onMouseUp', onMouseUpOrTouchEndSlider)
      slider?.addEventListener('touchend', onMouseUpOrTouchEndSlider)
    }
  }, [])

  /** 
   * 음악 내부 재생 범위를 조정하는 슬라이드.
  */
  const tcRangeSliderRefMusicRange = useCallback((slider: RangeSlider) => {
    const onPointerClicked = (evt: Event) => {
      let customEvent = evt.currentTarget as any // 라이어브러리 예시에 따른 형변환
      if (isNaN(customEvent.value1)) return

      // 값 적용
      audioSettingRef.current.rangeStart = customEvent.value1
    }

    if (slider != null) {
      slider.value1 = audioSettingRef.current.rangeStart
      slider.addEventListener('change', onPointerClicked)
      slider.addEventListener('onMouseUp', onMouseUpOrTouchEndSlider)
      slider.addEventListener('touchend', onMouseUpOrTouchEndSlider)
    }

    return () => {
      slider?.removeEventListener('change', onPointerClicked)
      slider?.addEventListener('onMouseUp', onMouseUpOrTouchEndSlider)
      slider?.addEventListener('touchend', onMouseUpOrTouchEndSlider)
    }
  }, [])

  /** 볼륨을 조정하는 슬라이드 */
  const tcRangeSliderRefVolume = useCallback((slider: RangeSlider) => {
    const onPointerClicked = (evt: Event) => {
      let customEvent = evt.currentTarget as any // 라이어브러리 예시에 따른 형변환
      if (isNaN(customEvent.value1)) return

      audioSettingRef.current.volume = customEvent.value1
    }

    if (slider != null) {
      slider.value1 = audioSettingRef.current.volume
      slider.addEventListener('change', onPointerClicked)
      slider.addEventListener('onMouseUp', onMouseUpOrTouchEndSlider)
      slider.addEventListener('touchend', onMouseUpOrTouchEndSlider)
    }

    return () => {
      slider?.removeEventListener('change', onPointerClicked)
      slider?.addEventListener('onMouseUp', onMouseUpOrTouchEndSlider)
      slider?.addEventListener('touchend', onMouseUpOrTouchEndSlider)
    }
  }, [])

  /** 페이드 인 값 조절하는 슬라이드 */
  const tcRangeSliderRefFadeIn = useCallback((slider: RangeSlider) => {
    const onPointerClicked = (evt: Event) => {
      let customEvent = evt.currentTarget as any // 라이어브러리 예시에 따른 형변환
      if (isNaN(customEvent.value1)) return

      audioSettingRef.current.fadeIn = customEvent.value1
    }

    if (slider != null) {
      slider.value1 = audioSettingRef.current.fadeIn
      slider.addEventListener('change', onPointerClicked)
      slider.addEventListener('onMouseUp', onMouseUpOrTouchEndSlider)
      slider.addEventListener('touchend', onMouseUpOrTouchEndSlider)
    }

    return () => {
      slider?.removeEventListener('change', onPointerClicked)
      slider?.addEventListener('onMouseUp', onMouseUpOrTouchEndSlider)
      slider?.addEventListener('touchend', onMouseUpOrTouchEndSlider)
    }
  }, [])

  /** 페이드 아웃 값 조절하는 슬라이드 */
  const tcRangeSliderRefFadeOut = useCallback((slider: RangeSlider) => {
    const onPointerClicked = (evt: Event) => {
      let customEvent = evt.currentTarget as any // 라이어브러리 예시에 따른 형변환
      if (isNaN(customEvent.value1)) return

      audioSettingRef.current.fadeOut = customEvent.value1
    }

    if (slider != null) {
      slider.value1 = audioSettingRef.current.fadeOut
      slider.addEventListener('change', onPointerClicked)
      slider.addEventListener('onMouseUp', onMouseUpOrTouchEndSlider)
      slider.addEventListener('touchend', onMouseUpOrTouchEndSlider)
    }

    return () => {
      slider?.removeEventListener('change', onPointerClicked)
      slider?.addEventListener('onMouseUp', onMouseUpOrTouchEndSlider)
      slider?.addEventListener('touchend', onMouseUpOrTouchEndSlider)
    }
  }, [])

  /**
   * 막대바 생성 하위 컴포넌트
   * 여기서 ref는 useRef로 선언된 tcRangeSliderRef 변수를 지정해주세요. (단, 중복으로 사용되지 않도록 해야합니다. 값이 겹치면 오류가 발생할 수 있음)
   * @param props 
   * @returns 
   */
  function TCRangeSlider (props: {
    /** 제목 */ label?: string, 
    /** 값1 */ value1: number, 
    /** 값2 (이 값이 포함된경우, 양방향 슬라이더가 됨) */ value2?: number, 
    /** 최소값 */ min: number, 
    /** 최대값 */ max: number, 
    /** 각 값의 간격 (참고: 소수점을 버림하는 round는 자동으로 설정됨) */ step?: number, 
    /** useCallback ref */ refVar: React.LegacyRef<RangeSlider>}) {

    /** 소수점 자리수를 지정하는 함수 (step값에 따라 자동으로 결정됨) */
    function getRoundValue (step = 0) {
      if (step >= 1) return 0
      else if (step < 1 && step >= 0.1) return 1
      else if (step < 0.1) return 2 
    }

    const DEFAULT_STEP = 0.1

    return (
      <div style={{display: 'flex', alignItems: 'center', minHeight:'36px'}}>
        <LableText style={{width:'30%'}} >{props.label}</LableText>
        <tc-range-slider id='rangeslider'
        animate-onclick="false"
        slider-bg-fill='#6A6CFC'
        pointer-border="2px solid #6A6CFC"
        slider-height='8px'
        slider-width='100%'
        // @ts-ignore (일부 매개변수 이름은 타입스크립트가 모르지만, 옵션은 정상적으로 적용됨)
        value1={props.value1}
        value2={props.value2}
        style={{width: '70%'}}
        ref={props.refVar}
        min={props.min}
        max={props.max}
        step={props.step ? props.step : DEFAULT_STEP}
        round={getRoundValue(props.step ? props.step : DEFAULT_STEP)} // 소수점 표시 자리의 개수 (0일경우 정수만 출력)
        />
      </div>
    )
  }

  function TCRangeSliderArea () {

    /** 값 표시 영역 */
    function ValueArea () {
      // 일정 시간마다 강제 업데이트
      const [f, setF] = useState(0);
      useEffect(() => {
        const intervalId = setInterval(() => {
          setF((f) => f + 1)
        }, 100)
  
        return (() => {
          clearInterval(intervalId)
        })
      }, [f])

      const videoDuration = videoStore.getDuration()
      const playDuration = audioSettingRef.current.end - audioSettingRef.current.start
      const playRangeEnd = audioSettingRef.current.rangeStart + playDuration
      const playMaxLimit = audioSettingRef.current.musicDuration

      // 페이드의 총 시간은 음악 재생 길이의 50%를 초과할 수 없음
      const maxFadeTime = playDuration / 2 >= 10 ? 10 : playDuration / 2

      return (
        <div>
          <ValueDiv>{audioSettingRef.current.start.toFixed(1)} ~ {audioSettingRef.current.end.toFixed(1)} / {videoDuration.toFixed(1)}</ValueDiv>
          <ValueDiv>{audioSettingRef.current.rangeStart.toFixed(1)} ~ {playRangeEnd.toFixed(1)} / {playMaxLimit.toFixed(1)}</ValueDiv>
          <ValueDiv>{audioSettingRef.current.volume} / 1</ValueDiv>
          <ValueDiv>{audioSettingRef.current.fadeIn} / {maxFadeTime.toFixed(1)}</ValueDiv>
          <ValueDiv>{audioSettingRef.current.fadeOut} / {maxFadeTime.toFixed(1)}</ValueDiv>
        </div>
      )
    }

    /** 창 닫기 */
    const onClose = () => {
      // 창을 닫을 때, 오디오 설정 모달이 닫히고, 마지막으로 클릭했던 객체를 제거함
      setShowAudioSettingModal('')
      timelineView.current.resetLastClickTargetData()
    }

    // 레인지 슬라이더 공간을 표현하기 위한 서브영역 (메인영역은 모바일, PC 레이아웃을 구분하는 용도로만 사용)
    // 배치는 임의 위치를 가지기 위해서 width를 강제로 할당함
    // &nbsp는 강제 띄어쓰기 용도
    /** 슬라이더 서브 영역 */
    function TCRangeSliderSubArea () {
      return (
        <div style={{padding:'10px'}}>
          <div>{lang.MUSICEDIT_NAME}: {getActiveItem().name}, ID: {getActiveItem().id}</div>
          <div style={{display:'flex'}}>
            <div style={{width: '70%'}}>
              <TCRangeSlider label={lang.MUSICEDIT_POSITION} refVar={tcRangeSliderRefStartEnd} value1={audioSettingRef.current.start} value2={audioSettingRef.current.end} min={0} max={videoStore.getDuration()}></TCRangeSlider>
              <TCRangeSlider label={lang.MUSICEIDT_PLAYBACK_RANGE} refVar={tcRangeSliderRefMusicRange} value1={audioSettingRef.current.rangeStart} min={0} max={audioSettingRef.current.musicDuration}></TCRangeSlider>
              <TCRangeSlider label={lang.MUSICEDIT_VOLUME} refVar={tcRangeSliderRefVolume} value1={audioSettingRef.current.volume} step={0.01} min={0} max={1} />
              <TCRangeSlider label={lang.MUSICEDIT_FADEIN} refVar={tcRangeSliderRefFadeIn} value1={audioSettingRef.current.fadeIn} min={0} max={10} />
              <TCRangeSlider label={lang.MUSICEDIT_FADEOUT} refVar={tcRangeSliderRefFadeOut} value1={audioSettingRef.current.fadeOut} min={0} max={10} />
            </div>
            <div style={{width: '5%'}}></div>
            <div style={{width: '25%'}}>
              <ValueArea/>
            </div>
          </div>
          <br></br>
          <GreyButton onClick={() => onClose()}>{lang.MUSICEDIT_CLOSE}</GreyButton>
          <span>&nbsp;&nbsp;&nbsp;</span>
          <GreyButton onClick={() => onDeleteBackgroundSound()}>{lang.MUSICEDIT_DELETE}</GreyButton>
        </div>
      )
    }
    
    // 모바일, PC 레이아웃 영역의 구분 용도 (내부 구현은 동일하고, 배치만 다름)
    return (
      <div>
        {isMobile() 
          ? <div style={{position:'fixed', right:'0%', top:'50%', width:'100%', minWidth:'100%',
            height:'50%', backgroundColor:'#3C3C3C', color:'#FFFFFF', justifyContent:'center', alignItems:'center'}}>
              <TCRangeSliderSubArea/>
            </div> 
          : <div style={{position:'absolute', right:'0%', top:'-60%', width:'60%', minWidth:'50vw',
            height:'50%', backgroundColor:'#3C3C3C', color:'#FFFFFF', justifyContent:'center', alignItems:'center'}}>
              <TCRangeSliderSubArea/>
            </div> }
      </div>
    )
  }

  /** 
   * 현재 비디오의 시간을 막대로 표시하는 영역
   * 
   * 타임라인 위에 덮어쓰는 구조입니다. 실시간으로 타임라인이 표시된 위치를 찾습니다.
   * 그 다음, 그 위치에 맞게 세로크기가 조절되며 현재 타임라인의 위치를 표시합니다.
   */
  const VideoCurrentTimeMeterArea = () => {
    const [f, setF] = useState(0); // forceUpdate
    const divRef = useRef<HTMLDivElement>(null); // div ref
    
    useEffect(() => {
      let intervalId = setInterval(() => {
        if (divRef.current == null) return
        if (timelineView.current == null) return

        const vDuration = videoStore.getDuration();
        const cTime = videoStore.getCurrentTime();
        const leftPercent = cTime / vDuration * 100

        // 스타일을 타임라인 내부의 시간 표시 위치에 맞게 수정함.
        // 타임라인 내부에는 여러 레이어가 있고, 이 중 시간을 표시하는 레이어에 접근
        const layer = timelineView.current.getLayer(timelineViewLayerList.TIME)
        if (layer == null) return
        
        // 시간을 표시하는 레이어에서 콘텐츠 영역의 길이를 가져옴
        const rect = layer.contentElement.getBoundingClientRect()

        // 만약 100%위치라면, 막대바가 화면 바깥으로 넘어갈 수 있으므로, 
        // 이를 제한하기 위해 표시되는 사각형의 길이를 총 길이에서 제외함
        const viewLength = rect.width - parseInt(divRef.current.style.width)
        const left = rect.left + (viewLength * leftPercent / 100)
        const top = rect.top

        // 고정 fixed 위치로 처리
        divRef.current.style.top = top + 'px'
        divRef.current.style.left = left + 'px'

        // 높이도 타임라인의 컨텐츠 영역에 맞게 설정
        divRef.current.style.height = rect.height + 'px'

        // 해당 컴포넌트만 강제 렌더링을 위한 forceUpdate
        setF((f) => f + 1)
      }, 100)

      return () => clearInterval(intervalId)
    }, [])

    // 타임라인 위에 덮어씌워야 하는 구조이므로, position이 fixed입니다.
    return (
      <div ref={divRef} style={{width: '4px', position:'fixed', background:'linear-gradient(#D63CAB, #6A6CFC)'}}/>
    )
  }

  /** 
   * container ref, useCallback을 사용하여 timelineView를 표시하도록 변경
   * 
   * 이것은, 모바일에서 sideMenu를 열고 닫았을 때, div객체의 참조가 무효가 되어
   * timelineView가 표시되지 않아 강제 렌더링 하기 위해 만든 함수입니다.
   * 
   * 따라서, useCallback을 이용해 렌더링이 된 후에 div를 참조시킨 후, 
   * 해당 div에 timelineView 객체의 내용을 포함시켜, div 내부가 초기화 될 때마다
   * 자식 노드를 계속 추가하도록 했습니다.
   * 
   * 이 현상은 해당 콘테이너를 전부 가리는 모바일 버전에서만 나오며, PC버전에서는 아무 상관 없습니다.
   * 
   * 참고: 나중에 확인해보니, 이 함수 중간에 모바일여부를 확인하고 강제 return 하는 조건문이 있었습니다.
   * 아마도, timelineView가 사이드메뉴를 열고 닫을 때 사라진 이유가 그 조건문 때문인 것 같습니다. 해당 조건문은 삭제되었지만,
   * 이 코드는 그대로 유지됩니다.
   */
  const container = useCallback((target: HTMLDivElement) => {
    if (target != null) {
      // 레이어의 내부 데이터가 없다면, 새로운 레이어를 생성하여 초기화합니다.
      if (timelineView.current.layer.length === 0) {
        // 레이어 구조: 총 3개
        // 0: Time 시간표시, 멀티라인 1, 드래그 불가능 
        timelineView.current.createLayer(1, '100%', '15%', '#1C1C1D');
        timelineView.current.setLayerTitle(timelineViewLayerList.TIME, 'T', '5%', '100%', '#FFFFFF', '#1C1C1D');
        timelineView.current.setLayerSubElementDragPossible(timelineViewLayerList.TIME, false); // 드래그 불가능

        // 1: Mastering 이펙트 표시, 오버랩(겹치기), 드래그 불가능
        timelineView.current.createLayer(0, '100%', '15%', '#1C1C1D');
        timelineView.current.setLayerTitle(timelineViewLayerList.MASTERING, 'M', '5%', '100%', '#FFFFFF', '#1C1C1D');
        timelineView.current.setLayerSubElementDragPossible(timelineViewLayerList.MASTERING, false); // 이펙트 시간 길이 조절 불가능

        // 2: Music 음악 표시, 멀티라인 있음. 5개, 단 겹치기 불가능
        timelineView.current.createLayer(10, '100%', '70%', '#1C1C1D');
        timelineView.current.setLayerMultiline(timelineViewLayerList.MUSIC, 5, false); // 멀티라인 설정 (5개, 겹치기 불가능)
        timelineView.current.setLayerTitle(timelineViewLayerList.MUSIC, '♪', '5%', '100%', '#FFFFFF', '#1C1C1D');
      }

      // 해당 타겟(div 태그) 내에 자식 엘리먼트가 없다면, timelineView의 엘리먼트를 직접 추가합니다.
      if (target.childElementCount === 0) {
        target.appendChild(timelineView.current.getBaseElement())
      }
    }
  }, [])

  // 화면 갱신용 useEffect
  // 주의: 데이터가 갱신될 때마다 재설정 해야하므로, useEffect의 2번째 매개변수로 []를 넣지 마세요.
  useEffect(() => {
    timelineViewChange()
  })

  /** 타임라인스토어에 있는 데이터를 타임라인 뷰에 처리 */
  const timelineViewChange = () => {
    timelineViewChangeLayerTime()
    timelineViewChangeLayerEffect()
    timelineViewChangeLayerMusic()
  }

  /** 길이를 분:초 형태의 텍스트로 변환합니다. */
  const durationToMinuteSecond = (value: number) => {
    let minute = Math.floor(value / 60);
    let second = value % 60;
    let secondText = second.toString().padStart(2, '0')

    return minute + ':' + secondText;
  };

  const timelineViewChangeLayerTime = () => {
    timelineView.current.layerSubElementDeleteAll(timelineViewLayerList.TIME) // 레이어 서브 엘리먼트 제거
    // 그 후 다시 레이어 서브엘리먼트 생성

    // 시간 처리
    if (videoStore == null) return;
    if (!videoStore.getDuration) return;

    let duration = videoStore.getDuration();
    if (duration == null) return;

    // 모바일이면 5개만 출력, pc버전이면 10개 출력
    // 이유는 모바일에서는 가로 길이가 짧기 때문
    const maxLength = isMobile() ? 5 : 10

    timelineView.current.setLayerSubElementDragPossible(timelineViewLayerList.TIME, false) // 시간 드래그 불가능
    for (let i = 0; i < maxLength; i++) {
      let number = Math.floor(duration / maxLength) * i
      const lengthDiv = Math.floor(1 / maxLength * 100)
      const positionDiv = lengthDiv * i
      timelineView.current.layerAddSubElementObj(
        timelineViewLayerList.TIME, {
          textContent: durationToMinuteSecond(number),
          id: undefined,
          cssColor: 'white',
          cssBackgroundColor: undefined
        }, {
          position: positionDiv,
          length: lengthDiv,
          lengthStart: undefined,
          lengthEndback: undefined,
          lengthMax: lengthDiv
        }
      )
    }
  }

  const timelineViewChangeLayerEffect = () => {
    timelineView.current.layerSubElementDeleteAll(timelineViewLayerList.MASTERING) // 레이어 서브 엘리먼트 제거
    // 그 후 다시 레이어 서브엘리먼트 생성

    // 이펙트 처리 (비디오 시간이 0이면, 0나누기 방지를 위해 함수 종료)
    const videoDuration = videoStore.getDuration()
    if (videoDuration == null || videoDuration === 0) return

    for (let i = 0; i < timelineStore.effects.length; i++) {
      const current = timelineStore.effects[i]

      // 참고: 마스터링 이펙트는 동영상 전체에 적용됩니다.
      // 따라서, 값이 등록될 때, start, end를 계산하지 않습니다.
      const duration = videoDuration // current.end - current.start
      const positionLengthMax = 100 // duration / videoDuration * 100

      let a = timelineView.current.layerAddSubElementObj(timelineViewLayerList.MASTERING, {
        id: current.id,
        textContent: current.effect.name,
        cssColor: 'white',
        cssBackgroundColor: string2color(current.effect.name ?? ''),
      }, {
        lengthMax: positionLengthMax,
        length: positionLengthMax,
        lengthStart: 0,
        lengthEndback: 0,
        position: 0,
      })
    }
  }

  const timelineViewChangeLayerMusic = () => {
    timelineView.current.layerSubElementDeleteAll(timelineViewLayerList.MUSIC) // 레이어 서브 엘리먼트 제거
    // 그 후 다시 레이어 서브엘리먼트 생성

    const videoDuration = videoStore.getDuration()
    if (videoDuration == null && videoDuration === 0) return

    for (let i = 0; i < timelineStore.backgroundSounds.length; i++) {
      const current = timelineStore.backgroundSounds[i]
      // 일부 값 보정
      // 음악의 끝이 비디오 길이보다 높으면 안됨
      if (current.end > videoDuration) {
        current.end = videoDuration
      }

      const duration = current.musicDuration
      const musicDuration = current.end - current.start
      const percentPosition = current.start / videoDuration * 100

      // 음악의 범위 끝이, 음악 내부의 길이보다 높으면 안됨
      if (current.rangeEnd > current.musicDuration) {
        current.rangeEnd = current.musicDuration - musicDuration
      }
      
      // 퍼센트 길이는 최대 100% 제한
      const percentLength = musicDuration < videoDuration ? musicDuration / videoDuration * 100 : 100
      const percentLengthMax = duration < videoDuration ? duration / videoDuration * 100 : 100
      const percentLengthStart = current.rangeStart / videoDuration * 100
      const percentLengthEndback = current.rangeEndback / videoDuration * 100

      timelineView.current.layerAddSubElementObj(timelineViewLayerList.MUSIC, {
        id: current.id,
        textContent: current.name,
        cssColor: 'white',
        cssBackgroundColor: string2color(current.name ?? ''),
      }, {
        lengthMax: percentLengthMax,
        length: percentLength,
        lengthStart: percentLengthStart,
        lengthEndback: percentLengthEndback,
        position: percentPosition
      })
    }
  }

  const onMouseUp = () => {
    // 변경된 값에 대하여 처음부터 다시 제작
    timelineViewToStoreLayerMastering()
    timelineViewToStoreLayerMusic()
    getActiveAudioSettingRef()

    timelineStore._updateBackgroundSound() // 이펙트 및 배경사운드 변경
    // setForceUpdate(forceUpdate + 1) // 화면 강제 갱신
  }

  const timelineViewToStoreLayerMastering = () => {
    // for문을 이용하여, 퍼센트 구간을 다시 측정하고, 
    // 이 퍼센트 값을 이용하여 레이어를 처음부터 재설정

    // 가지고 있는 데이터의 위치가 변경되면 변경된 위치를 근거로 재배치
    const currentLayerData = timelineView.current.getLayerReturnData(timelineViewLayerList.MASTERING)
    if (currentLayerData == null) return

    const videoDuration = videoStore.getDuration()
    if (videoDuration == null || videoDuration === 0) return

    const effectList = timelineStore.effects
    if (effectList.length === 0) return

    const effectIdList = getEffectIdList();
    if (effectIdList == null) return

    for (let i = 0; i < currentLayerData.length; i++) {
      const current = currentLayerData[i]
      const index = effectIdList.indexOf(current.id)
      if (index === -1) continue

      const start = current.percentPosition * videoDuration / 100
      const duration = current.percentLength * videoDuration / 100
      const effect = effectList[i]

      effect.start = start
      effect.end = start + duration
    }
  }

  const getEffectIdList = () => {
    const effectList = timelineStore.effects
    if (effectList.length === 0) return

    let effectNameList = []
    for (let i = 0; i < effectList.length; i++) {
      effectNameList.push(effectList[i].id)
    }

    return effectNameList
  }

  const getBackgroundsIdList = () => {
    const backgroundSoundList = timelineStore.backgroundSounds
    if (timelineStore.backgroundSounds.length === 0) return

    let bgNameList = []
    for (let i = 0; i < backgroundSoundList.length; i++) {
      const current = backgroundSoundList[i]
      bgNameList.push(current.id)
    }

    return bgNameList
  }
  
  const timelineViewToStoreLayerMusic = () => {
    // 가지고 있는 데이터의 위치가 변경되면 변경된 위치를 근거로 재배치
    const currentLayerData = timelineView.current.getLayerReturnData(timelineViewLayerList.MUSIC)
    if (currentLayerData == null) return

    const currentLayer = timelineView.current.getLayer(timelineViewLayerList.MUSIC)
    if (currentLayer == null) return

    const videoDuration = videoStore.getDuration()
    if (videoDuration == null || videoDuration === 0) return
    
    const backgroundSoundList = timelineStore.backgroundSounds
    const bgIdList = getBackgroundsIdList()
    if (bgIdList == null) return

    for (let i = 0; i < currentLayerData.length; i++) {
      const current = currentLayerData[i]

      // 참고: 배경 음악은 변수명으로 관리되므로 실제로 배경 음악이 달라질 일은 없음
      const backgroundIndex = bgIdList.indexOf(current.id)
      if (backgroundIndex === -1) continue // 해당하는 배경음악이름이 없으면 무시함

      const background = backgroundSoundList[backgroundIndex]
      const percentData = timelineView.current.getCalcuratePercentToValue(videoDuration, current.percentPosition, current.percentLength, current.percentLengthMax, current.percentLengthStart, current.percentLengthEndback)

      // backgroundSound 정보 입력
      // range관련 값들은 타임라인과 연동되지 않습니다.
      background.start = percentData.valuePosition
      background.end = percentData.valuePosition + percentData.valueLength
      const backgroundLength = background.end - background.start

      // fade 시간 조정 (임시 코드, 수정 필요)
      if (backgroundLength < 6) {
        background.fadeIn = backgroundLength / 2
        background.fadeOut = backgroundLength / 2
      }

      // const subElementRect = currentLayer.subElement[i].element.getBoundingClientRect()
      // if (subElementRect != null) {
      //   background.waveSurferCotainer.style.height = (subElementRect.height - 5) + 'px'
      // }
    }
  }

  const onClick = () => {
    const click = timelineView.current.lastClickTargetData
    if (click.layerNumber === timelineViewLayerList.MASTERING) {
      // effect를 클릭했을 때, 관련 처리가 필요함
      onDeleteEffect()
      timelineView.current.resetLastClickTargetData() // 마지막 클릭 데이터 제거 (중복, 미스클릭 방지)
    } else if (click.layerNumber === timelineViewLayerList.MUSIC) {
      let subElement = timelineView.current.layer[click.layerNumber].subElement[click.subElementNumber]
      // 참고: 여기서는 마지막 클릭 데이터를 제거하지 않습니다. 왜냐하면, onBackgroundSound 설정에 영향을 줘야 하기 때문입니다.
      // 모달 작업이 완료되고 닫히면, 그 때 마지막 클릭 데이터가 제거됩니다.

      if (subElement != null) {
        // 오디오 모달이 켜져 있는 상태에서 다른 음악을 클릭한다면, 정보 갱신을 위해 강제 렌더링 발생
        // 참고: 타임라인뷰는 서브엘리먼트 이외의 장소를 클릭했는지를 알 수 없음.
        // 그래서, 오디오가 한번 선택되면, 닫기 버튼을 누르기 전까지 계속 갱신이 일어날 수 있음.
        if (audioSettingModalType === 'music') {
          setForceUpdate((f) => f + 1)
        }

        // 클릭된 서브엘리먼트가 존재해야만 타임 모달이 표시됨
        getActiveAudioSettingRef()
        setShowAudioSettingModal('music')
      }
    }
  }

  const onDeleteEffect = () => {
    // 타임라인 뷰에서 마지막으로 클릭한 객체를 가져옴
    let click = timelineView.current.lastClickTargetData

    // 삭제할 대상을 찾은 후, 그 대상이 있으면 삭제
    let removeTarget = timelineStore.effects[click.subElementNumber]
    if (removeTarget != null) {
      timelineStore.removeEffect(removeTarget.id)
    }
  }

  const onDeleteBackgroundSound = () => {
    let click = timelineView.current.lastClickTargetData
    setShowAudioSettingModal('') // 모달 닫기

    let idList = getBackgroundsIdList()
    if (idList == null) return

    const backgroundIndex = idList.indexOf(click.id)
    let removeTarget = timelineStore.backgroundSounds[backgroundIndex]
    if (removeTarget != null) {
      timelineStore.removeBackgroundSound(removeTarget.id)
    }

    timelineView.current.resetLastClickTargetData() // 마지막 클릭 데이터 제거 (중복, 미스클릭 방지)
  }

  /** audioSettingRef에 지정된 값에 따라 알아서, 오디오를 변경합니다. (활성화된 것 기준으로) */
  const onChangeMusicRefData = () => {
    let click = timelineView.current.lastClickTargetData

    // start와 end의 간격이 1초보다 적을 경우 end의 위치를 start + 1로 변경
    // 단, end가 1보다 짧다면, 끝지점에서 start와 end를 재배치
    const videoDuration = videoStore.getDuration()
    if (audioSettingRef.current.end - audioSettingRef.current.start < 1) {
      audioSettingRef.current.end = audioSettingRef.current.start + 1
      if (audioSettingRef.current.end > videoDuration - 1) {
        audioSettingRef.current.start = videoDuration - 1
        audioSettingRef.current.end = videoDuration
      }
    }

    // rangeStart 보정, 영상에 출력하는 음악의 길이가, 음악 시작 지점부터 재생할 때,
    // 재생되는 위치 + 총 길이가 음악 최대 길이를 초과하지 않도록 조정
    const playDuration = audioSettingRef.current.end - audioSettingRef.current.start
    const playMaxLimit = audioSettingRef.current.musicDuration
    if (audioSettingRef.current.rangeStart > playMaxLimit - playDuration) {
      audioSettingRef.current.rangeStart = playMaxLimit - playDuration
    }

    // 페이드 아웃 + 페이드 인의 시간은 음악 재생 길이보다 길 수 없음
    // 따라서, 만약 페이드 시간이 너무 길면, 음악 재생 길이를 2로 나누고 페이드 인, 아웃 시간을 동일하게 함
    const fadeIn = audioSettingRef.current.fadeIn
    const fadeOut = audioSettingRef.current.fadeOut
    const playHalf = playDuration / 2
    if (fadeIn + fadeOut > playDuration) {
      audioSettingRef.current.fadeIn = Number(playHalf.toFixed(1))
      audioSettingRef.current.fadeOut = Number(playHalf.toFixed(1))
    }

    let idList = getBackgroundsIdList()
    if (idList != null) {
      const backgroundIndex = idList.indexOf(click.id)
      let target = timelineStore.backgroundSounds[backgroundIndex]
      if (target != null) {
        target.volume = audioSettingRef.current.volume
        target.start = audioSettingRef.current.start
        target.end = audioSettingRef.current.end
        target.rangeStart = audioSettingRef.current.rangeStart
        target.rangeEnd = audioSettingRef.current.rangeEnd
        target.rangeEndback = audioSettingRef.current.rangeEndback
        target.fadeIn = audioSettingRef.current.fadeIn
        target.fadeOut = audioSettingRef.current.fadeOut
      }
    }


    // 오디오 변경에 따라 강제 업데이트
    timelineStore._updateBackgroundSound()
    forceUpdateRef.current += 1
    setForceUpdate(forceUpdateRef.current)

    // audioSettingRef값이 실시간으로 참조되기 때문에, 마지막 클릭 데이터는 여기서 리셋 안합니다.
    // 대신 audioSettingModal이 닫힐 때 적용
    // timelineView.current.resetLastClickTargetData()
  }

  /** 선택한 음악 값들을 전부 audioSettingRef에 저장함 */
  const getActiveAudioSettingRef = () => {
    let getActive = getActiveItem()
    audioSettingRef.current.musicDuration = getActive.musicDuration
    audioSettingRef.current.name = getActive.name
    audioSettingRef.current.start = getActive.start
    audioSettingRef.current.end = getActive.end
    audioSettingRef.current.fadeIn = getActive.fadeIn
    audioSettingRef.current.fadeOut = getActive.fadeOut
    audioSettingRef.current.rangeStart = getActive.rangeStart
    audioSettingRef.current.rangeEnd = getActive.rangeEnd
    audioSettingRef.current.volume = getActive.volume
  }

  /** 현재 활성화된 아이템의 값을 가져옴 */
  const getActiveItem = () => {
    let returnData = {
      start: 0,
      end: 0,
      volume: 1,
      fadeIn: 0,
      fadeOut: 0,
      rangeStart: 0,
      rangeEnd: 0,
      musicDuration: 0,
      name: '',
      id: '',
    }

    const videoDuration = videoStore.getDuration()
    if (videoDuration == null || videoDuration === 0) {
      return returnData
    }

    const currentTarget = timelineView.current.lastClickTargetData
    if (currentTarget.layerNumber === timelineViewLayerList.MUSIC) {
      let idList = getBackgroundsIdList()
      if (idList == null) return returnData
      
      const backgroundIndex = idList.indexOf(currentTarget.id)
      let target = timelineStore.backgroundSounds[backgroundIndex]
      if (target == null) return returnData
      
      returnData.name = target.name
      returnData.start = target.start
      returnData.end = target.end
      returnData.fadeIn = target.fadeIn
      returnData.fadeOut = target.fadeOut
      returnData.rangeStart = target.rangeStart
      returnData.rangeEnd = target.rangeEnd
      returnData.musicDuration = target.musicDuration
      returnData.volume = target.volume
      returnData.id = target.id
    }

    return returnData
  }

  function TimelineArea () {
    // 이벤트 버블링을 이용해서, 내부에서 일어난 dragEnd 이벤트를 통해
    // 상위 엘리먼트에 전송해서, 내부에 데이터가 변경됨을 감지함
    // 이 컴포넌트는 timeline만 forceUpdate하기 위해 분리됨
    return (
      <div style={{width:'100%', height:'100%'}} ref={container} onDragEnd={onMouseUp} onTouchEnd={onMouseUp} onClick={onClick}></div>
    )
  }

  return (
    <Container>
      <TimelineArea/>
      <VideoCurrentTimeMeterArea/>
      {audioSettingModalType === 'music' && <TCRangeSliderArea/>}
    </Container>
  )
});

// 모바일과 PC버전의 배치는 사이드바의 위치로 인해, top부분이 약간 다르게 설정되어있습니다.
const Container = styled.div`
  position: absolute;
  width: calc(100vw - 64px);
  top: ${isMobile() ? '49%' : '60%'};
  height: 100%;
  width: 100%;

  @media (max-width: 480px) {
    width: 100vw;
  }
`;

const LableText = styled.div`
  color: #FFF;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

const ValueDiv = styled.div`
  display: flex;
  height: 24px;
  minWidth: 60px;
  border: 1px solid #999999;
  padding: 5.453px 0px 4.547px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 2.751px;
  background: #38393B;

  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
`

const GreyButton = styled.button`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  width: 20%;
  height: 50%;
  padding: 1%;

  background: #3F3F3F;

  /* shadow */
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(48.722511291503906px);

  color: #FFF;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
  text-transform: capitalize;
`