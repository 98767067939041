import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import { firestore } from 'firebase-app';
import { collection, doc, onSnapshot, setDoc } from 'firebase/firestore';
import { isNil, max } from 'lodash-es';
import { observable } from 'mobx';
import { IBackgroundSound } from 'model';
import {
  applySurroundSound,
  bufferToWave,
  restoreState,
  saveState,
  setEffects,
  setReverb,
} from 'sfx';
import { MixAudio } from 'sfx/mixAudio';
import { useStores } from 'store';
import Worker from 'web-worker';

import {
  getAuthTokenFromCookie,
  isMobile,
  isTestMode,
  saveFile,
  uploadFile,
} from 'util/index';
import { LoadingProgress } from 'page/app/loadingProgress';
import { IAppliedEffect, timelineStore } from './timeline';
import { uiStore } from './ui';
import { userStore } from './user';
import WaveSurfer from 'wavesurfer.js';

const Speakers = [
  { x: 90, y: 65, angle: 0 },
  { x: 170, y: 65, angle: 0 },
  { x: 130, y: 30, angle: 0 },
  { x: 80, y: 200, angle: 180 },
  { x: 180, y: 200, angle: 180 },
  // virtual spakers
  { x: 50, y: 130, angle: -90 },
  { x: 210, y: 130, angle: 90 },
];

const loadingProgress = new LoadingProgress();

export interface IPosition {
  x: number;
  y: number;

  angle: number;
}
export interface IVideoStore {
  video: any;
  videoUrl: string | null;
  time: number;
  saving: boolean;
  saveMessage: string;
  ffmpeg: FFmpeg | null;

  /** 
   * 비디오 로드 완료 여부
   * 
   * 참고: 이 값이 true여야 Audio, ShuuterStock메뉴를 사용할 수 있습니다.
   */
  loaded: boolean;
  loadingProgress: LoadingProgress | null;

  /** overlay.tsx에서 증가하는 로딩 바를 표현하기 위해 사용하는 상태 값
   *
   * 이 값을 지속적으로 변경해야, react의 상태 값 변경으로, 실시간으로 증가하는 로딩 바를 표현할 수 있습니다.
   * 해당 값이 사용되지 않는다면, 로딩 바가 실시간으로 변화하지 않습니다.
   */
  loadingPercent: number;

  speakers: IPosition[];

  analyser: AnalyserNode;

  /** 비디오가 사용 준비가 되었는지 확인합니다. (비디오가 존재하고 로드가 완료되어야 합니다.) */
  isReady: boolean;

  /**
   * 오디오 파일을 입력받는 엘리먼트 태그입니다.
   *
   * 참고: 새 파일을 등록할 때 이 태그를 사용하여 파일 오픈 창을 호출하세요.
   * 지역변수로 사용하면 아이폰에서는 갤러리로 파일 업로드가 되지 않습니다.
   */
  inputTag: HTMLInputElement;

  /**
   * inputTag의 이벤트가 등록되어있는지를 처리하는 변수(사용자가 직접 값을 수정해야 합니다.)
   *
   * 참고: 이벤트가 등록되어있는지에 대한 여부는 알 수 없습니다.
   * 따라서 임의의 변수를 만들어서 수동조작을 통해 간접적으로 알아야 합니다.
   */
  isInputTagEventRegist: boolean;

  context: AudioContext | null;
  videoElement: HTMLVideoElement;
  mixer: MixAudio | null;

  _source: MediaElementAudioSourceNode | null;

  /**
   * 인코딩 과정에 대한 각 단계 구성에 대해 도달한 퍼센트 값 (사용자에게 대략적인 진행 정도를 보여주기 위한 값의 기준)
   *
   * 2023/07/12 수정됨 (각 단계의 간격을 동일하게 설정)
   *
   * 0. 아무것도 실행하지 않음
   * 1. audio mixing = 0% ~ 20% (이펙트가 없으면 매우 빠르고, 있으면 이펙트에 따라 느려짐)
   * 2. encoding = 21% ~ 40% (대체로 오래 걸림)
   * 3. preparing = 41% ~ 60% 파일 병합 (매우 빠름)
   * 4. creating = 61% ~ 80% 파일 생성 (매우 빠름)
   * 5. encoding = 81% ~ 100% 인코딩 (대체로 오래 걸림)
   * 6. finallizing = 100% (결과 완료 표시 용도 - 인코딩이 완료된 경우)
   */
  levelPercent: number[];

  /**
   * 모바일에서 프로세스에 대한 퍼센트로 현재 단계를 간접적으로 판단하기 때문에 다른 변수를 임시로 추가했습니다.
   * 서버의 전달 방식이 변경되면 이 변수는 제거되거나 수정됩니다.
   *
   * 2023/07/12 이전의 값은 [0, 10, 50, 54, 60, 100, 100] 으로 지정되어 있습니다.
   * 
   * 현재 적용된 값은 levelPercent 변수의 주석을 참고해주세요.
   * */
  levelPercentPrevVersion: number[];

  /**
   * 인코딩에 대한 각 단계 구성에 대한 설명 값
   * 해당 스트링 값에 대한 내용은 변수를 초기화 할 때 사용하는 코드를 살펴보세요.
   */
  levelText: string[];

  /** videoElement에 있는 동영상의 길이를 구합니다. */ getDuration(): number;
  /** videoElement에 있는 현재 시간값을 구합니다. */ getCurrentTime(): number;

  importVideo(): Promise<void>;
  importVideoFromFile(file: any): Promise<void>;
  getPeakVolume(): Promise<number>;
  encodeVideo(): Promise<{ video: Blob; thumbnail: Blob; }>;
  _encodeVideoMobile(): Promise<{ video: Blob; thumbnail: Blob }>;
  exportVideo(): Promise<void>;
  uploadVideo(): Promise<{ fileId: string; video: string; thumbnail: string }>;

  /** input 태그에서 change이벤트가 발생했을 때 사용하는 함수 */
  inputTagEventChange(): void;

  /** 마스터링 파형을 로드를 요청하는 함수 (이 함수를 사용하면 현재 적용된 마스터링을 적용시킵니다.) */
  requestWaveMasteringLoad(): void;

  pause(): void;
  resume(): void;

  _loadFfmpeg(): Promise<void>;
  _applySurroundSound(forceRenew?: boolean): Promise<void>;
  _updateMixer(
    backgroundSounds: IBackgroundSound[],
    effects: IAppliedEffect[],
    withWatermark?: boolean,
  ): any;
}
export const videoStore: IVideoStore = observable({
  video: null,
  videoUrl: null,
  saving: false,
  saveMessage: '',
  time: 0,
  speakers: Speakers,
  ffmpeg: null,
  loaded: false,
  loadingProgress: loadingProgress,
  loadingPercent: loadingProgress.currentPercent,

  inputTag: document.createElement('input'),
  isInputTagEventRegist: false,

  context: null,
  mixer: null,

  _source: null,

  levelPercent: [0, 20, 40, 60, 80, 100, 100],
  levelText: [
    'Preparing...',
    '1 / 5 audio Mixing...',
    '2 / 5 Encoding Sound MP3...',
    '3 / 5 Preparing Video Encoder...',
    '4 / 5 create sound with video...',
    '5 / 5 video encoding...',
    'Finallizing...',
  ],
  levelPercentPrevVersion: [0, 10, 50, 54, 60, 100, 100],

  get isReady() {
    return !!this.video && this.loaded;
  },
  get videoElement() {
    return document.getElementById('main-video') as HTMLVideoElement;
  },

  getDuration() {
    const duration = this.videoElement?.duration;
    if (isNaN(duration)) return 0;
    return duration ?? 0;
  },

  getCurrentTime() {
    const currentTime = this.videoElement.currentTime;
    if (isNaN(currentTime)) return 0;
    return currentTime ?? 0;
  },

  inputTagEventChange() {
    this.inputTag.type = 'file';
    this.inputTag.accept = '.mp4,.avi';

    if (!this.isInputTagEventRegist) {
      this.inputTag.addEventListener('change', (e: any) => {
        const file = e.target.files[0];
        this.video = file;
        this.videoUrl = URL.createObjectURL(file);
        if (this.mixer != null) {
          // 비디오가 새로 변경된다면, 해당하는 비디오의 Url도 변경해 주셔야 합니다.
          // 안그러면 비디오를 변경했을 때, mixAudio 과정에서 잘못된 오디오를 인코딩 할 수 있습니다.
          this.mixer.mainAudioUrl = this.videoUrl;
        }

        this.videoElement!.onloadedmetadata = async () => {
          await this._applySurroundSound(true);

          this.loaded = true;
        };
      });
    }

    this.inputTag.click();
  },

  importVideo() {
    this.loaded = false;

    // 아이폰 14에서 import 버튼(헤더에 있는)을 통해 새 영상을 불러온 다음,
    // 렉걸리는 듯한 소리 출력이 발생한다는 버그가 있어, 새 영상을 불러오기 직전에는 현재 재생중인 영상을 정지하도록 했습니다.
    if (
      this.videoElement != null &&
      this.videoElement instanceof HTMLVideoElement
    ) {
      this.videoElement.pause();
    }

    this.inputTagEventChange();
    // const input = document.createElement('input');
    // input.type = 'file';
    // input.accept = '.mp4,.avi';

    // input.addEventListener('change', async (e: any) => {
    //   const file = e.target.files[0];
    //   this.video = file;

    //   setTimeout(() => {
    //     this.videoUrl = URL.createObjectURL(file);
    //     this.videoElement!.onloadedmetadata = async () => {
    //       await this._applySurroundSound(true);

    //       this.loaded = true;
    //     };
    //   }, 1);
    // })

    // input.onchange = async (e: any) => {
    //   const file = e.target.files[0];
    //   this.video = file;

    //   setTimeout(() => {
    //     this.videoUrl = URL.createObjectURL(file);
    //     this.videoElement!.onloadedmetadata = async () => {
    //       await this._applySurroundSound(true);

    //       this.loaded = true;
    //     };
    //   }, 1);

    //   alert('비디오를 체인지')
    // };

    // input.click();
  },
  importVideoFromFile(file: File) {
    this.video = file;
    this.videoUrl = URL.createObjectURL(file);

    setTimeout(() => {
      this._applySurroundSound(true);
      this.loaded = true;
    }, 1);
  },

  async getPeakVolume() {
    return 1;

    const audio = new OfflineAudioContext({
      sampleRate: 44100,
      // @ts-ignore
      length: 44100 * this.getDuration(),
      numberOfChannels: 2,
    });

    const source = audio.createBufferSource();
    source.buffer = await audio.decodeAudioData(await this.video.arrayBuffer());
    source.connect(audio.destination);
    source.start();

    const result = await audio.startRendering();

    //@ts-ignore
    return max([...result.getChannelData(0), ...result.getChannelData(1)]);
  },

  async encodeVideo() {
    if (!this.video) return;

    // 실시간으로 증가하는 퍼센트를 보여주기 위해서 setInterval 함수를 사용했습니다.
    // 해당 타이머는 finally 구문에서 해제됩니다.
    let loadingIntervalTimerId = setInterval(() => {
      if (this.loadingProgress?.currentPercent != null) {
        this.loadingPercent = this.loadingProgress?.currentPercent;
      }
    }, 20);
    this.loadingProgress?.reset();

    try {
      saveState();
      this.saving = true;
      this.saveMessage = 'Preparing...';
      const { timelineStore } = useStores();

      // 비디오 일시정지
      // @ts-ignore
      document.getElementById('main-video')?.pause();

      if (isMobile()) {
        return await this._encodeVideoMobile();
      }

      // 지금 updateMixer 함수는 렌더링을 피하기 위해 mixAudio의 기능을 중지했습니다.
      // 따라서 최종 결과 이펙트와 입력만 마치고, 나중에 따로 mixAudio 함수를 호출해야 합니다.
      this._updateMixer(
        timelineStore.backgroundSounds,
        timelineStore.effects,
        isNil(userStore.user) || userStore.user!.level === 0,
      );

      // 믹스 오디오 버퍼 작업을 여기서 실행합니다.
      // 이 작업이 끝나면 이펙트와 입력이 반영된 오디오 버퍼를 얻을 수 있습니다.
      this.loadingProgress?.movePercent(
        this.levelPercent[1],
        videoStore.getDuration(),
      );
      this.saveMessage = this.levelText[1];
      const buffer = await this.mixer!.mixAudio();

      this.loadingProgress?.movePercent(this.levelPercent[2], 30); // 30초 이내에 로딩된다고 생각해서 30초로 지정함.
      this.saveMessage = this.levelText[2];

      const encodeWorker = new Worker(
        new URL('../worker/encoder.js', import.meta.url),
      );

      const left = buffer.getChannelData(0);
      const right = buffer.getChannelData(1);

      encodeWorker.postMessage({
        left,
        right,
        sampleRate: this.context?.sampleRate,
      });

      const audioBlob = await new Promise<Blob>(resolve => {
        encodeWorker.onmessage = e => {
          resolve(e.data.buffer);
        };
      });

      // 업로드 할 때 마다 새 워커가 실행되므로 메모리 누수를 방지하기 위해 작업이 끝난 워커를 종료하여야 합니다.
      encodeWorker.terminate();

      setReverb('none');
      setEffects([]);

      this.loadingProgress?.movePercent(this.levelPercent[3], 20);
      this.saveMessage = this.levelText[3];
      await this._loadFfmpeg();

      this.loadingProgress?.movePercent(this.levelPercent[4], 30);
      this.saveMessage = this.levelText[4];
      const ffmpeg = this.ffmpeg!;
      await ffmpeg.writeFile('v.mp4', await fetchFile(this.video));
      await ffmpeg.writeFile('a.mp3', await fetchFile(audioBlob));

      // testMode??? 이 모드를 사용한경우, 알 수 없는 이유로 mixAudio의 결과가 반영되지 않음.
      // 현재는 이 조건을 사용할 필요가 없어 무력화하였습니다.
      if (isTestMode() && false) {
        const videoData = await ffmpeg.readFile('v.mp4');
        await ffmpeg.exec(['-i', 'v.mp4', '-frames:v', '1', 'screenshot.png']);
        const thumbnailData = await ffmpeg.readFile('screenshot.png');

        this.loadingProgress?.complete();

        return {
          video: new Blob([videoData], { type: 'video/mp4' }),
          thumbnail: new Blob([thumbnailData], {
            type: 'image/png',
          }),
        };
      }

      this.loadingProgress?.movePercent(
        this.levelPercent[5],
        videoStore.getDuration(),
      );
      this.saveMessage = this.levelText[5];

      await ffmpeg.exec(['-i', 'v.mp4', '-frames:v', '1', 'screenshot.png']);
      await ffmpeg.exec([
        '-i',
        'v.mp4',
        '-i',
        'a.mp3',
        '-c:v',
        'copy',
        '-map',
        '0:v:0',
        '-map',
        '1:a:0',
        'o.mp4',
      ]);
      const videoData = await ffmpeg.readFile('o.mp4');
      const thumbnailData = await ffmpeg.readFile('screenshot.png');

      this.saveMessage = this.levelText[6];
      this.loadingProgress?.complete();

      return {
        video: new Blob([videoData], { type: 'video/mp4' }),
        thumbnail: new Blob([thumbnailData], {
          type: 'image/png',
        }),
      };
    } catch (e) {
      console.error(e);
    } finally {
      clearInterval(loadingIntervalTimerId);
      this.saving = false;
      restoreState();
    }
  },
  async _encodeVideoMobile() {
    const dump = timelineStore.save();
    // 모바일 인코드 비디오가 업로드 되는 순간부터, 로딩 화면을 표시하도록 메세지와 로딩 프로그레스를 추가했습니다.
    // 로딩 프로그레스의 값이 지정되지 않으면, 진행 상태가 표시되지 않으므로 임의의 값을 추가했습니다.
    this.saveMessage = 'server progress start...';
    this.loadingProgress?.movePercent(1, 5);

    const fileId = `${Date.now()}_${Math.random()}`;
    const uploadVideo = await uploadFile(
      `tmp_videos/${fileId}.mp4`,
      this.video,
    );

    const context = doc(collection(firestore, 'render_context'));
    await setDoc(context, {
      video_url: uploadVideo.Location,
      progress: 0,
      ...dump,
    });

    const unsubscribe = onSnapshot(context, doc => {
      // 참고사항
      // 만약 서버에서 전달되는 값이 변경된경우, 이를 반영해야 합니다.
      // levelPercent는 퍼센트 값 목표를 간편하게 지정하기 위해 만든 변수이므로,
      // 이 값이 변경된 경우, 서버에서 받는 내용과 별개일 수 있으므롭 변수가 추가 수정되었습니다.

      /**
       * 해당 요청이 서버에서 진행되는것을 추가로 표시해주는 텍스트
       * [글자가 모바일 화면을 초과하는 문제가 있어, 해당 글자를 제외했습니다.]
       *
       * @deprecated
       */
      const serverProgressText = '';

      // 각 단계에 따라 텍스트를 표시하고 퍼센트 값을 변경합니다.
      switch (doc.data()!.progress) {
        case this.levelPercentPrevVersion[0]:
          this.loadingProgress?.movePercent(
            this.levelPercent[1],
            videoStore.getDuration(),
          );
          this.saveMessage = serverProgressText + this.levelText[1];
          break;
        case this.levelPercentPrevVersion[1]:
          this.loadingProgress?.movePercent(this.levelPercent[2], 30);
          this.saveMessage = serverProgressText + this.levelText[2];
          break;
        case this.levelPercentPrevVersion[2]:
          this.loadingProgress?.movePercent(this.levelPercent[3], 20);
          this.saveMessage = serverProgressText + this.levelText[3];
          break;
        case this.levelPercentPrevVersion[3]:
          this.loadingProgress?.movePercent(this.levelPercent[4], 30);
          this.saveMessage = serverProgressText + this.levelText[4];
          break;
        case this.levelPercentPrevVersion[4]:
          this.loadingProgress?.movePercent(
            this.levelPercent[5],
            videoStore.getDuration(),
          );
          this.saveMessage = serverProgressText + this.levelText[5];
          break;
        case this.levelPercentPrevVersion[5]:
          this.loadingProgress?.complete();
          this.saveMessage = serverProgressText + this.levelText[6];
          break;
      }

      // console.log('encodeVideoMoblie -> progress: ', doc.data()!.progress, ', progressType: ' + typeof doc.data()!.progress);
    });

    const {
      fileId: fileIdFromserver,
      videoUrl,
      thumbnailUrl,
    } = await (
      await fetch(
        `${process.env.REACT_APP_ENCODE_API_URL}?context_id=${context.id}`,
        {
          method: 'POST',
          body: JSON.stringify({
            title: videoStore.video.name,
            effectName: timelineStore.effects[0]?.effect?.name ?? '',
            bgmName: timelineStore.backgroundSounds[0]?.name ?? '',
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getAuthTokenFromCookie()}`,
          },
        },
      )
    ).json();

    unsubscribe();

    return {
      fileId: fileIdFromserver,
      video: videoUrl,
      thumbnail: thumbnailUrl,
    } as any;
  },

  async exportVideo(saveThumbnail = false) {
    if (!this.video) return;

    try {
      const { video, thumbnail } = await this.encodeVideo();
      saveFile(video, 'output.mp4');

      if (saveThumbnail) {
        saveFile(thumbnail, 'thumbnail.png');
      }
    } catch (e) {
      console.error(e);
    }
  },

  async uploadVideo() {
    if (!this.video) {
      return null as any;
    }

    const loadingIntervalTimerId = setInterval(() => {
      if (this.loadingProgress?.currentPercent != null) {
        this.loadingPercent = this.loadingProgress?.currentPercent;
      }
    }, 20);

    try {
      const {
        //@ts-ignore
        fileId: idFromServer,
        video,
        thumbnail,
      } = await this.encodeVideo();

      if (video instanceof Blob) {
        const fileId = `${Date.now()}`;
        const uploadVideo = uploadFile(`videos/${fileId}.mp4`, video);
        const uploadThumbnail = uploadFile(
          `thumbnails/${fileId}.png`,
          thumbnail,
        );

        this.saving = true;
        this.saveMessage = 'Uploading...';
        // this.loadingProgress?.movePercent(99, videoStore.getDuration() / 10)

        await Promise.all([uploadThumbnail, uploadVideo]);

        return {
          fileId,
          video: `${process.env.REACT_APP_CF_ENDPOINT}/videos/${fileId}.mp4`,
          thumbnail: `${process.env.REACT_APP_CF_ENDPOINT}/thumbnails/${fileId}.png`,
        };
      } else {
        return {
          fileId: idFromServer,
          video,
          thumbnail,
        };
      }
    } catch (e) {
      console.error(e);

      throw e;
    } finally {
      clearInterval(loadingIntervalTimerId);
      this.saving = false;
    }
  },

  pause() {
    this.videoElement.pause();
  },
  resume() {
    this.videoElement.play();
  },

  async _loadFfmpeg() {
    // 메모리 누수 방지를 위해 ffmpeg.exit() 함수를 사용했으나, 강제로 예외를 던져 영상을 완성하지 못함.
    // 따라서 ffmpeg.exit()는 사용하기 어려움.

    // 중복해서 ffmpeg를 불러오는것을 막기 위해서, 로드는 1번만 수행합니다.
    if (this.ffmpeg == null || !this.ffmpeg.loaded) {
      this.ffmpeg = new FFmpeg();
      await this.ffmpeg.load({
        coreURL: '/ffmpeg/ffmpeg-core.js',
        wasmURL: '/ffmpeg/ffmpeg-core.wasm',
        //workerURL: '/ffmpeg/ffmpeg-core.worker.js',
        // log: true,
      });
      console.log('_loadFfmepg -> this.ffmpeg: ', this.ffmpeg);
      console.log('_loadFfmpeg -> this.ffmepg.isLoaded: ', !this.ffmpeg.loaded);

      if (!this.ffmpeg.loaded) {
        await this.ffmpeg.load();
      }
    }
  },
  async _applySurroundSound() {
    uiStore.showLoading();
    const peakVolume = await this.getPeakVolume();

    if (this.context) {
      // 컨텍스트가 있는 경우, 기존의 컨텍스트를 삭제하면 안됩니다.
      // 왜냐하면, 해당 영상의 소스를 다시 컨텍스트에 새로 연결할 수 없어서, 영상이 재생되지 않습니다.
      let audio = this.mixer?.audioContext;
      let gain = this.mixer?.audioContext.createGain();
      let destination = this.mixer?.audioContext.destination;

      // 이펙트의 연결을 위해서 applySurroundSound 작업을 다시 진행합니다.
      await applySurroundSound(audio, gain, destination, {
        defaultGain: 1 / peakVolume,
      });
    } else {
      const audio = new AudioContext();
      const analyser = audio.createAnalyser();

      // if (this.context) {
      //   this.mixer?.close();
      //   await this.context.close();
      // }
      this.context = audio;

      if (!this._source) {
        this._source = audio.createMediaElementSource(this.videoElement!);
        this._source.connect(analyser);
        this.analyser = analyser;
      }

      const gain = audio.createGain();

      this.mixer = new MixAudio(audio, this.videoElement!, analyser, gain, []);
      // 미리듣기에서는 버퍼 렌더링 작업을 할 필요가 없으므로, mixAudio.mixAudio함수를 호출하지 않습니다.
      // await this.mixer.mixAudio([]);

      // 로그 표시 여부 결정
      this.mixer.logView = false

      await applySurroundSound(audio, gain, audio.destination, {
        defaultGain: 1 / peakVolume,
      });
    }

    uiStore.hideLoading();
  },

  _updateMixer(
    backgroundSounds: IBackgroundSound[],
    effects: IAppliedEffect[],
    withWatermark = true,
  ) {
    if (this.mixer) {
      this.mixer.setInputs([
        ...backgroundSounds,
        ...(withWatermark
          ? backgroundSounds.map(x => ({
              ...x,
              url: `/watermark.mp3`,
            }))
          : []),
      ]);

      this.mixer.setEffects(
        //@ts-ignore
        effects.flatMap(x => {
          return (x.effect.nodes ?? []).map(y => ({
            ...y,
            start: x.start,
            end: x.end,
          }));
        }),
      );
    } else {
      console.error('No mixer');
    }
  },
} as IVideoStore);
