import { getCookie } from './cookie';

const TestToken =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiNGE4NmVhYjMwYThkYzI3ZWQyZjVjZmU3YWEyOTQ0MjdjZmQ2NmE2ZDc3NTUyNTQzYzQ3NGEyZmI0MTE1MmRhMzdkOGU4NmY3ZTU2ZTZiZDIiLCJpYXQiOjE2ODc3NjgwNzYuMjM4MTU3LCJuYmYiOjE2ODc3NjgwNzYuMjM4MTYsImV4cCI6MTY4Nzg1NDQ3Ni4yMzQ4Mywic3ViIjoiNCIsInNjb3BlcyI6WyIqIl19.CAn8TROfp7kmLOpb8auG73cfoIy4w1iy0OcatkXOX69NDzaFScVjIXcEcbr-4LJLrcohz6Vp8JAcz06R5B9qM21dt1R4vJHCuFCaiPVQCzLWPwAocwRVvYH-Ez_BrCsE8abrR7AcY4tbUI2wEjNjEtTajLqpKP6LEtY5Zpx-6syDL2pZFM31tiV66iHGx1a-fPID2eVlkubSSkFRaZfDrU5mSlx94SrYHu0sGD81jfRyOPjr3sy5WGwcPeZ0RCgom4LVkk7fhKUsPQO7qPm-SvrowAl3Kq54uro98aAB-XTbhPKvlph0AL8EM-VLdatK835bwynvDDQmMAdxKYzu_B9IK4aQeK55UtRRu1rSjun5T9yN-wQ6AALbwV-OQKU_1uv57HA0-iABQDQS2dUbbyHL4x4umllQi8MV_iUnaj8NUcEOrf2tRrZgXCuoF6NiiqdCaXtyjO3IZhLcWuEl18Eh8BV25WtQknzk6C30arZ5BVDs5yuPwS41_9tLeW3v74CaAN9Rp2tH6YWvPlCp8FRyUUrQ-s1ZC3aQr0YtUpzCu2psUbodZroBHmFhgRwwEPII830I_UPooDCNBVONpiM013cp1CTFGjeAFNbvxf1EqYahFeAlPmsuwZpkQmCHVS93DxxKReeerzYymbh5yssHeNW3xbWROiKGZtpz4xo';

export const request = async (
  method: 'GET' | 'POST' | 'DELETE',
  apiPath: string,
  params: Record<string, any> = {},
) => {
  const form = new FormData();
  const token = getCookie('SU_TOKEN');

  Object.keys(params).forEach(key => {
    form.append(key, params[key]);
  });

  const resp = await fetch('https://api.surroundio.org/api/v1' + apiPath, {
    method,
    body: method !== 'GET' ? form : undefined,
    headers: {
      Authorization: `Bearer ${token ?? TestToken}`,
    },
  });

  const data = JSON.parse(await resp.text());

  return data;
};
