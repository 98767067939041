// 이 파일은 셔터 스톡 장르 목록과 무드의 목록 배열의 길이가 너무 길이서 따로 분리한 파일입니다.

/** 셔터 스톡 장르 목록 */
export const shutterStockGernes = [
  'Dance / Techno',
  'Dance/Electronic',
  'All Vocals',
  'Pop',
  'Production / Film Scores',
  'Pop/Rock',
  'Production',
  'Samples / Effects',
  'Games',
  'Oohs & Aahs',
  'Corporate',
  'Electronic',
  'Rock',
  'Electro Pop',
  'Folk',
  'Indie Pop',
  'Vocals',
  'Choir / Group',
  'R&B',
  'Hip Hop',
  'Chill Out',
  'World',
  'R&B/Soul',
  'Lead Vocals',
  'Trailer',
  'Classical',
  'World/International',
  'Holiday',
  'Jazz',
  'Kids / Children',
  'Country',
  'New Age',
  'Latin',
  'Children',
  'Easy Listening',
  'Masterworks',
  'Audio Logos',
  'Blues',
  'Punk/Metal',
  'Dubstep',
  'News',
  'Piano / Solo Instrumental',
  'Reggae',
  'Reggae/Ska',
  'Piano/Solo Instrumental',
  'Bluegrass',
  'Independent',
]

/** 셔터 스톡 무드 목록 */
export const shutterStockMoods = [
  'Feel Good',
  'Confident',
  'Happy',
  'Bright',
  'Fun',
  'Playful',
  'Uplifting',
  'Inspiring',
  'Optimistic',
  'Fashion / Lifestyle',
  'Epic / Orchestral',
  'Sophisticated',
  'Stylish',
  'Gentle / Light',
  'Mellow',
  'Intense',
  'Calm',
  'Epic',
  'Fragile',
  'Adventure / Discovery',
  'Dramatic',
  'Happy / Cheerful',
  'Action / Sports',
  'Cinematic',
  'Hopeful',
  'Arousing',
  'Sci-Fi / Future',
  'Energetic',
  'Carefree',
  'Cheerful',
  'Merry',
  'Romantic / Sentimental',
  'Love',
  'Tender',
  'Romantic',
  'Sexy / Sensual',
  'Sad / Nostalgic',
  'Sensual',
  'Sexy',
  'Dark / Somber',
  'Dreamy',
  'Ethereal',
  'Underscores',
  'Bleak',
  'Dark',
  'Bittersweet',
  'Heartbroken',
  'Melancholic',
  'Reflective',
  'Remorseful',
  'Sad',
  'Wistful',
  'Magical / Mystical',
  'Suspense / Drama',
  'Mysterious',
  'Fiery',
  'Worry',
  'Aggressive',
  'Relaxation / Meditation',
  'Angry',
  'Serene',
  'Atmospheric',
  'Meditative',
  'Soothing',
  'Comedy / Funny',
  'Military / Patriotic',
  'Crime / Thriller / Spy',
  'Quirky',
  'Wedding',
  'Aerobics / Workout',
  'Silly',
  'Horror / Scary',
  'Religious / Christian',
  'Eerie',
  'Fear',
  'Terror',
  'Spiritual',
  'Strange / Bizarre',
]