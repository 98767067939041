export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
};

export const getAuthTokenFromCookie = () => {
  return getCookie('SU_TOKEN');
};
