import React, { useEffect } from 'react';
import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import Lottie from 'react-lottie';
import { Box, Modal } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import styled from 'styled-components';

interface LoginModalProps {
  onClose: () => void;
}
export const LoginModal = ({ onClose }: LoginModalProps) => {
  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      console.log(tokenResponse);
      const userInfo = await (
        await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${tokenResponse}` },
        })
      ).json();
      console.log(userInfo);
    },
    onError: errorResponse => console.log(errorResponse),
  });

  const onFacebookLogin = () => {
    FB.login(function (response: any) {
      // handle the response

      console.log(response);
    });
  };

  return (
    <Modal open onClose={onClose}>
      <Box sx={{ background: 'white' }}>
        <LoginContainer>
          <GoogleLogin
            scope="profile https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/plus.login"
            clientId="117217818595-0qr5bho7tp274i62ijgtaei27t9ms1mo.apps.googleusercontent.com"
            onSuccess={resp => {
              console.log(resp);
            }}
          />
          <LoginButton onClick={() => googleLogin()}>Google</LoginButton>
          <LoginButton onClick={() => onFacebookLogin()}>Facebook</LoginButton>
        </LoginContainer>
      </Box>
    </Modal>
  );
};

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 12px;
`;

const LoginButton = styled.button`
  width: 100%;
  height: 43px;

  background: white;
`;
