import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { Header } from 'component/header';
import { HistoryDesktop } from 'component/history/HistoryDesktop';
import { Playground } from 'component/playground';
import { Sidebar } from 'component/sidebar';
import { Timeline } from 'component/timeline';
import 'react-timelines2/lib/css/style.css';
import { isEmpty } from 'lodash-es';
import { useStores } from 'store';

import { ChatPrompt } from 'component/chat';
import { useSearchParamStore } from 'store/router';
import { Overlay } from './app/Overlay';
import { constLayout } from 'constant/layout';

export const AppDesktop = observer(() => {
  const { uiHistory, uiStore } = useStores();
  const [{ menu }] = useSearchParamStore();

  // HistoryDesktop의 순서가 더 위에 있는 이유는, Header에 표시되는 정보가 잘리는것을 막기 위해서
  return (
    <Container>
      <Header/>

      <AppContainer expandSidebar={!isEmpty(menu)}>
        <Sidebar />
        <RightContainer>
          <Playground />
        </RightContainer>
      </AppContainer>
      <HistoryDesktop show={uiHistory.loading}/>
      <Overlay />

      {uiStore.showChatPrompt && <ChatPrompt />}
    </Container>
  );
});

const Container = styled.div`
  height: calc(100vh - ${constLayout.PC_HEADER_HEIGHT});
`;

// appContainer에서, 사이드바가 확장될 때랑 아닐 때랑 서로의 크기는 다릅니다.
// css 문법에 맞추기 위해 1fr 부분 맨 앞에는 공백이 추가됩니다.
const AppContainer = styled.div<{ expandSidebar: boolean }>`
  position: relative;
  height: calc(100vh - ${constLayout.PC_HEADER_HEIGHT});

  display: grid;
  grid-template-columns: ${({ expandSidebar }) =>
    expandSidebar 
    ? constLayout.PC_SIDEBAR_EXPAND_WIDTH + ' 1fr' 
    : constLayout.PC_SIDEBAR_WIDTH + ' 1fr'};

  overflow: hidden;

  > div {
    height: calc(100vh - ${constLayout.PC_HEADER_HEIGHT});
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
